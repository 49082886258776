import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col, Row } from "styled-bootstrap-grid";

import { gray, red } from "../../../../../constants/Colours";

import { RadioGroup } from "../../../../UI-kit/RadioGroup";

import Text from "../../../../UI-kit/Text";
import Button from "../../../../UI-kit/Buttons/Button";
import SecondOrderButton from "../../../../UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../../../UI-kit/FloatingInput";
import InputButtonGroup from "../../../../UI-kit/InputButtonGroup";

import { getPreviewMode } from "../../../../../store/previewMode/selectors";
import FileUploader from "../../../../UI-kit/FileUploader";
import Checkbox from "../../../../UI-kit/Checkbox";
import { getUserAnswers } from "../../../../../store/userAnswers/selectors";
import { setUserAnswer } from "../../../../../store/userAnswers/actions";
import { type } from "os";
import { CheckBoxLabel } from "../../../../UI-kit/CheckBoxLabel";
import { key_divider } from "../../../../../constants/elements";
import { CRUDStructElement } from "../../CRUDStructElement";
import { useParams } from "react-router-dom";
import { API_URL, Application } from "../../../../../api/methods";
import { LinkButton } from "../../../../UI-kit/Buttons/LinkButton";
import questionnaireFunc from "../../../../../functions/questionnaireFunc";

const UploadFilePart = ({ struct, element, addStruct, deleteStruct }) => {
  const { id, name } = element;
  const key = id;

  const { application_id } = useParams();

  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);

  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [selectedValue, setSelectedValue] = useState();
  const [state, setState] = useState({ value: "" });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  useEffect(() => {
    setIsAddButtonDisabled(state.value == "");
  }, [state.value]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleAddStruct = () => {
    addStruct(element.id, state.value);
    setState({ state, value: "" });
  };

  const changeUserAnswerValue = (e) => {
    const { name, checked } = e.target;
    let currentValue = userAnswers[key]?.answer;

    currentValue =
      typeof currentValue == "undefined" || typeof currentValue == "string"
        ? {}
        : currentValue;

    currentValue[name] = checked;

    const userAnswer = questionnaireFunc.getAnswerInfo(
      element,
      currentValue,
      null
    );
    dispatch(setUserAnswer(key, userAnswer));
  };

  const handleCheckboxChange = (e) => {
    setState({ ...state, checked: e.target.checked });
  };

  const filterArrayByDuplicatesIds = (arr) =>
    arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const filteredStruct = filterArrayByDuplicatesIds(element.struct);

  function onClickAddFile() {
    document.getElementById(key).click();
  }

  const handleLoadFile = (e, index) => {
    const { files, name } = e.target;
    const [file] = files;

    Application.uploadFile({ file, application_id }, ({ data }) => {
      const userAnswer = questionnaireFunc.getAnswerInfo(
        element,
        data.link,
        null
      );
      dispatch(setUserAnswer(key, userAnswer));
    });
  };

  const handleCancelUploadFile = () => {
    const userAnswer = questionnaireFunc.getAnswerInfo(element.name, "", null);
    dispatch(setUserAnswer(key, userAnswer));
  };

  const answerObj = userAnswers[key]?.answer;
  const isChecked = userAnswers[key]?.answer
    ? Object.values(answerObj)[0]
    : false;

  const uploadMessage = typeof answerObj === "string" ? "Файл прикреплен" : "";
  const file_href = typeof answerObj === "string" ? answerObj : "";

  const isLinkInUserAnswer = file_href.length > 0;

  const handleCheckboxClick = isPreviewMode
    ? changeUserAnswerValue
    : handleCheckboxChange;
  return (
    <>
      {isPreviewMode && !isChecked ? (
        <>
          <Button onClick={onClickAddFile}>
            Прикрепить файл
            <input
              id={key}
              name={key}
              onChange={handleLoadFile}
              type="file"
              style={{ display: "none" }}
            ></input>
          </Button>
        </>
      ) : null}

      {isLinkInUserAnswer ? (
        <>
          <LinkButton
            style={{ marginRight: 10 }}
            href={API_URL.replace("/api/", "") + file_href}
            download
          >
            {uploadMessage}
          </LinkButton>
          <SecondOrderButton
            className={"delete-button"}
            onClick={handleCancelUploadFile}
          >
            Отменить загрузку
          </SecondOrderButton>
        </>
      ) : null}

      {isLinkInUserAnswer || isFileUploaded ? null : (
        <Text style={{ marginTop: 5, marginBottom: 10, color: gray }}>
          {isPreviewMode
            ? element.struct.length == 0
              ? ""
              : "Если у вас отсутствует файл, выберите ответ:"
            : "Вариант ответа, если пользователь не будет загружать файл"}
        </Text>
      )}

      {element.struct.length !== 0 || isPreviewMode ? null : (
        <Col>
          <Row>
            <InputButtonGroup>
              <FloatingInput
                name="value"
                onChange={handleChange}
                value={state.value}
                label={"Название элемента"}
              ></FloatingInput>
              <Button
                disabled={isAddButtonDisabled}
                onClick={handleAddStruct}
                margin={"10px 0"}
              >
                Добавить ответ
              </Button>
            </InputButtonGroup>
          </Row>
        </Col>
      )}

      {isLinkInUserAnswer || !isPreviewMode
        ? ""
        : filteredStruct.map(({ name, id }) => {
            const isChecked = userAnswers[key]?.answer
              ? userAnswers[key]?.answer[name]
              : false;
            return (
              <CheckBoxLabel key={id}>
                <Checkbox
                  name={name}
                  checked={isChecked}
                  onChange={handleCheckboxClick}
                />
                <span style={{ marginLeft: 13 }}>{name}</span>
              </CheckBoxLabel>
            );
          })}

      {!isPreviewMode ? (
        <CRUDStructElement
          elementId={element.id}
          struct={filteredStruct}
          deleteStruct={deleteStruct}
        />
      ) : null}
    </>
  );
};

export default UploadFilePart;
