import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "styled-bootstrap-grid";
import { key_divider } from "../../../../../constants/elements";
import questionnaireFunc from "../../../../../functions/questionnaireFunc";
import { getPreviewMode } from "../../../../../store/previewMode/selectors";
import { setUserAnswer } from "../../../../../store/userAnswers/actions";
import { getUserAnswers } from "../../../../../store/userAnswers/selectors";
import Button from "../../../../UI-kit/Buttons/Button";
import Checkbox from "../../../../UI-kit/Checkbox";
import { CheckBoxLabel } from "../../../../UI-kit/CheckBoxLabel";
import FloatingInput from "../../../../UI-kit/FloatingInput";
import InputButtonGroup from "../../../../UI-kit/InputButtonGroup";
import { Radio, RadioGroup } from "../../../../UI-kit/RadioGroup";
import { CRUDStructElement } from "../../CRUDStructElement";

const CheckBoxPart = ({ struct, element, addStruct, deleteStruct }) => {
  const { id, name } = element;
  const key = id;

  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);

  const [state, setState] = useState({ value: "", checked: false });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  useEffect(() => {
    setIsAddButtonDisabled(state.value == "");
  }, [state.value]);

  const changeUserAnswerValue = (e) => {
    const { name, checked } = e.target;
    let currentValue = userAnswers[key]?.answer;
    currentValue = typeof currentValue == "undefined" ? {} : currentValue;
    currentValue[name] = checked;

    const userAnswer = questionnaireFunc.getAnswerInfo(
      element,
      currentValue,
      null
    );
    dispatch(setUserAnswer(key, userAnswer));
  };

  const handleCheckboxChange = (e) => {
    setState({ ...state, checked: e.target.checked });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleAddStruct = () => {
    addStruct(element.id, state.value);
    setState({ ...state, value: "" });
  };

  const handleCheckboxChangeClick = isPreviewMode
    ? changeUserAnswerValue
    : handleCheckboxChange;

  return (
    <>
      {isPreviewMode ? null : (
        <Col>
          <Row>
            <InputButtonGroup>
              <FloatingInput
                name="value"
                onChange={handleChange}
                value={state.value}
                label={"Название элемента"}
              ></FloatingInput>
              <Button
                disabled={isAddButtonDisabled}
                onClick={handleAddStruct}
                margin={"10px 0"}
              >
                Добавить ответ
              </Button>
            </InputButtonGroup>
          </Row>
        </Col>
      )}
      <Col>
        {!isPreviewMode
          ? null
          : element.struct.map(({ name, id }) => {
              const isChecked = userAnswers[key]?.answer
                ? userAnswers[key]?.answer[name]
                : false;

              return (
                <Row key={id}>
                  <CheckBoxLabel>
                    <Checkbox
                      name={name}
                      checked={isChecked}
                      onChange={handleCheckboxChangeClick}
                    />
                    <span style={{ marginLeft: 13 }}>{name}</span>
                  </CheckBoxLabel>
                </Row>
              );
            })}
      </Col>
      {!isPreviewMode ? (
        <CRUDStructElement
          elementId={element.id}
          struct={element.struct}
          deleteStruct={deleteStruct}
        />
      ) : null}
    </>
  );
};

export default CheckBoxPart;
