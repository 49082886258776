import React from "react";

const OrderTableSelect = ({
  options,
  onChange,
  selectedValue,
  placeholder,
  name = "",
  defaultValue = null,
  disabledPlaceholder = true,
}) => (
  <select onChange={onChange} style={{ outline: "none" }} name={name}>
    <option
      selected={selectedValue == null}
      value={defaultValue}
      disabled={disabledPlaceholder}
    >
      {placeholder}
    </option>
    {options.map(({ value, label }) => (
      <option key={value} selected={selectedValue == value} value={value}>
        {label}
      </option>
    ))}
  </select>
);

export default OrderTableSelect;
