import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Application, Delivery } from "../../api/methods";

import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../components/UI-kit/FloatingInput";
import FloatingSelect from "../../components/UI-kit/FloatingSelect";
import Radio from "../../components/UI-kit/Radio";
import Subtitle2 from "../../components/UI-kit/Subtitle2";
import Text from "../../components/UI-kit/Text";
import Title from "../../components/UI-kit/Title";
import { green } from "../../constants/Colours";

import { PATHS } from "../../constants/RoutesPaths";
import { dateTranslate } from "../../functions/common";
import { T } from "../../lang/ru";

const options = [
  { id: 1, name: "Иваново" },
  { id: 2, name: "Шуя" },
];

const OrderDelivery = (props) => {
  const id = props.match.params.id;

  const history = useHistory();
  const [allTypesDelivery, setAllTypesDelivery] = useState([]);

  const [isDisabledUpdateButton, setIsDisabledUpdateButton] = useState(true);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [values, setValues] = useState({
    cost: "",
    is_needed: 1,
    name: "",
    region: "",
    term: "",
    track_number: "",
    delivery_method_id: -1,
  });
  const {
    delivery_method_id,
    region,
    track_number,
    name,
    term,
    cost,
    is_needed,
    address,
  } = values;

  const [isNeedDelivery, setIsNeedDelivery] = useState(
    is_needed == "1" ? "true" : "false"
  );
  useEffect(() => {
    const isNeed = values.is_needed == 1 ? "true" : "false";
    setIsNeedDelivery(isNeed);
  }, [values.is_needed]);

  useEffect(() => {
    setIsDisabledUpdateButton(false);
    setSaveSuccess(false);
    setValues((prev) => {
      return { ...prev, is_needed: isNeedDelivery == "true" ? 1 : 0 };
    });
  }, [isNeedDelivery]);

  useEffect(() => {
    getAllTypesDelivery();
    getDelivery();
  }, []);

  const getAllTypesDelivery = () => {
    Delivery.getAll({}, getAllypesDeliverySuccess);
  };

  const getAllypesDeliverySuccess = ({ data }) => {
    setAllTypesDelivery(data);
  };

  const getDelivery = () => {
    Application.getDeliveryById({ id }, getDeliverySuccess);
  };

  const getDeliverySuccess = ({ data }) => {
    const date = data[0].term ? data[0].term.split(" ")[0] : "";

    setValues({ ...data[0], term: date });
  };

  const onChange = (e) => {
    let { value, name } = e.target;
    setIsDisabledUpdateButton(false);
    setSaveSuccess(false);
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onClickSave = () => {
    updateDelivery();
  };

  const updateDelivery = () => {
    Application.updateDelivery({ ...values, id }, updateDeliverySuccess);
  };

  const updateDeliverySuccess = ({ data }) => {
    setIsDisabledUpdateButton(true);
    setSaveSuccess(true);
    // goToDefaultPath();
  };

  const goToDefaultPath = () => {
    history.push(PATHS.admin.ordersHistory);
  };

  return (
    <>
      <Title>{T.filterNames.delivery}</Title>

      <Subtitle2>{T.needDelivery}</Subtitle2>
      <Radio
        radios={T.isNeedDeliveryRadio}
        nameRadio="needDelivery"
        setValue={setIsNeedDelivery}
        radioValue={isNeedDelivery}
        flexDirect={false}
      ></Radio>

      {isNeedDelivery == "false" ? null : (
        <>
          <Subtitle2>{T.chooseTypeDelivery}</Subtitle2>
          <FloatingSelect
            value={delivery_method_id ? delivery_method_id : -1}
            title={T.typeDeliveryTitle}
            name="delivery_method_id"
            onChange={onChange}
            options={allTypesDelivery}
          ></FloatingSelect>
          <Subtitle2>Адрес доставки</Subtitle2>
          <FloatingInput
            name="address"
            onChange={onChange}
            value={address == null ? "" : address}
          />

          {delivery_method_id && delivery_method_id !== -1 ? (
            <>
              <Subtitle2>{T.deliveryTime}</Subtitle2>
              <FloatingInput
                name="term"
                onChange={onChange}
                value={term}
                type="date"
              />
              <Subtitle2>{T.deliveryPrice}</Subtitle2>
              <FloatingInput
                name="cost"
                placeholder={T.deliveryPrice}
                onChange={onChange}
                value={cost}
              />
            </>
          ) : (
            <></>
          )}
          <Subtitle2>{T.enterTrackNumber}</Subtitle2>
          <FloatingInput
            name="track_number"
            placeholder={T.trackNumber}
            onChange={onChange}
            value={track_number != "null" ? track_number : ""}
          />
        </>
      )}

      <Button disabled={isDisabledUpdateButton} onClick={onClickSave}>
        {T.save}
      </Button>
      <SecondOrderButton onClick={goToDefaultPath} marginLeft>
        {T.backToRequests}
      </SecondOrderButton>
      <Text style={{ marginTop: 10 }} color={green}>
        {saveSuccess ? "Изменения успешно сохранены!" : null}
      </Text>
    </>
  );
};

export default OrderDelivery;
