import axios from "axios";
import { S_IFMT } from "constants";
import { PATHS } from "../constants/RoutesPaths";
import { store } from "../index";
import { setLoaderState } from "../store/loader/actions";

// const baseUrl = "http://ndevelop.ru";
// const baseUrl = "http://new.fabras.ru"
export const API_URL: string | undefined = process.env.REACT_APP_API_URL;

export type AnyJson = boolean | number | string | null | JsonArray | JsonObject;
interface JsonObject {
  [key: string]: AnyJson;
}
interface JsonArray extends Array<AnyJson> {}
type ArgType = string;

type DataArgs = {
  [key: string]: ArgType;
};
type SuccessType = (res: any) => void;
type ErrorType = (err: any) => void;

type MethodType = {
  [key: string]: (
    data?: DataArgs,
    success?: SuccessType,
    fail?: ErrorType
  ) => void;
};

const convertJSONToUrlEncoded: (obj: any) => string = (obj) => {
  var str = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      str.push(key + "=" + obj[key]);
    }
  }
  return str.join("&");
};

const goToLogin = () => {
  document.location.replace(PATHS.login);
};

const successCallback = (res: AnyJson, success: any) => {
  store.dispatch(setLoaderState("SUCCESS"));
  setTimeout(() => store.dispatch(setLoaderState("DEFAULT")), 2000);
  success(res);
};

const failFunction = (res: any, fail: any) => {
  store.dispatch(setLoaderState("ERROR"));
  setTimeout(() => store.dispatch(setLoaderState("DEFAULT")), 2000);
  fail(res);
};
//  все касатемое рефреша токена
let isRefreshProcessing = false;

// Список зафейленных функций от атворизации для выполнения их после рефреша токена
let failedByAuthorizationFunctions: any = [];

const getToken = (token: any) => "Bearer " + token;

const commonRequest = (
  methodClass: string,
  requestType:
    | "POST"
    | "GET"
    | "PUT"
    | "DELETE"
    | "get"
    | "delete"
    | "head"
    | "HEAD"
    | "options"
    | "OPTIONS"
    | "post"
    | "put"
    | "patch"
    | "PATCH"
    | "link"
    | "LINK"
    | "unlink"
    | "UNLINK"
    | undefined,
  method: string,
  data: DataArgs,
  success: SuccessType = () => {},
  fail: ErrorType = () => {}
) => {
  const isDataEmpty = Object.keys(data).length === 0;

  type TokenType = string | null;
  let token: TokenType = localStorage.getItem("accessToken");
  let response: (token: TokenType) => any;

  // store.dispatch(setLoaderState("LOADING"));

  let formData = new FormData();

  let isHaveFile = false;

  for (const key in data) {
    if (key.includes("file")) isHaveFile = true;
    formData.append(key, data[key]);
  }

  const headers = {
    "Content-Type":
      requestType === "PUT" ? "x-www-form-urlencoded" : "multipart/form-data",
    Authorization: getToken(token),
  };
  const responseType: "blob" | "json" = isHaveFile ? "blob" : "json";

  const config = {
    headers,
    responseType,
  };

  if (method != "") {
    method = "/" + method;
  }

  let urlEncodedData = "";
  if (!isDataEmpty) {
    urlEncodedData = "?" + convertJSONToUrlEncoded(data);
  }

  const replaceToken = (config: any, token: any) => {
    config.headers.Authorization = getToken(token);
  };

  if (requestType === "GET")
    response = (token) => {
      replaceToken(config, token);
      return axios.get(API_URL + methodClass + method + urlEncodedData, config);
    };
  else if (requestType === "DELETE")
    response = (token) => {
      replaceToken(config, token);
      return axios.delete(API_URL + methodClass + method, config);
    };
  else if (requestType === "POST")
    response = (token) => {
      replaceToken(config, token);
      return axios({
        method: requestType,
        baseURL: API_URL,
        url: methodClass + method,
        headers,
        data: formData,
      });
    };
  else if (requestType === "PUT")
    response = (token) => {
      replaceToken(config, token);
      return axios.put(
        API_URL + methodClass + method + urlEncodedData,
        {},
        config
      );
    };
  else {
    response = (token) => {
      headers.Authorization = getToken(token);
      return axios({
        method: requestType,
        baseURL: API_URL,
        url: methodClass + method,
        headers,
        data: formData,
      });
    };
  }

  response(token)
    .then((res: any) => successCallback(res, success))
    .catch((res: AnyJson) => {
      const token = localStorage.getItem("accessToken");
      const isAuthError = JSON.stringify(res).includes("401");

      // если 401 и это не обнова токена
      if (isAuthError) {
        const isRefreshMethod = method.includes("refresh");

        if (isRefreshMethod) {
          failFunction(res, fail);
          return;
        }

        failedByAuthorizationFunctions.push({ response, success });

        if (!isRefreshProcessing) {
          isRefreshProcessing = true;

          Auth.refreshToken(
            { refreshToken: token ? token : "" },
            ({ data }) => {
              console.log("успех рефреша");

              const { access_token } = data;

              localStorage.setItem("accessToken", access_token);

              isRefreshProcessing = false;

              failedByAuthorizationFunctions.map(
                ({ response, success }: any) => {
                  response(access_token).then((res: any) =>
                    successCallback(res, success)
                  );
                }
              );
            },
            () => {
              goToLogin();
              localStorage.clear();
            }
          );
        }
      } else {
        failFunction(res, fail);
      }
    });
};

const CLASS = {
  auth: "auth",
  category: "category",
  form: "form",
  chain: "chain",
  element: "element",
  applications: "applications",
  payers: "payers",
  message: "message",
  company: "company",
  user: "user",
  delivery: "deliverymethods",
  source: "source",
  promocode: "promocode",
};

export const User: MethodType = {
  changeUserStatus: (data = { user_id: "", status: "" }, success, fail) => {
    commonRequest(CLASS.user, "POST", "status", data, success, fail);
  },
  getUsers: (data = {}, success, fail) => {
    commonRequest(CLASS.user, "GET", "", {}, success, fail);
  },
  getExperts: (data = {}, success, fail) => {
    commonRequest(CLASS.user, "GET", "experts", {}, success, fail);
  },
};

export const Auth: MethodType = {
  login: (data = { email: "", password: "" }, success, fail) => {
    commonRequest(CLASS.auth, "POST", "login", data, success, fail);
  },
  register: (
    data = {
      name: "",
      surname: "",
      middlename: "",
      phone: "",
      email: "",
      password: "",
    },
    success,
    fail
  ) => {
    commonRequest(CLASS.auth, "POST", "registration", data, success, fail);
  },
  logout: (data = {}, success, fail) => {
    commonRequest(CLASS.auth, "POST", "logout", data, success, fail);
  },
  infoAbout: (data = {}, success, fail) => {
    commonRequest(CLASS.auth, "POST", "me", data, success, fail);
  },
  refreshToken: (data = { refreshToken: "" }, success, fail) => {
    commonRequest(CLASS.auth, "POST", "refresh", data, success, fail);
  },
};

export const Category: MethodType = {
  add: (data = { name: "" }, success, fail) => {
    commonRequest(CLASS.category, "POST", "", data, success, fail);
  },
  getAll: (data = {}, success, fail) => {
    commonRequest(CLASS.category, "GET", "", {}, success, fail);
  },
};

export const Form: MethodType = {
  add: (data = { name: "", category_id: "" }, success, fail) => {
    commonRequest(CLASS.form, "POST", "", data, success, fail);
  },
  swap: (data = { id1: "", id2: "", form: "" }, success, fail) => {
    commonRequest(CLASS.form, "POST", "swap", data, success, fail);
  },
  change: (data = { id: "", index: "", form: "" }, success, fail) => {
    commonRequest(CLASS.form, "POST", "change", data, success, fail);
  },
  getAll: (data = { category_id: "" }, success, fail) => {
    commonRequest(
      CLASS.form,
      "GET",
      "all/" + data.category_id,
      {},
      success,
      fail
    );
  },
  getById: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.form, "GET", "" + data.id, {}, success, fail);
  },
  update: (data = { name: "", id: "" }, success, fail) => {
    const id = data.id;
    delete data.id;
    commonRequest(CLASS.form, "PUT", id, data, success, fail);
  },
  delete: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.form, "DELETE", data.id, {}, success, fail);
  },
};

export const Chain: MethodType = {
  add: (
    data = {
      type: "",
      form_id: "",
      parent_id: "",
      condition: "",
      tip: "",
      order_index: "",
    },
    success,
    fail
  ) => {
    data.href_name = "";
    data.required = "0";
    commonRequest(CLASS.chain, "POST", "", data, success, fail);
  },
  addFromFavorite: (
    data = {
      id: "",
      type: "",
      form_id: "",
      parent_id: "",
      condition: "",
      tip: "",
      order_index: "",
    },
    success,
    fail
  ) => {
    commonRequest(CLASS.chain, "POST", "fromfavorite", data, success, fail);
  },
};

const deleteEmptyFieldsData: (data: DataArgs) => void = (data) => {
  for (const key in data) {
    const element = data[key];
    const isEmptyField = element == null;
    if (isEmptyField) delete data[key];
  }
};

export const Element: MethodType = {
  update: (data = {}, success, fail) => {
    const id = data.id;
    delete data.id;
    deleteEmptyFieldsData(data);
    commonRequest(CLASS.element, "POST", "update/" + id, data, success, fail);
  },

  structUpdate: (data = { name: "", id: "" }, success, fail) => {
    const id = data.id;
    delete data.id;
    // deleteEmptyFieldsData(data);
    commonRequest(
      CLASS.element,
      "POST",
      "struct/update/" + id,
      data,
      success,
      fail
    );
  },
  deleteFavorite: (data = { id: "" }, success, fail) => {
    commonRequest(
      CLASS.element,
      "DELETE",
      `favorite/${data.id}`,
      {},
      success,
      fail
    );
  },

  addStructElement: (data = { name: "", id: "" }, success, fail) => {
    commonRequest(
      CLASS.element,
      "POST",
      `${data.id}/struct`,
      data,
      success,
      fail
    );
  },
  toggleFavorite: (data = { elem: "" }, success, fail) =>
    commonRequest(CLASS.element, "POST", `favorite`, data, success, fail),

  getAllFavorites: (data, success, fail) =>
    commonRequest(CLASS.element, "GET", `favorite`, {}, success, fail),
  getSystemNames: (data, success, fail) =>
    commonRequest(CLASS.element, "GET", `system_names`, {}, success, fail),

  deleteStructElement: (data = { id: "" }, success, fail) => {
    commonRequest(
      CLASS.element,
      "DELETE",
      `struct/${data.id}`,
      {},
      success,
      fail
    );
  },
  deleteElement: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.element, "DELETE", data.id, {}, success, fail);
  },
};

export const Application: MethodType = {
  uploadFile: (data = { file: "", application_id: "" }, success, fail) => {
    commonRequest(CLASS.applications, "POST", "addfile", data, success, fail);
  },
  add: (data = { payer_id: "", form_id: "", answer: "" }, success, fail) => {
    commonRequest(CLASS.applications, "POST", "", data, success, fail);
  },
  finish: (
    data = {
      id: "",
      answer: "",
      is_needed: "",
      delivery_method_id: "",
      address: "",
      is_adress_from_payer: "",
      promocode_id: "",
      wishes: "",
    },
    success,
    fail
  ) => {
    commonRequest(CLASS.applications, "POST", "finish", data, success, fail);
  },
  update: (data = {}, success, fail) => {
    commonRequest(CLASS.applications, "PUT", data.id, data, success, fail);
  },
  loadAct: (data = { fileType: "", id: "" }, success, fail) => {
    commonRequest(
      CLASS.applications,
      "GET",
      `${data.id}/akt/${data.fileType}`,
      {},
      success,
      fail
    );
  },
  addFile: (data = { application_id: "", file: "" }, success, fail) => {
    commonRequest(CLASS.applications, "POST", "finish", data, success, fail);
  },
  getById: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.applications, "GET", data.id, {}, success, fail);
  },
  getAll: (
    data = {
      start_time: "1920-11-10 00:00:00",
      end_time: "2090-11-10 00:00:00",
    },
    success,
    fail
  ) => {
    commonRequest(CLASS.applications, "GET", "", data, success, fail);
  },
  sendDocs: (data = { id: "" }, success, fail) => {
    commonRequest(
      CLASS.applications,
      "GET",
      "senddocs/" + data.id,
      {},
      success,
      fail
    );
  },
  getWithDate: (
    data = {
      start_time: "",
      end_time: "",
    },
    success,
    fail
  ) => {
    commonRequest(CLASS.applications, "GET", "", data, success, fail);
  },
  loadResults: (data = { file: "", id: "" }, success, fail) => {
    const id = data.id;
    delete data.id;
    commonRequest(
      CLASS.applications,
      "POST",
      `${id}/result`,
      data,
      success,
      fail
    );
  },
  setExpert: (data = { app_id: "", expert_id: "" }, success, fail) => {
    commonRequest(
      CLASS.applications,
      "PUT",
      `setexpert/${data.app_id}`,
      { expert_id: data.expert_id },
      success,
      fail
    );
  },
  setPaymentStatus: (data = { app_id: "", status: "" }, success, fail) => {
    commonRequest(
      CLASS.applications,
      "PUT",
      `setpaymentstatus/${data.app_id}`,
      { status: data.status },
      success,
      fail
    );
  },
  setStatus: (data = { app_id: "", status: "" }, success, fail) => {
    commonRequest(
      CLASS.applications,
      "PUT",
      `setstatus/${data.app_id}`,
      { status: data.status },
      success,
      fail
    );
  },
  getDeliveryById: (data = { id: "" }, success, fail) => {
    commonRequest(
      CLASS.applications,
      "GET",
      `${data.id}/delivery`,
      {},
      success,
      fail
    );
  },
  updateDelivery: (
    data = {
      cost: "",
      is_needed: "",
      name: "",
      region: "",
      address: "",
      term: "",
      track_number: "",
      id: "",
    },
    success,
    fail
  ) => {
    commonRequest(
      CLASS.applications,
      "POST",
      `${data.id}/delivery`,
      data,
      success,
      fail
    );
  },
  delete: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.applications, "DELETE", data.id, {}, success, fail);
  },
};

export const Payers: MethodType = {
  add: (
    data = {
      type: "",
      name_ip: "",
      legal_adress: "",
      inn: "",
      ogrn: "",
      bank: "",
      bik_bank: "",
      surname: "",
      middlename: "",
      name: "",
      kor: "",
      ras: "",
      phone: "",
      email: "",
      adress: "",
      number: "",
      who_give: "",
      date_get: "",
      serial: "",
      name_org: "",
      kpp: "",
      position: "",
    },
    success,
    fail
  ) => {
    commonRequest(CLASS.payers, "POST", "", data, success, fail);
  },
  getAll: (data = {}, success, fail) => {
    commonRequest(CLASS.payers, "GET", "", data, success, fail);
  },
  getById: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.payers, "GET", data.id, {}, success, fail);
  },
};

export const Message: MethodType = {
  send: (data = { id: "", message: "", file: "" }, success, fail) => {
    commonRequest(CLASS.message, "POST", data.id, data, success, fail);
  },
  getAllMessage: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.message, "GET", data.id, {}, success, fail);
  },
  delete: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.message, "DELETE", data.id, {}, success, fail);
  },
  loadAttachedFile: (data = { file_id: "" }, success, fail) => {
    commonRequest(
      CLASS.message,
      "GET",
      "file/" + data.file_id,
      {},
      success,
      fail
    );
  },
};

export const Company: MethodType = {
  get: (data = {}, success, fail) => {
    commonRequest(CLASS.company, "GET", "", {}, success, fail);
  },
  recordInformation: (
    data = {
      nameCompany: "",
      legalAddress: "",
      fullNameCompany: "",
      mailingAddress: "",
      directorName: "",
      directorSurname: "",
      directorMiddlename: "",
      positionHeadCompany: "",
      positionHeadCompanyСase: "",
      documentBasis: "",
      INN: "",
      ogrn: "",
      bank: "",
      KPP: "",
      bikBank: "",
      phone: "",
    },
    success,
    fail
  ) => {
    console.log({ ...data });

    const backendKeys: any = {
      nameCompany: "name",
      legalAddress: "legal_address",
      fullNameCompany: "full_name",
      mailingAddress: "post_address",
      directorName: "director_name",
      directorSurname: "director_surname",
      directorMiddlename: "director_middlename",
      positionHeadCompany: "director_position",
      positionHeadCompanyСase: "director_position_r",
      documentBasis: "signature_doc",
      INN: "inn",
      bank: "bank",
      KPP: "kpp",
      ogrn: "ogrn",
      bikBank: "bik",
      phone: "phone",
    };
    const oldNames = Object.keys(data);

    oldNames.forEach((frontKey) => {
      data[backendKeys[frontKey]] = data[frontKey];
    });
    commonRequest(CLASS.company, "POST", "", data, success, fail);
  },
  signature: (data = { file: "" }, success, fail) => {
    commonRequest(CLASS.company, "POST", "signature", data, success, fail);
  },
  print: (data = { file: "" }, success, fail) => {
    commonRequest(CLASS.company, "POST", "print", data, success, fail);
  },
};

export const Delivery: MethodType = {
  add: (data = { name: "", price: "" }, success, fail) => {
    commonRequest(CLASS.delivery, "POST", "", data, success, fail);
  },
  getAll: (data = {}, success, fail) => {
    commonRequest(CLASS.delivery, "GET", "", {}, success, fail);
  },
  update: (data = { name: "", price: "", id: "" }, success, fail) => {
    commonRequest(CLASS.delivery, "PUT", data.id, data, success, fail);
  },
  delete: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.delivery, "DELETE", data.id, {}, success, fail);
  },
};

export const Source: MethodType = {
  add: (data = { name: "" }, success, fail) => {
    commonRequest(CLASS.source, "POST", "", data, success, fail);
  },
  getAll: (data = {}, success, fail) => {
    commonRequest(CLASS.source, "GET", "", {}, success, fail);
  },
  delete: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.source, "DELETE", data.id, {}, success, fail);
  },
};

export const Promocode: MethodType = {
  add: (data = { name: "", value: "" }, success, fail) => {
    commonRequest(CLASS.promocode, "POST", "", data, success, fail);
  },
  getAll: (data = {}, success, fail) => {
    commonRequest(CLASS.promocode, "GET", "", {}, success, fail);
  },
  delete: (data = { id: "" }, success, fail) => {
    commonRequest(CLASS.promocode, "DELETE", data.id, {}, success, fail);
  },
  getByName: (data = { name: "" }, success, fail) => {
    commonRequest(CLASS.promocode, "GET", "/find", data, success, fail);
  },
};
// ?
