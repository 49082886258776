import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Title from "../../components/UI-kit/Title";
import FloatingSelect from "../../components/UI-kit/FloatingSelect";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import Text from "../../components/UI-kit/Text";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";
import { Company, Payers } from "../../api/methods";
import { setActiveStep } from "../../store/activeStep/actions";

const RequestChoosingCustomer: React.FC = () => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [options, setOptions] = useState<any>([]);
  const [customer, setCustomer] = useState<number>(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllPayers();
    dispatch(setActiveStep(1));
  }, []);

  const getAllPayers = () => {
    Payers.getAll({}, loadCustomers);
  };

  const loadCustomers: (data: any) => void = ({ data }) => {
    console.log(data);
    data = data.map((customerInfo: any) => {
      const { type, surname, name, middlename, name_org } = customerInfo;

      const customerName =
        type == 2 ? name_org : `${surname} ${name} ${middlename}`;
      return {
        ...customerInfo,
        name: customerName,
      };
    });

    // asd
    setOptions(data);
  };

  const onChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void = (
    e
  ) => {
    setCustomer(Number(e.target.value));
    setIsDisabled(false);
  };

  const goToRequestCategories: () => void = () => {
    history.push(
      PATHS.customer.requestCategories.replace(":payer_id", String(customer))
    );
  };

  const goToAddNewCustomer: () => void = () => {
    history.push(PATHS.customer.addNewCustomer);
  };

  const goToDefaultScreen: () => void = () => {
    history.push(PATHS.customer.ordersHistory);
  };

  const content =
    options.lenght !== 0 ? (
      <>
        <FloatingSelect
          value={customer}
          title={T.customer}
          name="customer"
          onChange={onChangeSelect}
          options={options}
        ></FloatingSelect>
        <Button onClick={goToRequestCategories} disabled={isDisabled}>
          {T.next}
        </Button>
      </>
    ) : (
      <Text>{T.noCustomers}</Text>
    );

  return (
    <>
      <Title>{T.customerChoice}</Title>
      {content}
      <SecondOrderButton onClick={goToAddNewCustomer} marginLeft marginRight>
        {T.addNewCustomerButton}
      </SecondOrderButton>
      <SecondOrderButton onClick={goToDefaultScreen} color="gray">
        {T.cancelOrderCreation}
      </SecondOrderButton>
    </>
  );
};

export default RequestChoosingCustomer;
