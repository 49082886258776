import React from "react";

import InputRadio from "../UI-kit/InputRadio";
import { Row } from "styled-bootstrap-grid";

type Radio = {
  radios: { value: any; name: string }[];
  nameRadio: string;
  setValue: any;
  radioValue: string;
  flexDirect?: boolean;
  margin?: string;
};

const Radio: React.FC<Radio> = ({
  radios = [],
  nameRadio = "",
  setValue,
  radioValue = "",
  flexDirect = false,
  margin = "15px -15px 20px",
}) => {
  const changeRadio: (e: any) => void = (e) => {
    setValue(e.target.id);
  };

  return (
    <Row
      style={{
        paddingLeft: 10,
        flexDirection: flexDirect ? "column" : "row",
        margin: `${margin}`,
      }}
    >
      {radios.map(({ value, name }) => (
        <InputRadio
          checked={radioValue == value ? true : false}
          id={value}
          name={nameRadio}
          onClick={changeRadio}
          placeholder={name}
          flexDirect={flexDirect}
          key={value}
        />
      ))}
    </Row>
  );
};

export default Radio;
