import React, { useEffect, useState } from "react";

import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";

import { T } from "../../../lang/ru";
import { AnyJson, API_URL, Application } from "../../../api/methods";
import Text from "../../../components/UI-kit/Text";
import { dateTranslate, getApplicationStatus } from "../../../functions/common";
import Axios from "axios";
import CustomerApplicationTable from "../../../components/UI-kit/Table/CustomerApplicationTable";
import { LinkButton } from "../../../components/UI-kit/Buttons/LinkButton";
import { gray } from "../../../constants/Colours";
import hooks from "../../../functions/hooks";
import { PATHS } from "../../../constants/RoutesPaths";
import { Link } from "react-router-dom";
import { Preloader } from "../../../components/UI-kit/Preloader/Preloader";

const OrderInfoCustomer = ({ id, isShowDocuments }) => {
  const [application, setApplication] = useState(null);

  const systemNames = hooks.useSystemNames();

  useEffect(() => {
    getApplication();
  }, []);

  const getApplication = () => {
    Application.getById({ id }, successGetApllication);
  };

  const successGetApllication = ({ data }) => {
    setApplication(data);
  };

  const loadFile = (url, fileName) => {
    let token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: "Bearer " + token,
    };

    url = API_URL.replace("/api/", "") + url;
    console.log(url);
    Axios({
      url: url,
      method: "GET",
      headers: headers,
      responseType: "blob",
    }).then((response) => {
      successLoadFile(response, fileName);
    });
  };

  const successLoadFile = (response, fileName) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  if (application === null) return <Preloader />;
  const {
    application_date,
    status,
    answer,
    name,
    surname,
    middlename,
    object_name,
    name_org,
    name_ip,
    payer_type,
    payer_id,
    price,
    docs,
    result_file,
    file_name = "",
    system_name_answers,
    wishes = "",
  } = application;

  const goToPayerInfoPage = () =>
    PATHS.customer.customerInfo.replace(":id", payer_id);

  return (
    <>
      <CustomerApplicationTable className="smoothPadding">
        <tbody>
          <tr>
            <td className="gray right">{T.orderNumber}</td>
            <td>{id}</td>
          </tr>
          <tr>
            <td className="gray right">{T.status}</td>
            <td>{getApplicationStatus(status)[0]?.label}</td>
          </tr>

          <tr>
            <td className="gray right">Тип объекта</td>
            <td>{object_name}</td>
          </tr>
          {systemNames.map(({ title }) => (
            <tr>
              <td className="gray right">{title}</td>
              <td>{system_name_answers ? system_name_answers[title] : "–"}</td>
            </tr>
          ))}
          <tr>
            <td className="gray right">Дата создания заявки</td>
            <td>{dateTranslate(application_date)}</td>
          </tr>
          <tr>
            <td className="gray right">Пожелания</td>
            {wishes == "" ? (
              <td style={{ color: gray }}>Отсутствуют</td>
            ) : (
              <td>{wishes}</td>
            )}
          </tr>
          {isShowDocuments ? (
            <tr>
              <td className="gray right">{T.documents}</td>
              <td>
                {docs.length == 0 ? (
                  <span style={{ color: gray, border: "none", padding: 0 }}>
                    Отсутствуют
                  </span>
                ) : (
                  Object.keys(docs).map((docName, index) => {
                    const { href, docType } = docs[docName];

                    let [newDocName] = docName.split("(");
                    newDocName = newDocName.replace(" ", "");
                    newDocName += `. Заказ №${id}`;
                    const handleDownload = () =>
                      loadFile(href, newDocName + "." + docType);
                    return (
                      <>
                        <SecondOrderButton
                          style={{ paddingLeft: 0 }}
                          onClick={handleDownload}
                        >
                          {docName}
                        </SecondOrderButton>
                        {index % 2 == 1 ? <br /> : null}
                      </>
                    );
                  })
                )}

                {/* <SecondOrderButton>{T.contract}</SecondOrderButton> */}
                {/* <SecondOrderButton>{T.score}</SecondOrderButton> */}
              </td>
            </tr>
          ) : null}

          <tr>
            <td className="gray right">Результаты</td>

            <td>
              {result_file ? (
                <SecondOrderButton
                  style={{ paddingLeft: 0 }}
                  onClick={() => loadFile(result_file, file_name)}
                >
                  Скачать результаты
                </SecondOrderButton>
              ) : (
                <span style={{ color: gray, border: "none", padding: 0 }}>
                  Отсутствуют
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </CustomerApplicationTable>
    </>
  );
};

export default OrderInfoCustomer;
