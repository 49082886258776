import React, { useState } from "react";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../components/UI-kit/FloatingInput";
import Radio from "../../components/UI-kit/Radio";
import Subtitle2 from "../../components/UI-kit/Subtitle2";
import Title from "../../components/UI-kit/Title";
import { T } from "../../lang/ru";

type Props = {
  match: {
    params: {
      id: number;
    };
  };
};

const RegistryChangeAccount: React.FC<Props> = (props) => {
  const id = props.match.params.id;
  const [arrears, setArrears] = useState("");
  const [isPaid, setIsPaid] = useState("true");

  const onChangeInput: (e: any) => void = (e) => {
    setArrears(e.target.value);
  };

  return (
    <>
      <Title>{T.changeAccount}</Title>
      <FloatingInput
        name="arrears"
        placeholder={T.arrears}
        onChange={onChangeInput}
        value={arrears}
      ></FloatingInput>
      <Subtitle2>{T.paid}</Subtitle2>
      <Radio
        radios={T.isNeedDeliveryRadio}
        nameRadio="paid"
        setValue={setIsPaid}
        radioValue={isPaid}
      />
      <Button>{T.save}</Button>
      <SecondOrderButton marginLeft>{T.back}</SecondOrderButton>
    </>
  );
};

export default RegistryChangeAccount;
