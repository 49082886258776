import React, { useEffect, useState } from "react";
import { API_URL } from "../../api/methods";
import { key_divider } from "../../constants/elements";
import { LinkButton } from "../UI-kit/Buttons/LinkButton";
import SecondOrderButton from "../UI-kit/Buttons/SecondOrderButton";
import MainCustomerButton from "../UI-kit/Buttons/MainCustomerButton";
import ThirdOrderButton from "../UI-kit/Buttons/ThirdOrderButton";
import { Card } from "../UI-kit/Card";
import { CardsWrapper } from "../UI-kit/CardWrapper";
import Subtitle from "../UI-kit/Subtitle";
import Subtitle2 from "../UI-kit/Subtitle2";
import Text from "../UI-kit/Text";

const ApplicationInfo = ({ application }) => {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    formatAnswersToView(application.answer);
  }, [application]);

  const formatAnswersToView = (answers) => {
    const isEmptyAnswers = answers == "" || answers == null;
    if (isEmptyAnswers) return;

    answers = JSON.parse(answers);

    const sortIndex = (key_a, key_b) => {
      const a = answers[key_a];
      const b = answers[key_b];
      const indexA = a.orderIndex;
      const indexB = b.orderIndex;

      if (indexA > indexB) {
        return 1;
      }
      if (indexA < indexB) {
        return -1;
      }
      return 0;
    };

    const removeFileDuplicateOfRepeatedList = (id) => {
      const isFile = id.toString().includes("_file_");
      return !isFile;
    };

    const answersArray = Object.keys(answers)
      .sort(sortIndex)
      .filter(removeFileDuplicateOfRepeatedList)
      .map((id) => {
        const answer = answers[id];

        const { Title, parentId } = answer;

        const tab = id.toString().split("_").length;

        return {
          id,
          tab,
          parentId,
          question: Title,
          answer: getAnswersRightPart(answer.answer),
        };
      });

    setAnswers(answersArray);
  };

  const getAnswersRightPart = (rightPart) => {
    const rightPartJSON = JSON.stringify(rightPart);
    const isRightPartObject =
      typeof rightPart === "object" &&
      !rightPartJSON.includes("[") &&
      !rightPartJSON.includes("]");

    if (isRightPartObject) return answerObjectToString(rightPart);
    else return rightPart;
  };

  const answerObjectToString = (rightPartObject) => {
    const buf = { ...rightPartObject };
    console.log(
      Object.keys(buf)
        .map((key) => {
          if (buf[key] == true) return key;
        })
        .filter((el) => el)
    );

    return Object.keys(rightPartObject)
      .map((key) => {
        if (rightPartObject[key] == true) return key;
      })
      .filter((el) => el)
      .join(", ");
  };

  const renderAnswer = (answer, question = "") =>
    isAnswerHref(answer)
      ? getLinkAnswer(answer, question)
      : isArrayAnswer(answer)
      ? getListAnswer(answer)
      : answer;

  const isArrayAnswer = (answer) => typeof answer === "object";

  const isAnswerHref = (answer) => answer.includes("storage");

  const getListAnswer = (answer) =>
    isComplexListAnswer(answer)
      ? renderComplexList(answer)
      : renderRepeatedList(answer);

  const isComplexListAnswer = (answer) => !JSON.stringify(answer).includes("{");

  const renderComplexList = (answer) =>
    answer.map((info) => <Text style={{ border: "none" }}>{info}</Text>);

  const renderRepeatedList = (answer) => (
    <CardsWrapper style={{ border: "none", alignItems: "flex-start" }}>
      {answer.map((info) => renderInfoOfArray(info))}
    </CardsWrapper>
  );

  const renderInfoOfArray = (info) => (
    <Card style={{ border: "none", alignItems: "flex-start", marginRight: 20 }}>
      {getRepeatedListRows(info)}
    </Card>
  );

  const getRepeatedListRows = (info) => {
    let isFileExist = checkExistFile(info);
    const defaultStyle = { padding: 0, border: "none", marginBottom: 16 };

    return Object.keys(info).map((key, index) => {
      const isTitle = index == 0;
      const isKeyFile = key == "file";

      const value = info[key];

      if (isFileExist && isKeyFile)
        return (
          <LinkButton
            style={defaultStyle}
            href={API_URL.replace("/api/", "") + value}
            download
          >
            Файл
          </LinkButton>
        );

      if (isFileExist && !isTitle) return null;

      return isTitle ? (
        <b style={defaultStyle}>{value}</b>
      ) : (
        <Text style={defaultStyle}>{value}</Text>
      );
    });
  };

  const checkExistFile = (info) => {
    let isExist = false;
    Object.keys(info).forEach((key) => {
      if (key == "file") {
        const value = info[key];
        const isUndefined = typeof value === "undefined";
        if (!isUndefined) {
          const isEmpty = value.length == 0;
          if (!isEmpty) isExist = true;
        }
      }
    });
    return isExist;
  };

  const getLinkAnswer = (hrefToStorage, question) => (
    <ThirdOrderButton onClick={() => goToDownload(hrefToStorage, question)}>
      Скачать файл
    </ThirdOrderButton>
  );

  const goToDownload = (href, question) => {
    href = API_URL.replace("/api/", "") + href;

    let link = document.createElement("a");
    link.href = href;
    link.download = getTitleFile(question);
    link.click();
  };

  const getTitleFile = (question) => `Заявка №${application.id} – ${question}`;

  const getTabs = (tabCount) => {
    const tab = "_";
    const tabs = new Array(tabCount)
      .fill(0)
      .map(() => tab)
      .join("");
    return tabs;
  };

  let parentBuf = -1;
  const colors = [
    "#eee",
    "#cce5e8",
    "#cad2eb",
    "#dacaeb",
    "#e5caeb",
    "#e8bcd4",
  ];
  return (
    <>
      {answers.length == 0 ? (
        <Text> Ответы отсутсвуют</Text>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {answers.map(({ question, answer, tab, id, parentId }) => {
            const isNewBlock = parentId != parentBuf;
            parentBuf = parentId;
            const color = colors[tab];
            return (
              <div
                key={id}
                style={{
                  paddingTop: 0,
                  paddingBottom: 20,
                  marginTop: "-1px",
                  paddingLeft: tab * 20,

                  border: "1px solid #eee",
                  borderTop: `${isNewBlock ? 20 : 1}px solid ${
                    isNewBlock ? color : "#eee"
                  }`,
                  borderLeft: `${tab * 30}px solid ${color}`,
                }}
              >
                <Subtitle2
                  aria-label="Вопрос"
                  className="td-gray"
                  style={{ minWidth: 500, maxWidth: 500 }}
                >
                  {question}
                </Subtitle2>
                <div aria-label="Ответ пользователя">
                  <div style={{}}>{renderAnswer(answer, question)}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ApplicationInfo;
