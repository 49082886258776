import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "styled-bootstrap-grid";
import { Element } from "../../../../../api/methods";
import { key_divider } from "../../../../../constants/elements";
import questionnaireFunc from "../../../../../functions/questionnaireFunc";
import { getPreviewMode } from "../../../../../store/previewMode/selectors";
import { setUserAnswer } from "../../../../../store/userAnswers/actions";
import { getUserAnswers } from "../../../../../store/userAnswers/selectors";
import Button from "../../../../UI-kit/Buttons/Button";
import Checkbox from "../../../../UI-kit/Checkbox";
import { CheckBoxLabel } from "../../../../UI-kit/CheckBoxLabel";
import FloatingInput from "../../../../UI-kit/FloatingInput";
import InputButtonGroup from "../../../../UI-kit/InputButtonGroup";
import { SmallInput } from "../../../../UI-kit/SmallInput";
import { CRUDStructElement } from "../../CRUDStructElement";

const InputPart = ({ struct, element, addStruct, deleteStruct }) => {
  const { id, name } = element;
  const key = id;

  const dispatch = useDispatch();

  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);

  const [bufValue, setBufValue] = useState("");
  const [state, setState] = useState({ value: "" });
  // const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const type = (e) => {
    const newValue = questionnaireFunc.getAnswerInfo(element, e.target.value);
    dispatch(setUserAnswer(key, newValue));
  };

  const handleAddStruct = () => {
    addStruct(element.id, state.value);
    setState({ state, value: "" });
  };

  const handleCheckboxChange = (e) => {
    setState({ ...state, checked: e.target.checked });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const changeUserAnswerValue = (e) => {
    const { name, checked } = e.target;
    let currentValue = userAnswers[key]?.answer;

    const isCurrentValueNotObject =
      typeof currentValue == "undefined" || typeof currentValue == "string";
    const isCurrentValueObject = typeof currentValue === "object";

    let newValue = isCurrentValueNotObject ? {} : currentValue;

    if (isCurrentValueNotObject) setBufValue(currentValue);

    if (isCurrentValueObject) newValue = bufValue;
    else newValue[name] = checked;

    let answerInfo = questionnaireFunc.getAnswerInfo(element, newValue);
    dispatch(setUserAnswer(key, answerInfo));
  };

  const filterArrayByDuplicatesIds = (arr) =>
    arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const filteredStruct = filterArrayByDuplicatesIds(element.struct);

  const isAnswerObject = typeof userAnswers[key]?.answer === "object";

  const inputValue = isAnswerObject ? bufValue : userAnswers[key]?.answer;

  const handleChangeCheckbox = isPreviewMode
    ? changeUserAnswerValue
    : handleCheckboxChange;

  return (
    <>
      {!isPreviewMode ? null : (
        <FloatingInput
          disabled={isAnswerObject}
          onChange={type}
          value={inputValue}
        />
      )}

      {element?.struct.length !== 0 || isPreviewMode ? null : (
        <InputButtonGroup>
          <FloatingInput
            name="value"
            onChange={handleChange}
            value={state.value}
            label={"Название элемента"}
          ></FloatingInput>
          <Button
            disabled={state.value == ""}
            onClick={handleAddStruct}
            margin={"10px 0"}
          >
            Добавить ответ
          </Button>
        </InputButtonGroup>
      )}
      {isPreviewMode
        ? filteredStruct.map(({ name, id }) => {
            const isChecked = userAnswers[key]?.answer
              ? userAnswers[key]?.answer[name]
              : false;

            return (
              <CheckBoxLabel key={id}>
                <Checkbox
                  name={name}
                  checked={isChecked}
                  onChange={handleChangeCheckbox}
                />
                <span style={{ marginLeft: 13 }}>{name}</span>
              </CheckBoxLabel>
            );
          })
        : null}

      {!isPreviewMode ? (
        <CRUDStructElement
          elementId={element.id}
          struct={element.struct}
          deleteStruct={deleteStruct}
        />
      ) : null}
    </>
  );
};

export default InputPart;
