import React, { useEffect, useState } from "react";
import { Application, Auth } from "../../api/methods";
import ApplicationInfo from "../../components/Application/ApplicationInfo";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [currentApplicationIndex, chooseApplicationIndex] = useState(null);

  useEffect(() => {
    authorize();
  }, []);

  const authorize = () => Auth.login({ email: "", password: "" }, authSuccess);

  const authSuccess = (res) => {
    const { access_token, status } = res.data;
    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("status", status);
    document.reload();
  };

  useEffect(() => {
    loadApplications();
  }, []);

  const loadApplications = () => {
    Application.getAll({}, loadApplicationsSuccess);
  };

  const loadApplicationsSuccess = ({ data }) => {
    setApplications(data);
    chooseApplicationIndex(data.length - 1);
  };

  return (
    <>
      {/* {applications.map((application, index) => (
        <Button
          disabled={index == currentApplicationIndex}
          margin={"10px 10px 10px 0"}
          onClick={() => chooseApplicationIndex(index)}
        >
          {application.id}
        </Button>
      ))} */}
      {currentApplicationIndex === null ? null : (
        <ApplicationInfo application={applications[currentApplicationIndex]} />
      )}
    </>
  );
};

export default Applications;
