import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Application } from "./api/methods";
import LayoutForRoutes from "./components/Navigation/LayoutForRoutes/LayoutForRoutes";
import { FullscreenContainer } from "./components/UI-kit/FullscreenContainer";
import { FullscreenPreloader } from "./components/UI-kit/Preloader/FullscreenPreloader";
import { Preloader } from "./components/UI-kit/Preloader/Preloader";
import { PATHS } from "./constants/RoutesPaths";
import Main from "./routes/common/Main";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const isEmptyLS = localStorage.length === 0;

  const isNotLoginScreen = history.location.pathname !== PATHS.login;
  const isNotRegister = history.location.pathname !== PATHS.register;
  const isNotAgreement = history.location.pathname !== PATHS.agreement;

  const isLoginScreen = history.location.pathname === PATHS.login;
  const isRegister = history.location.pathname === PATHS.register;
  const isAgreement = history.location.pathname === PATHS.agreement;

  const isShowAlways = isLoginScreen || isRegister || isAgreement;
  useEffect(() => {
    const isNeedRedirect =
      isEmptyLS && isNotLoginScreen && isNotRegister && isNotAgreement;

    if (isNotLoginScreen && isNotRegister && isNotAgreement) {
      let isReturn = false;
      Application.getAll(
        {},
        () => {},
        (res) => {
          const isAuthError = JSON.stringify(res).includes("401");
          if (isAuthError) setIsLoading(false);
          isReturn = true;
        }
      );
      if (isReturn) return;
    }

    if (isNeedRedirect) {
      history.push(PATHS.login);
    }

    setIsLoading(false);
  }, []);

  if (isLoading && !isShowAlways) {
    return <FullscreenPreloader />;
  }

  return (
    <LayoutForRoutes>
      <Main></Main>
    </LayoutForRoutes>
  );
}

export default App;
