import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import ThirdOrderButton from "../../components/UI-kit/Buttons/ThirdOrderButton";
import Table from "../../components/UI-kit/Table/Table";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";
import { User } from "../../api/methods";
import { dateTranslate } from "../../functions/common";
import Text from "../../components/UI-kit/Text";
import { Preloader } from "../../components/UI-kit/Preloader/Preloader";

const CustomersList = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = () => User.getUsers({}, loadCustomersSuccess);
  const loadCustomersSuccess = ({ data }) => setData(data);

  const history = useHistory();

  const goToEditCustomer = (id) => {
    history.push(PATHS.admin.customerInfo.replace(":id", String(id)));
  };

  if (data == null) return <Preloader />;

  if (data.length == 0) return <Text>Исполнители отсутствуют</Text>;

  const goToCustomer = (id) => {
    history.push(
      PATHS.customer.customerInfo.replace(":id/:application_id?", id)
    );
  };
  return (
    <>
      <Table className="smoothPadding">
        <thead>
          <tr>
            <th>Имя</th>
            <th>Email</th>
            <th>Телефон</th>
            <th>Дата регистрации</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {data.map(
            ({ id, email, middlename, name, surname, phone, created_at }) => (
              <tr key={id}>
                <td>
                  {surname} {name} {middlename}
                </td>
                <td>{email}</td>
                <td>{phone}</td>
                <td>{dateTranslate(created_at)}</td>

                {/* <td>
                  <ThirdOrderButton onClick={() => goToEditCustomer(id)}>
                    {T.moreDetails}
                  </ThirdOrderButton>
                </td> */}
              </tr>
            )
          )}
        </tbody>
      </Table>
    </>
  );
};

export default CustomersList;
