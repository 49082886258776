import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Delivery } from "../../api/methods";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingSelect from "../../components/UI-kit/FloatingSelect";
import Title from "../../components/UI-kit/Title";
import { T } from "../../lang/ru";
import { setIsShowPopup } from "../../store/popups/actions";
import AddPopup from "./EditorDeliveries/AddPopup";
import DeletePopup from "./EditorDeliveries/DeletePopup";
import EditPopup from "./EditorDeliveries/EditPopup";

const EditorDeliveries = () => {
  const dispatch = useDispatch();
  const [delivery, setDelivery] = useState(-1);
  const [allDeliveries, setAllDeliveries] = useState([]);
  const [selectDelivery, setSelectDelivery] = useState(0);

  useEffect(() => {
    getAllDelivery();
  }, []);

  const getAllDelivery = () => {
    Delivery.getAll({}, getAllDeliverySuccess);
  };

  const getAllDeliverySuccess = ({ data }) => {
    setAllDeliveries(data);
  };

  const onChangeSelect = (e) => {
    setDelivery(e.target.value);
    setSelectDelivery(
      allDeliveries.findIndex(
        (item) => Number(item.id) === Number(e.target.value)
      )
    );
  };

  const onClickEdit = () => {
    dispatch(setIsShowPopup("isShowEditDelivery", true));
  };

  const onClickDelete = () => {
    dispatch(setIsShowPopup("isShowDeleteDelivery", true));
  };

  const onClickAdd = () => {
    dispatch(setIsShowPopup("isShowAddDelivery", true));
  };

  return (
    <>
      <Title>{T.editDeliveriesMethods}</Title>
      <FloatingSelect
        value={delivery}
        title={T.deliveryMethod}
        name="delivery"
        onChange={onChangeSelect}
        options={allDeliveries}
      ></FloatingSelect>
      <Button margin={"10px 15px 10px 0"} onClick={onClickAdd}>
        {T.addNewDelivery}
      </Button>
      {delivery !== -1 ? (
        <>
          <Button onClick={onClickEdit}>{T.editSelectDelivery}</Button>
          <SecondOrderButton
            marginLeft
            className="delete-button"
            onClick={onClickDelete}
          >
            {T.deleteSelectDelivery}
          </SecondOrderButton>
        </>
      ) : (
        <></>
      )}
      <EditPopup
        getAllDelivery={getAllDelivery}
        selectDelivery={allDeliveries[selectDelivery]}
      ></EditPopup>
      <AddPopup getAllDelivery={getAllDelivery}></AddPopup>
      <DeletePopup
        name={allDeliveries[selectDelivery]?.name}
        id={delivery}
        getAllDelivery={getAllDelivery}
        setDelivery={setDelivery}
      ></DeletePopup>
    </>
  );
};

export default EditorDeliveries;
