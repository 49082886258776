import React from "react";

const OrderTableInput = ({
  value,
  onChange,
  placeholder,
  name,
  onBlur,
  type = "text",
}) => (
  <input
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    name={name}
    onBlur={onBlur}
    style={{ outline: "none" }}
    type={type}
  ></input>
);

export default OrderTableInput;
