import React, { useEffect, useState } from "react";

import { Row, Container, Col } from "styled-bootstrap-grid";
import OrdersRegistryTable from "./OrderRegistry/OrdersRegistryTable";

import { Application, Delivery, Source } from "../../api/methods";
import Text from "../../components/UI-kit/Text";
import Radio from "../../components/UI-kit/Radio";
import { T } from "../../lang/ru";
import FloatingInput from "../../components/UI-kit/FloatingInput";
import Subtitle2 from "../../components/UI-kit/Subtitle2";
import MiniSelect from "../../components/UI-kit/MiniSelect";
import InputCheckbox from "../../components/UI-kit/InputCheckbox";
import MiniInput from "../../components/UI-kit/MiniInput";
import { isValidDateCheck } from "../../functions/validators";
import { dateTranslateToApi } from "../../functions/common";
import { Preloader } from "../../components/UI-kit/Preloader/Preloader";

const OrdersRegistry = () => {
  //const [data, setData] = useState<Data[]>(data);
  //const [filtersData, setFiltersData] = useState<Data[]>(data);
  // const [isValidDateCheck, setIsValidDateCheck] = useState(false);
  const [isValidDateFrom, setIsValidDateFrom] = useState(true);
  const [isValidDateTo, setIsValidDateTo] = useState(true);
  const [dateChanged, setDateChanged] = useState(false);
  const [type, setType] = useState("active");
  const [intervalDate, setIntervalDate] = useState({
    dateFrom: "",
    dateTo: "",
  });
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    contractNumber: { active: false, value: "" },
    source: { active: false, value: -1 },
    delivery: { active: false, value: -1 },
    objectType: { active: false, value: -1 },
  });
  const [options, setOptions] = useState({
    source: [
      { id: 0, name: "Другое" },
      { id: 1, name: "Не другое" },
    ],
    delivery: [
      { id: 0, name: "Другое" },
      { id: 1, name: "Не другое" },
    ],
    objectType: [
      { id: 0, name: "Др22222угое" },
      { id: 1, name: "Не другое" },
    ],
  });

  useEffect(() => {
    getAllDelivery();
    getAllSource();
  }, []);

  useEffect(() => {
    if (isValidDateFrom && isValidDateTo) {
      fetchApplications();
    }
  }, [dateChanged, type]);

  const fetchApplications = async () => {
    Application.getAll(
      {
        start_time:
          intervalDate.dateFrom === ""
            ? "1920-11-10 00:00:00"
            : await dateTranslateToApi(intervalDate.dateFrom),
        end_time:
          intervalDate.dateTo === ""
            ? "2090-11-10 00:00:00"
            : await dateTranslateToApi(intervalDate.dateTo),
        // is_active: type == "active" ? 1 : 0,
      },
      getAllApplicationsSuccess
    );
  };

  const getAllSource = () => {
    Source.getAll(undefined, (res) => getAllOptionsSuccess(res.data, "source"));
  };

  const getAllDelivery = () => {
    Delivery.getAll(undefined, (res) =>
      getAllOptionsSuccess(res.data, "delivery")
    );
  };

  const getAllApplicationsSuccess = ({ data }) => {
    setData(data);
  };

  const getAllOptionsSuccess = (data, name) => {
    setOptions((prev) => {
      return { ...prev, [name]: data };
    });
  };

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    setFilters((prev) => {
      return { ...prev, [name]: { active: prev[name].active, value: value } };
    });
  };

  const onChangeDate = (e) => {
    const { value, name } = e.target;
    setIntervalDate((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onBlurInputDate = (e) => {
    checkDateValid(e);
  };

  const checkDateValid = (e) => {
    const { value, name } = e.target;
    name === "dateFrom"
      ? setIsValidDateFrom(value !== "" ? isValidDateCheck(value) : true)
      : setIsValidDateTo(value !== "" ? isValidDateCheck(value) : true);
    setDateChanged((prev) => {
      return !prev;
    });
  };

  const onChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: { active: checked, value: filters[name].value },
    });
  };

  const onChangeSelect = (e) => {
    const { value, name } = e.target;
    setFilters({
      ...filters,
      [name]: { active: filters[name].active, value: Number(value) },
    });
  };

  if (data === null) return <Preloader />;

  return (
    <>
      {/* <Row style={{ marginTop: 15, position: "relative" }}>
        <Container style={{ overflowX: "auto" }}> */}
      <OrdersRegistryTable
        data={data}
        getApplicationWithDate={fetchApplications}
      />
      {/* </Container>
      </Row> */}
    </>
  );
};

export default OrdersRegistry;
