import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import FloatingInput from "../../components/UI-kit/FloatingInput";
import Subtitle2 from "../../components/UI-kit/Subtitle2";
import Title from "../../components/UI-kit/Title";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import InputsGrid from "../../components/UI-kit/InputsGrid";

import { T } from "../../lang/ru";
import { Company } from "../../api/methods";

const CompanyInfo = () => {
  const [companySettings, setCompanySettings] = useState({
    nameCompany: "",
    legalAddress: "",
    fullNameCompany: "",
    mailingAddress: "",
    fullnameHeadCompany: "",
    positionHeadCompany: "",
    positionHeadCompanyСase: "",
    documentBasis: "",
    INN: "",
    bank: "",
    KPP: "",
    bikBank: "",
    signature_file: "",
    print_file: "",
    phone: "",
  });
  const companyInfo = [
    { name: "nameCompany", placeholder: T.nameCompany },
    { name: "legalAddress", placeholder: T.legalAddress },
    { name: "fullNameCompany", placeholder: T.fullNameCompany },
    { name: "mailingAddress", placeholder: T.mailingAddress },
  ];
  const headCompanyInfo = [
    { name: "directorSurname", placeholder: T.surnameHeadCompany },
    { name: "directorName", placeholder: T.nameHeadCompany },
    { name: "directorMiddlename", placeholder: T.middlenameHeadCompany },
    { name: "positionHeadCompany", placeholder: T.positionHeadCompany },
    { name: "positionHeadCompanyСase", placeholder: T.positionHeadCompanyСase },
  ];
  const additionalCompanyInfo = [
    { name: "INN", placeholder: T.INN },
    { name: "bank", placeholder: T.bank },
    { name: "KPP", placeholder: T.KPP },
    { name: "ogrn", placeholder: "ОГРН" },
    { name: "bikBank", placeholder: T.bikBank },
    { name: "rAccount", placeholder: T.rAccount },
    { name: "kAccount", placeholder: T.kAccount },
    { name: "email", placeholder: T.email },
    { name: "phone", placeholder: T.phone },
  ];

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const loadCompanyInfo = () => Company.get({}, loadCompanyInfoSuccess);

  const loadCompanyInfoSuccess = ({ data }) => {
    for (const key in data) {
      const element = data[key];
      if (element == "null" || element == null) data[key] = "";
    }

    const info = {
      nameCompany: data["name"],
      legalAddress: data["legal_address"],
      fullNameCompany: data["full_name"],
      mailingAddress: data["post_address"],
      directorName: data["director_name"],
      directorSurname: data["director_surname"],
      directorMiddlename: data["director_middlename"],
      positionHeadCompany: data["director_position"],
      positionHeadCompanyСase: data["director_position_r"],
      documentBasis: data["signature_doc"],
      INN: data["inn"],
      bank: data["bank"],
      ogrn: data["ogrn"],
      KPP: data["kpp"],
      rAccount: data["rAccount"],
      kAccount: data["kAccount"],
      email: data["email"],
      bikBank: data["bik"],
      signature_file: data["signature_file"],
      print_file: data["print_file"],
      phone: data["phone"],
    };
    setCompanySettings(info);
  };

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    setCompanySettings({ ...companySettings, [name]: value });
  };

  function onClickAddCompanyStamp(e) {
    document.getElementById("print").click();
  }

  const onClickAddSupervisorSignature = () => {
    document.getElementById("signature").click();
  };

  const saveInfo = () => {
    console.log({ ...companySettings });
    Company.recordInformation(companySettings, saveSuccess);
  };

  const saveSuccess = ({ data }) => {};

  const signatureFileSrc =
    process.env.REACT_APP_API_URL.replace("/api/", "") +
    companySettings.signature_file;

  const printFileSrc =
    process.env.REACT_APP_API_URL.replace("/api/", "") +
    companySettings.print_file;

  const handleLoadFile = (e) => {
    const { files, name } = e.target;
    const [file] = files;
    if (name == "print") Company.print({ file }, loadCompanyInfo);
    else Company.signature({ file }, loadCompanyInfo);
  };

  return (
    <>
      <Title>{T.companySettings}</Title>
      <Subtitle2>{T.enterCompanyInformation}</Subtitle2>
      <InputsGrid maxWidth="710px" columns="1fr 1fr" gap="0">
        {companyInfo.map(({ name, placeholder }) => (
          <FloatingInput
            name={name}
            onBlur={saveInfo}
            placeholder={placeholder}
            value={companySettings[name]}
            onChange={onChangeInput}
            key={name}
          />
        ))}
      </InputsGrid>
      <Subtitle2>{T.addCompanyStamp}</Subtitle2>
      <div>
        <img
          src={printFileSrc}
          style={{ height: 180, border: "1px solid rgb(242, 242, 242)" }}
        ></img>
      </div>
      <Button onClick={onClickAddCompanyStamp}>
        {T.addFile}
        <input
          onChange={handleLoadFile}
          name="print"
          id="print"
          type="file"
          style={{ display: "none" }}
        ></input>
      </Button>

      <Subtitle2>{T.enterInformationHeadCompany}</Subtitle2>
      {headCompanyInfo.map(({ name, placeholder }) => (
        <FloatingInput
          name={name}
          onBlur={saveInfo}
          placeholder={placeholder}
          value={companySettings[name]}
          onChange={onChangeInput}
          key={name}
        />
      ))}
      <Subtitle2>{T.addSupervisorSignature}</Subtitle2>
      <div>
        <img
          src={signatureFileSrc}
          style={{ height: 180, border: "1px solid rgb(242, 242, 242)" }}
        ></img>
      </div>

      <Button onClick={onClickAddSupervisorSignature}>
        {T.addFile}
        <input
          onChange={handleLoadFile}
          name="signature"
          id="signature"
          type="file"
          style={{ display: "none" }}
        ></input>
      </Button>
      <FloatingInput
        name="documentBasis"
        onBlur={saveInfo}
        placeholder={T.onTheDocumentBasis}
        value={companySettings.documentBasis}
        onChange={onChangeInput}
      />
      <Subtitle2>{T.enterAdditionalCompanyInformation}</Subtitle2>
      <InputsGrid maxWidth="710px" columns="1fr 1fr" gap="0">
        {additionalCompanyInfo.map(({ name, placeholder }) => (
          <FloatingInput
            name={name}
            onBlur={saveInfo}
            placeholder={placeholder}
            value={companySettings[name]}
            onChange={onChangeInput}
            key={name}
          />
        ))}
      </InputsGrid>
    </>
  );
};

export default CompanyInfo;
