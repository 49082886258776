import React, { useEffect, useState } from "react";

import { Element } from "../../../api/methods";
import { SmallInput } from "../../UI-kit/SmallInput";

export const CRUDStructElement = ({ struct, deleteStruct, elementId }) => {
  const updateStruct = (id, name) => {
    Element.structUpdate({ id, name });
  };

  return (
    <>
      {struct.map(({ name, id }) => (
        <div>
          <SmallInput
            onBlur={(e) => updateStruct(id, e.target.value)}
            defaultValue={name}
          />
          <a
            style={{ marginLeft: 10, cursor: "pointer" }}
            onClick={() => deleteStruct(elementId, id)}
          >
            &times;
          </a>
        </div>
      ))}
    </>
  );
};
