import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Col, Row } from "styled-bootstrap-grid";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../components/UI-kit/FloatingInput";
import InputCheckbox from "../../components/UI-kit/InputCheckbox";
import Radio from "../../components/UI-kit/Radio";
import Subtitle2 from "../../components/UI-kit/Subtitle2";
import Title from "../../components/UI-kit/Title";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";
import { setActiveStep } from "../../store/activeStep/actions";
import { Application, Delivery, Payers } from "../../api/methods";
import FloatingSelect from "../../components/UI-kit/FloatingSelect";

const RequestDelivery = (props) => {
  const { application_id, payer_id, id } = props.match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isNeedDelivery, setIsNeedDelivery] = useState("true");
  const [isNeedConclusion, setIsNeedConclusion] = useState("TRUE");
  const [values, setValues] = useState({
    adress: "",
    deliveryAddressMatches: false,
    promo: "",
    wishes: "",
    delivery: -1,
  });
  const [allDeliveries, setlAllDeliveries] = useState([]);
  const { adress, deliveryAddressMatches, promo, wishes, delivery } = values;

  const [payerType, setPayerType] = useState(null);

  useEffect(() => {
    dispatch(setActiveStep(3));
    getAllDeliveries();
    loadCustomerInfo();
  }, []);

  const loadCustomerInfo = () => {
    Payers.getById({ id: payer_id }, ({ data }) => {
      console.log(data[0].type);
      if (data.length > 0) setPayerType(data[0].type);
    });
  };
  const getAllDeliveries = () => {
    Delivery.getAll({}, sucessGetAllDelivery);
  };

  const sucessGetAllDelivery = ({ data }) => {
    setlAllDeliveries(data);
  };

  const onChange = (e) => {
    let { value, name, type, checked } = e.target;
    if (type === "checkbox") {
      value = checked;
    }

    setValues((state) => {
      return { ...state, [name]: value };
    });
  };

  const onClickSend = () => {
    dispatch(setActiveStep(4));
    const userAnswers = localStorage.getItem("user_answers");

    const data = {
      id: application_id,
      answer: userAnswers,
      is_needed: isNeedDelivery == "true" ? 1 : 0,
      delivery_method_id: delivery,
      address: adress,
      is_adress_from_payer: deliveryAddressMatches ? 1 : 0,
      promocode_id: 0,
      wishes: wishes,
    };

    Application.finish(data, sendApplicationSuccess);
  };

  const sendApplicationSuccess = () => {
    history.push(PATHS.customer.ordersHistory);
  };

  const goToRequestCategories = () => {
    history.push(
      PATHS.customer.requestForms.replace(":id/:payer_id", String(id))
    );
  };

  return (
    <>
      <Title>{T.filterNames.delivery}</Title>
      <Subtitle2>{T.needDelivery}</Subtitle2>
      <Radio
        radios={T.isNeedDeliveryRadio}
        nameRadio="needDelivery"
        setValue={setIsNeedDelivery}
        radioValue={isNeedDelivery}
        flexDirect={false}
      ></Radio>
      {isNeedDelivery === "true" ? (
        <>
          <Subtitle2>{T.chooseTypeDelivery}</Subtitle2>
          <FloatingSelect
            value={delivery}
            title={T.deliveryMethod}
            name="delivery"
            onChange={onChange}
            options={allDeliveries}
          ></FloatingSelect>
          <Row>
            <Col col="auto">
              <FloatingInput
                placeholder={T.adress}
                value={adress}
                name="adress"
                onChange={onChange}
                disabled={deliveryAddressMatches}
              ></FloatingInput>
            </Col>
            <Col col="auto" style={{ display: "flex", alignItems: "center" }}>
              <InputCheckbox
                name="deliveryAddressMatches"
                placeholder={
                  payerType == null
                    ? T.deliveryAddressMatchesPhis
                    : payerType == 1
                    ? T.deliveryAddressMatchesPhis
                    : T.deliveryAddressMatches
                }
                onClick={onChange}
                checked={deliveryAddressMatches}
              ></InputCheckbox>
            </Col>
          </Row>
        </>
      ) : null}
      {/* <Subtitle2>{T.conslusion}</Subtitle2>
      <Radio
        radios={T.isNeedConclusionRadio}
        nameRadio="isNeedConclusion"
        setValue={setIsNeedConclusion}
        radioValue={isNeedConclusion}
        flexDirect={false}
      ></Radio>
      <Subtitle2>{T.youHavePromo}</Subtitle2>
      <FloatingInput
        placeholder={T.promo}
        value={promo}
        name="promo"
        onChange={onChange}
      ></FloatingInput> */}
      <Subtitle2>{T.yourWishes}</Subtitle2>
      <FloatingInput
        isTextarea
        value={wishes}
        name="wishes"
        onChange={onChange}
        width="500px"
      ></FloatingInput>
      <Button onClick={onClickSend}>{T.send}</Button>
      <SecondOrderButton marginLeft onClick={goToRequestCategories}>
        {T.back}
      </SecondOrderButton>
    </>
  );
};

export default RequestDelivery;
