import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import { accent, white, darkaccent } from "../../../constants/Colours";
import ThirdOrderButton from "./ThirdOrderButton";

const TableCustomerButton = styled(ThirdOrderButton)`
  & span {
    display: none;
  }

  @media ${device.tablet} {
    width: auto !important;
    font-size: 16px;
    & span {
      display: inline-block;
    }
  }

  &.table-id {
    @media ${device.tablet} {
      border: 1px solid ${accent} !important;
      padding: 5px 10px !important;
      margin-bottom: 25px;
      background-color: ${white};
      border-radius: 10px;
    }
  }
  &.table-name {
    @media ${device.tablet} {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      color: ${darkaccent};
    }
  }
`;

export default TableCustomerButton;
