import { throws } from "assert";
import React from "react";
import ReactDOM from "react-dom";
import FileUploadProgress from "react-fileupload-progress";
import { URL_TO_UPLOAD_FILE } from "../../api/constants";
import { accent } from "../../constants/Colours";
import Button from "./Buttons/Button";
import SecondOrderButton from "./Buttons/SecondOrderButton";
import ThirdOrderButton from "./Buttons/ThirdOrderButton";

const styles = {
  progressWrapper: {
    height: "50px",
    marginTop: "10px",
    width: "400px",
    float: "left",
    overflow: "hidden",
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
    WebkitBoxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
    boxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
  },
  progressBar: {
    float: "left",
    width: "0",
    height: "100%",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#fff",
    textAlign: "center",
    backgroundColor: accent,
    WebkitBoxShadow: "inset 0 -1px 0 rgba(0,0,0,.15)",
    boxShadow: "inset 0 -1px 0 rgba(0,0,0,.15)",
    WebkitTransition: "width .6s ease",
    Otransition: "width .6s ease",
    transition: "width .6s ease",
  },
  cancelButton: {
    marginTop: "5px",
    WebkitAppearance: "none",
    padding: 0,
    cursor: "pointer",
    background: "0 0",
    border: 0,
    float: "left",
    fontSize: "21px",
    fontWeight: 700,
    lineHeight: 1,
    color: "#000",
    textShadow: "0 1px 0 #fff",
    filter: "alpha(opacity=20)",
    opacity: ".2",
  },

  bslabel: {
    display: "inline-block",
    maxWidth: "100%",
    marginBottom: "5px",
    fontWeight: 700,
  },

  bsHelp: {
    display: "block",
    marginTop: "5px",
    marginBottom: "10px",
    color: "#737373",
  },

  bsButton: {
    padding: "1px 5px",
    fontSize: "12px",
    lineHeight: "1.5",
    borderRadius: "3px",
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
    display: "inline-block",
    padding: "6px 12px",
    marginBottom: 0,
    fontWeight: 400,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    backgroundImage: "none",
    border: "1px solid transparent",
  },
};

class FileUploader extends React.Component {
  formGetter = () => {
    return new FormData(document.getElementById("customForm"));
  };

  customFormRenderer = (onSubmit) => {
    const { id, isFileUploaded } = this.props;

    if (isFileUploaded) return null;

    return (
      <form id="customForm" style={{ marginBottom: "0" }}>
        <input
          style={{ display: "none" }}
          name="application_id"
          id="application_id"
          value={this.props.application_id}
        />
        <label
          style={{ color: accent, marginBottom: 0, cursor: "pointer" }}
          for={"exampleInputFile_" + id}
          class="custom-file-upload"
        >
          Выбрать файл
        </label>
        <input
          style={{ display: "none" }}
          type="file"
          name="file"
          id={"exampleInputFile_" + id}
          onChange={onSubmit}
        />
        {/* <button type="button" style={styles.bsButton} onClick={onSubmit}>
          Загрузить
        </button> */}
      </form>
    );
  };

  customProgressRenderer = (progress, hasError, cancelHandler) => {
    if (hasError || progress > -1) {
      let barStyle = Object.assign({}, styles.progressBar);
      barStyle.width = progress + "%";

      let message = <span>{barStyle.width}</span>;
      if (hasError) {
        barStyle.backgroundColor = "#d9534f";
        message = (
          <span style={{ color: "#a94442" }}>Не удалось загрузить...</span>
        );
      }
      // успех
      if (progress === 100) {
        if (!this.props.isFileUploaded) this.props.setIsFileUploaded(true);
        message = <span>Файл успешно загружен</span>;
      }

      const customCancelHandler = () => {
        this.props.setIsFileUploaded(false);
        cancelHandler();
        this.props.handleCancelUploadFile();
      };

      return (
        <div>
          <div style={styles.progressWrapper}>
            <div style={barStyle}></div>
          </div>
          <button style={styles.cancelButton} onClick={customCancelHandler}>
            <span>&times;</span>
          </button>
          <div style={{ clear: "left" }}>{message}</div>
        </div>
      );
    } else {
      return;
    }
  };

  render() {
    return (
      <div>
        <FileUploadProgress
          key="ex2"
          url={URL_TO_UPLOAD_FILE}
          method="post"
          onProgress={(e, request, progress) => {
            // console.log("progress", e, request, progress);
          }}
          onLoad={this.props.onFileUploadSuccess}
          onError={(e, request) => {
            // console.log("error", e, request);
          }}
          onAbort={(e, request) => {
            // console.log("abort", e, request);
          }}
          formGetter={this.formGetter}
          formRenderer={this.customFormRenderer}
          progressRenderer={this.customProgressRenderer}
        />
      </div>
    );
  }
}
export default FileUploader;
