import T from "./types";

const initialState = {
  isShowEditDelivery: false,
  isShowAddDelivery: false,
  isShowDeleteDelivery: false,
  isShowAddSource: false,
  isShowDeleteSource: false,
  isShowDeletePromocode: false,
  isShowAddPromocode: false,
  isShowDeleteMessage: false,
  isShowDeleteApplication: false,
};

export default function popups(state = initialState, action) {
  switch (action.type) {
    case T.SET_IS_SHOW_POPUP:
      return { ...state, [action.name]: action.value };
    default:
      return state;
  }
}
