import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Promocode } from "../../../api/methods";

import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import Text from "../../../components/UI-kit/Text";

import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const BoldText = styled.span`
  font-weight: bold;
`;

const DeletePopup = ({ name, id, getAllPromocodes, setPromocode }) => {
  const dispatch = useDispatch();
  const { isShowDeletePromocode } = useSelector(getIsShowPopups);

  const onClickYes = () => {
    deletePromocode();
  };

  const deletePromocode = () => {
    Promocode.delete({ id }, deletePromocodeSuccess);
  };

  const deletePromocodeSuccess = () => {
    setPromocode(-1);
    getAllPromocodes();
    closePopup();
  };

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowDeletePromocode", false));
  };

  const nameText = <BoldText>{name}</BoldText>;

  const buttons = (
    <ButtonsBlock>
      <Button onClick={onClickYes}>{T.yes}</Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert title={T.deleting} isShow={isShowDeletePromocode} buttons={buttons}>
      <Text>
        {T.deletePromocode}
        {nameText}?
      </Text>
    </Alert>
  );
};

export default DeletePopup;
