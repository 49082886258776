import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import {
  gray,
  lightgray,
  ultraLightgray,
  green,
  accent,
  black,
} from "../../../constants/Colours";
import SecondOrderButton from "../Buttons/SecondOrderButton";
import ThirdOrderButton from "../Buttons/ThirdOrderButton";

const Table = styled.table`
  font-family: "Fraunces", serif;
  font-size: 125%;
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid black;
  font-size: 16px !important;
  line-height: 160%;
  margin-bottom: 6px;
  border-collapse: collapse;
  th.fixed-col {
    background: #f8f8f8;
  }
  .fixed-col {
    position: sticky !important;
    left: 0;
    background: white;

    outline: 1px solid ${lightgray} !important;
    // border-right: 1px solid ${lightgray} !important;
  }

  & tr * {
    padding: 8px 60px 8px 20px;
    border: 1px solid ${lightgray};
  }
  & thead {
    & tr th {
      text-align: left;
      font-weight: normal;
      background-color: ${ultraLightgray};
      // color: ${gray};
    }
  }
  & tbody {
    & * {
      font-weight: regular;
    }
  }
  &.miniPadding {
    max-width: 1300px;
    overflow: auto;
    input {
      padding: 0 !important;
    }
    & tr *,
    & td * {
      padding: 10px;
      width: fit-content;
      white-space: nowrap;
    }
  }
  &.smoothPadding {
    & tr *,
    & td * {
      padding: 8px 20px 8px 20px;
    }
  }
  & .right {
    text-align: right;
  }
  & tr .gray {
    color: ${gray};
    background-color: ${ultraLightgray};
  }
  & tr .green {
    color: ${green};
  }
  & input {
    font-size: 16px;
    color: ${accent};
    border: none;
  }
  ${ThirdOrderButton} {
    padding: 0;
    width: 100%;
    text-align: left;
  }
  ${SecondOrderButton} {
    border: none;
    padding: 0;
    margin-right: 15px;
  }
`;

export default Table;
