import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { PATHS } from "../../constants/RoutesPaths";
import AddingNewExecutor from "../admin/AddingNewExecutor";
import Applications from "../admin/Applications";
import CompanyInfo from "../admin/CompanyInfo";
import CustomersList from "../admin/CustomersList";
import Editor from "../admin/Editor";
import EditorFormsList from "../admin/EditorFormsList";
import ExecutorInfo from "../admin/ExecutorInfo";
import ExecutorsList from "../admin/ExecutorsList";
import OrderDelivery from "../admin/OrderDelivery";
import OrdersRegistry from "../admin/OrdersRegistry";
import RegistryChangeAccount from "../admin/RegistryChangeAccount";
import AddNewCustomer from "../customer/AddNewCustomer";
import OrderInfoCustomer from "../customer/Order/OrderInfoCustomer";
import OrdersHistoryCustomer from "../customer/OrdersHistory";
import OrdersHistoryAdmin from "../admin/OrdersHistory";
import RequestCategories from "../customer/RequestCategories";
import RequestChoosingCustomer from "../customer/RequestChoosingCustomer";
import RequestDelivery from "../customer/RequestDelivery";
import UsefulMaterials from "../customer/UsefulMaterials";
import Orders from "../specialist/Orders";
import CustomerInfo from "./CustomerInfo";
import Login from "./Login";
import NotFound from "./NotFound";
import OrderChat from "./OrderChat";
import OrderInfoSpecialistAdmin from "../admin/Order/OrderInfoSpecialistAdmin";
import OrderObject from "./OrderObject";
import Questionnaire from "./Questionnaire";
import Register from "./Register";
import RegisterSuccess from "./RegisterSuccess";
import RequestForms from "../customer/RequestForms";
import Steps from "../../components/UI-kit/Steps";
import { getStatus } from "../../functions/common";
import Order from "./Order";
import OrdersHistory from "../customer/OrdersHistory";
import EditorDeliveries from "../admin/EditorDeliveries";

import styled from "styled-components";
import { device } from "../../constants/Breakpoints";
import ScrollTop from "../../components/ScrollTop";
import EditorSources from "../admin/EditorSources";
import EditorPromocodes from "../admin/EditorPromocodes";
import Agreement from "./Agreement";

const MainDiv = styled.main`
  width: 100%;
  @media ${device.tablet} {
    overflow-x: hidden;
    margin-top: 45px;
  }
`;

const UserConfig: any = [
  {
    path: PATHS.customer.ordersHistory,
    component: OrdersHistoryCustomer,
  },
  {
    path: PATHS.customer.usefulMaterials,
    component: UsefulMaterials,
  },
  {
    path: PATHS.customer.orderInfo,
    component: Order,
  },
  {
    path: PATHS.customer.usefulMaterials,
    component: UsefulMaterials,
  },
  {
    path: PATHS.customer.customerInfo,
    component: CustomerInfo,
  },
  {
    path: PATHS.customer.requestSteps,
    component: Steps,
  },
  {
    path: PATHS.customer.addNewCustomer,
    component: AddNewCustomer,
  },
  {
    path: PATHS.customer.requestCustomers,
    component: RequestChoosingCustomer,
  },
  {
    path: PATHS.customer.requestCategories,
    component: RequestCategories,
  },
  {
    path: PATHS.customer.requestDelivery,
    component: RequestDelivery,
  },
  {
    path: PATHS.customer.requestPassing,
    component: Questionnaire,
  },
  {
    path: PATHS.customer.requestForms,
    component: RequestForms,
  },
];

const ExpertConfig: any = [
  {
    path: PATHS.customer.ordersHistory,
    component: OrdersHistoryCustomer,
  },
  {
    path: PATHS.customer.orderInfo,
    component: Order,
  },
  {
    path: PATHS.customer.usefulMaterials,
    component: UsefulMaterials,
  },
  {
    path: PATHS.customer.customerInfo,
    component: CustomerInfo,
  },
];

const AdminConfig: any = [
  {
    path: PATHS.admin.ordersHistory,
    component: OrdersHistoryAdmin,
  },
  {
    path: PATHS.admin.orderInfo,
    component: Order,
  },
  {
    path: PATHS.admin.orderChat,
    component: OrderChat,
  },
  {
    path: PATHS.admin.orderObject,
    component: OrderObject,
  },
  {
    path: PATHS.admin.customerInfo,
    component: CustomerInfo,
  },
  {
    path: PATHS.admin.editorPreview,
    component: Questionnaire,
  },
  {
    path: PATHS.admin.orderDelivery,
    component: OrderDelivery,
  },
  {
    path: PATHS.admin.editorCreating,
    component: Editor,
  },
  {
    path: PATHS.admin.customersList,
    component: CustomersList,
  },
  {
    path: PATHS.admin.executorsList,
    component: ExecutorsList,
  },
  {
    path: PATHS.admin.registry,
    component: OrdersRegistry,
  },
  {
    path: PATHS.admin.companyInfo,
    component: CompanyInfo,
  },
  {
    path: PATHS.admin.addNewExecutor,
    component: AddingNewExecutor,
  },
  {
    path: PATHS.admin.executorInfo,
    component: ExecutorInfo,
  },
  {
    path: PATHS.admin.changeAccount,
    component: RegistryChangeAccount,
  },
  {
    path: PATHS.admin.editorCategories,
    component: EditorFormsList,
  },
  {
    path: PATHS.admin.editorDeliveries,
    component: EditorDeliveries,
  },
  {
    path: PATHS.admin.editorSources,
    component: EditorSources,
  },
  {
    path: PATHS.admin.editorPromocodes,
    component: EditorPromocodes,
  },
  {
    path: "/applications",
    component: Applications,
  },
];

const Main = () => {
  const [status, setStatus] = useState(getStatus());

  useEffect(() => {
    setStatus(getStatus());
  }, []);

  return (
    <MainDiv>
      <ScrollTop>
        <Switch>
          <Route exact path={PATHS.login} component={Login} />
          <Route exact path={PATHS.register} component={Register} />
          <Route exact path={PATHS.agreement} component={Agreement} />
          <Route
            exact
            path={PATHS.registerSuccess}
            component={RegisterSuccess}
          />
          {status === null
            ? null
            : status === "CUSTOMER"
            ? UserConfig.map(({ path, component }: any, index: any) => (
                <Route
                  exact
                  key={index}
                  path={path}
                  component={component}
                ></Route>
              ))
            : status === "ADMIN"
            ? AdminConfig.map(({ path, component }: any, index: any) => (
                <Route
                  exact
                  key={index}
                  path={path}
                  component={component}
                ></Route>
              ))
            : ExpertConfig.map(({ path, component }: any, index: any) => (
                <Route
                  exact
                  key={index}
                  path={path}
                  component={component}
                ></Route>
              ))}
          {/* <RoutesByUserType status={status} /> */}
          <Route path="*" component={NotFound} />
        </Switch>
      </ScrollTop>
    </MainDiv>
  );
};

export default Main;
