import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import { black, gray } from "../../../constants/Colours";
import Subtitle from "../../UI-kit/Subtitle";
import Subtitle2 from "../../UI-kit/Subtitle2";
import Text from "../../UI-kit/Text";

export const PhoneBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${Subtitle},${Subtitle2}, ${Text} {
    margin: 0;
    line-height: 140%;
  }
  ${Text} {
    font-size: 13px;
    color: ${gray};
  }
  a {
    display: flex;
    align-items: center;
    color: ${gray} !important;
    margin: 0;
    text-decoration: none;
    &:hover {
      color: #6f6f6f !important;
    }
  }
  &.header-block + &.header-block {
    margin-left: 40px;
  }
  @media ${device.tablet} {
    & h2 {
      font-size: 14px;
    }
    & .header-logo {
      display: none !important;
    }
  }
`;
