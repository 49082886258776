import React, { useState, useEffect } from "react";

import FloatingInput from "../../../components/UI-kit/FloatingInput";
import Subtitle2 from "../../../components/UI-kit/Subtitle2";
import { Col, Row } from "styled-bootstrap-grid";

import { T } from "../../../lang/ru";
import {
  isValidEmailCheck,
  isValidPhoneCheck,
  isValidDateCheck,
} from "../../../functions/validators";
import { checkFullnessFields } from "../../../functions/common";

const IndividualForm = ({
  setInputsValue,
  inputsValue = {},
  setIsDisabled,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidDate, setIsValidDate] = useState(true);

  const {
    surname,
    adress,
    name,
    phone,
    middlename,
    email,
    serial,
    number,
    who_give,
    date_get,
    inn,
  } = inputsValue;

  const requiredFields = [
    surname,
    name,
    middlename,
    inn,
    email,
    phone,
    serial,
    number,
    who_give,
    date_get,
  ];

  useEffect(() => {
    // Есть ли пустые поля/ false – нет пустых, true есть пустые
    const isFullnessFields = checkFullnessFields(requiredFields);

    setIsDisabled(isFullnessFields);
  }, requiredFields);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputsValue({ ...inputsValue, [name]: value });
  };

  const checkEmailValid = (e) => {
    setIsValidEmail(isValidEmailCheck(e.target.value));
  };

  const [isValid, setIsValid] = useState({
    serial: false,
    number: false,
    inn: false,
  });

  const checkValid = (e) => {
    const { value, name } = e.target;
    setIsValid({ ...isValid, [name]: validators[name](value) });
  };
  const validators = {
    serial: (value) => !value.match("^([0-9]{4})$") || !value,
    inn: (value) => !value.match("^([0-9]{10,12})$") || !value,
    number: (value) => !value.match("^([0-9]{6})$") || !value,
  };

  const checkPhoneValid = (e) => {
    setIsValidPhone(isValidPhoneCheck(e.target.value));
  };

  const checkDateValid = (e) => {
    setIsValidDate(isValidDateCheck(e.target.value));
  };

  return (
    <>
      <Subtitle2 style={{ marginTop: 30 }}>
        {T.enterCustomerInformation}
      </Subtitle2>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="surname"
            placeholder={T.surname}
            required={true}
            onChange={onChangeInput}
            value={surname}
          />
        </Col>
        <Col col="auto">
          <FloatingInput
            name="adress"
            placeholder={T.adress}
            onChange={onChangeInput}
            value={adress}
          />
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="name"
            placeholder={T.name}
            required={true}
            onChange={onChangeInput}
            value={name}
          />
        </Col>
        <Col col="auto">
          <FloatingInput
            name="phone"
            placeholder={T.phone}
            onChange={onChangeInput}
            required
            value={phone}
            wrong={!isValidPhone}
            onBlur={checkPhoneValid}
            wrongMessage={T.inCorrectPhone}
          />
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="middlename"
            placeholder={T.middleName}
            required={true}
            onChange={onChangeInput}
            value={middlename}
          />
        </Col>

        <Col col="auto">
          <FloatingInput
            name="email"
            placeholder={T.email}
            required
            onChange={onChangeInput}
            value={email}
            wrong={!isValidEmail}
            wrongMessage={T.inCorrectEmail}
            onBlur={checkEmailValid}
          />
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="inn"
            onBlur={checkValid}
            wrong={isValid.inn}
            wrongMessage={"Формат (Например, 998877777766)"}
            placeholder={T.INN}
            required={true}
            onChange={onChangeInput}
            value={inn}
          />
        </Col>
      </Row>
      <Subtitle2 style={{ marginTop: 40 }}>{T.enterPasportData}</Subtitle2>
      <Row>
        <Col>
          <FloatingInput
            name="serial"
            onBlur={checkValid}
            wrong={isValid.serial}
            wrongMessage={"Формат серии паспорта (Например, 9988)"}
            placeholder={T.series}
            required={true}
            onChange={onChangeInput}
            value={serial}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingInput
            name="number"
            placeholder={T.number}
            onBlur={checkValid}
            wrong={isValid.number}
            wrongMessage={"Номер паспорта (Например, 999888)"}
            required={true}
            onChange={onChangeInput}
            value={number}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingInput
            name="who_give"
            placeholder={T.issuedBy}
            required={true}
            onChange={onChangeInput}
            value={who_give}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingInput
            name="date_get"
            placeholder={T.dateOfIssueInput}
            required={true}
            onChange={onChangeInput}
            value={date_get}
            wrong={!isValidDate}
            wrongMessage={T.inCorrectDate}
            onBlur={checkDateValid}
          />
        </Col>
      </Row>
    </>
  );
};

export default IndividualForm;
