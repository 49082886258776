import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { Application, Element, Form } from "../../api/methods";

import PullElements from "../../components/admin/Editor/PullElements";

import Button from "../../components/UI-kit/Buttons/Button";
import Subtitle from "../../components/UI-kit/Subtitle";
import Subtitle2 from "../../components/UI-kit/Subtitle2";

import { PATHS } from "../../constants/RoutesPaths";

import { setActiveStep } from "../../store/activeStep/actions";

import {
  setElements,
  modificateIdsOfSuperComplexLists,
} from "../../store/elements/actions";

import { previewModeON } from "../../store/previewMode/actions";

import { setAllAnswers } from "../../store/userAnswers/actions";
import { getUserAnswers } from "../../store/userAnswers/selectors";

import { debounce } from "../../functions/common";
import questionnaireFunc from "../../functions/questionnaireFunc";

const Questionnaire = (props) => {
  const { id, payer_id, application_id } = props.match.params;

  const history = useHistory();

  const dispatch = useDispatch();

  const elements = useSelector((state) => state.elements);
  const userAnswers = useSelector(getUserAnswers);

  const [systemNames, setSystemNames] = useState([]);
  let [systemNamesOptions, setSystemNamesOptions] = useState([]);

  useEffect(() => {
    loadForm(id);
    enablePreviewMode();
    dispatch(setActiveStep(2));
    loadSystemNames();
  }, [id]);

  const loadSystemNames = () => {
    Element.getSystemNames({}, loadSystemNamesSuccess);
  };

  const loadSystemNamesSuccess = ({ data }) => {
    const options = data.map(({ id, title }) => {
      return { id, name: title };
    });
    setSystemNamesOptions(options);
    setSystemNames(data);
  };

  const enablePreviewMode = () => {
    dispatch(previewModeON());
  };

  const loadForm = (id) => {
    Form.getById({ id }, loadFormSuccess);
  };

  const loadFormSuccess = ({ data }) => {
    setElementsToStore(data);
    modificateComplexLists();
    loadUserAnswers();
  };

  const modificateComplexLists = () => {
    dispatch(modificateIdsOfSuperComplexLists());
  };
  const setElementsToStore = (elements) => {
    dispatch(setElements(elements));
  };

  const loadUserAnswers = () => {
    Application.getById({ id: application_id }, loadUserAnswersSuccess);
  };

  const loadUserAnswersSuccess = ({ data }) => {
    const answer = JSON.parse(data.answer);
    dispatch(setAllAnswers(answer));
  };

  const goToDelivery = (id) =>
    history.push(
      PATHS.customer.requestDelivery
        .replace(":id", id)
        .replace(":payer_id", payer_id)
        .replace(":application_id", application_id)
    );

  const handleNextButton = () => {
    const isRequiredEmtpy = questionnaireFunc.isRequiredFieldEmpty(
      elements,
      userAnswers
    );
    questionnaireFunc.saveElementsOrder(userAnswers);

    if (isRequiredEmtpy) {
      alert("Заполните все обязательные поля");
      return;
    }

    const newAnswers = { ...userAnswers };

    elements.map((element) => {
      const { id, system_name_id } = element;
      const systemNameIndex = systemNames.findIndex(
        ({ id }) => id == system_name_id
      );

      console.log(newAnswers, systemNames, system_name_id, systemNameIndex);
      if (systemNameIndex != -1) {
        const userAnswer = newAnswers[id];
        if (userAnswer) {
          newAnswers[id].system_name_id = systemNames[systemNameIndex].id;
        }
      }
    });
    // questionnaireFunc.markElementBySystemName(newAnswers, systemNames);
    localStorage.setItem("user_answers", JSON.stringify(newAnswers));
    goToDelivery(id);
  };

  return (
    <>
      <Subtitle2 style={{ marginTop: 30, marginBottom: 0 }}>
        Поля отмечененные символом звездочка (*) обязательны для заполнения
      </Subtitle2>
      <PullElements
        systemNames={systemNamesOptions}
        elements={elements}
      ></PullElements>
      <Button onClick={handleNextButton}>Далее</Button>
    </>
  );
};

export default Questionnaire;
