const types = {
  SET_EDITOR_ELEMENTS: "SET_EDITOR_ELEMENTS",
  ADD_ELEMENT_TO_STRUCT: "ADD_ELEMENT_TO_STRUCT",
  DELETE_ELEMENT_FROM_STRUCT: "DELETE_ELEMENT_FROM_STRUCT",
  TYPE_NAME: "TYPE_NAME",
  TYPE_TIP: "TYPE_TIP",
  SET_STRUCT_ELEMENT: "SET_STRUCT_ELEMENT",
  CHANGE_HREF: "CHANGE_HREF",
  SET_REPEAT_CHILDREN_COUNT_TO_ELEMENT: "SET_REPEAT_CHILDREN_COUNT_TO_ELEMENT",
  MODIFICATE_IDS_OF_SUPER_COMPLEXT_LISTS:
    "MODIFICATE_IDS_OF_SUPER_COMPLEXT_LISTS",
};

export default types;
