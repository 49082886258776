import React, { ReactNode, useEffect, useState } from "react";
import { T } from "../../../lang/ru";
import Subtitle from "../../UI-kit/Subtitle";
import Text from "../../UI-kit/Text";
import Header from "../Header/Header";
// import Footer from "../Footer/Footer";
import Logo from "../Header/Logo";
import { PhoneBlock } from "../Header/PhoneBlock";
import { Container, Row } from "styled-bootstrap-grid";
import Loader from "../../UI-kit/Loader";
import Footer from "../Footer/Footer";
import Links from "../Links/Links";
import { Link, useHistory } from "react-router-dom";
import { PATHS } from "../../../constants/RoutesPaths";
import { getStatus } from "../../../functions/common";
import { gray } from "../../../constants/Colours";
import { useDispatch, useSelector } from "react-redux";
import Subtitle2 from "../../UI-kit/Subtitle2";
import MobileButton from "../../UI-kit/Buttons/MobileButton";
import { setIsHiddenMobileButton } from "../../../store/isHiddenMobileButton/action";
import { getIsShowButton } from "../../../store/isHiddenMobileButton/selectors";

type Props = {
  children: ReactNode;
};

const LayoutForRoutes: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const links = [
    { name: T.companySettings, path: PATHS.admin.companyInfo },
    { name: T.editForms, path: PATHS.admin.editorCategories },
    { name: T.editDeliveriesMethods, path: PATHS.admin.editorDeliveries },
    { name: T.editSources, path: PATHS.admin.editorSources },
    { name: T.promocodes, path: PATHS.admin.editorPromocodes },
  ];
  const [status, setStatus] =
    useState<"" | "ADMIN" | "CUSTOMER" | "SPECIALIST" | null>(null);

  const isHiddenButton = useSelector(getIsShowButton);
  const dispatch = useDispatch();

  useEffect(() => {
    setStatus(getStatus());
  }, []);

  const pushToPath: (path: string) => void = (path) => {
    history.push(path);
  };

  const isAdmin = status === "ADMIN";
  const logout = () => {
    localStorage.clear();
    document.location.replace(PATHS.login);
  };

  const goToChoosingCustomer = () => {
    dispatch(setIsHiddenMobileButton(false));
    history.push(PATHS.customer.requestCustomers);
  };

  const { pathname } = document.location;
  const isHiddenLogoutButton =
    pathname.includes(PATHS.login) ||
    pathname.includes(PATHS.register) ||
    pathname.includes(PATHS.agreement) ||
    pathname.includes(PATHS.registerSuccess);

  return (
    <>
      <Header>
        <Container style={{ width: "-webkit-fill-available" }}>
          <Row
            style={{ margin: 0 }}
            justifyContent={
              isAdmin || isHiddenLogoutButton ? "between" : undefined
            }
            alignItems="center"
          >
            {isAdmin ? (
              <PhoneBlock>
                <Link to={PATHS.defualt}>
                  <Subtitle2>Административная панель</Subtitle2>
                </Link>
              </PhoneBlock>
            ) : (
              <>
                <PhoneBlock className="header-block">
                  <Link to={PATHS.defualt}>
                    <Subtitle>
                      {process.env.REACT_APP_TITLE}
                      <br className="header-logo"></br>
                    </Subtitle>
                  </Link>
                </PhoneBlock>
                <PhoneBlock className="header-block">
                  <Subtitle style={{ color: gray }}>
                    <a href="tel:88004443037">{T.phoneNumber}</a>
                  </Subtitle>
                </PhoneBlock>
              </>
            )}
            <Row
              style={{
                margin: 0,
                marginLeft: "auto",
                display: isAdmin || !isHiddenLogoutButton ? "" : "none",
              }}
            >
              {isAdmin ? <Links links={links} onClick={pushToPath} /> : null}
              {isHiddenLogoutButton ? null : (
                <Link onClick={logout} to={PATHS.login}>
                  <Text className="header-exit">Выход</Text>
                </Link>
              )}
            </Row>
          </Row>
        </Container>
      </Header>
      <Container style={{ width: "-webkit-fill-available", minHeight: 800 }}>
        {children}
      </Container>
      {/* <Loader /> */}
      {isHiddenButton ? (
        <MobileButton onClick={goToChoosingCustomer}>
          {T.orderCalculation}
        </MobileButton>
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
};
export default LayoutForRoutes;
