import React from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import Applications from "./UsefulMaterialsComponents/Applications";
import Materials from "./UsefulMaterialsComponents/Materials";

const UsefulMaterials: React.FC = () => {
  return (
    <>
      <Col>
        <Row>
          <Applications />
        </Row>
        <Row>
          <Materials />
        </Row>
      </Col>
    </>
  );
};

export default UsefulMaterials;
