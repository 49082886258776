import styled from "styled-components";
import {
  accent,
  gray,
  lightgray,
  red,
  white,
} from "../../../constants/Colours";

type ButtonProps = {
  disabled?: boolean;
  margin?: string;
  isDanger?: boolean;
};

const Button = styled.button`
  padding: 15px 22px;
  font-size: 16px;
  border: 1px solid ${accent};
  color: ${accent};
  background-color: ${white};
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  margin: ${(props: ButtonProps) => (props.margin ? props.margin : "10px 0")};
  background-position: center;
  transition: all 0.5s;

  ${(props: ButtonProps) => {
    const { isDanger = false } = props;
    if (isDanger)
      return `
      border-color: ${red} !important;
      color: ${red} !important;
      &:hover {
        background: ${red} radial-gradient(circle, transparent 1%, ${red} 1%)
          center / 15000% !important;
      color: ${white} !important;

      }
    `;
  }};

  ${(props: ButtonProps) =>
    props.disabled === true
      ? `{
          border-color: ${gray};
          background-color: ${lightgray};
          color: ${gray};
          &:hover{
            border-color: ${gray} !important;
            background-color: ${lightgray} !important;
            color: ${gray} !important;
          }
 }`
      : `
      &:hover {
        background: ${accent} radial-gradient(circle, transparent 1%, ${accent} 1%)
          center / 15000%;
        color: ${white};
      }
      :active,
      :focus {
        background-color: #6eb9f7;
        color: ${white};
        border-color: transparent;
        background-size: 100%;
        transition: background 0s;
      }
    
      &.active {
        cursor: default;
        color: ${lightgray};
        border-color: ${lightgray};
        z-index: -1;
        &:hover,
        &:focus,
        &:active {
          color: ${lightgray};
          border-color: ${lightgray};
          background: transparent;
        }
      }
      `};
`;

export default Button;
