import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Container, Row } from "styled-bootstrap-grid";
import Button from "../../components/UI-kit/Buttons/Button";
import ButtonGroup from "../../components/UI-kit/Buttons/ButtonGroup";
import ThirdOrderButton from "../../components/UI-kit/Buttons/ThirdOrderButton";
import Table from "../../components/UI-kit/Table/Table";
import Title from "../../components/UI-kit/Title";
import UsefulMaterials from "./UsefulMaterials";
import TableCustomerButton from "../../components/UI-kit/Buttons/TableCustomerButton";

import { PATHS } from "../../constants/RoutesPaths";
import { Application, Element, User } from "../../api/methods";
import {
  getApplicationStatus,
  getStatus,
  getStatusString,
} from "../../functions/common";
import { T } from "../../lang/ru";
import { setIsHiddenMobileButton } from "../../store/isHiddenMobileButton/action";
import MainCustomerButton from "../../components/UI-kit/Buttons/MainCustomerButton";
import CustomerMainTable from "../../components/UI-kit/Table/CustomerMainTable";
import { useDispatch } from "react-redux";
import Subtitle2 from "../../components/UI-kit/Subtitle2";
import OrderTableSelect from "../admin/OrderRegistry/OrderTableSelect";
import { Preloader } from "../../components/UI-kit/Preloader/Preloader";

import { EmptyApplicationsBlock } from "../../components/UI-kit/EmptyApplicationsBlock";
import { getApplicationId } from "../../functions/getters";

type Data = {
  id: number;
  payer_id: string;
  status: string;
  surname: string;
  name: string;
  middlename: string;
  executor_id: string;
  system_name_ans: string;
  system_name_answers: any;
  name_org: any;
  contract_number: number;
  type: any;
};

type Props = {
  data: Data[];
  goToOrder: any;
  goToCustomer: any;
  changeExecutor: any;
  experts: any;
  systemNames: any;
  isExecutor: boolean;
};

const OrdersTable: React.FC<Props> = ({
  data,
  goToOrder,
  goToCustomer,
  changeExecutor,
  experts,
  isExecutor,
  systemNames,
}) => {
  const isExpert = localStorage.getItem("status") == "2";
  return (
    <CustomerMainTable>
      <thead>
        <tr>
          <th>Номер заказа</th>
          {systemNames.map(({ title }: { title: any }) => (
            <th>{title}</th>
          ))}
          {isExpert ? null : <th>Заказчик</th>}

          <th>Статус</th>
          {isExecutor ? <th>Расчетчик</th> : null}
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({
            id,
            contract_number,
            executor_id,
            payer_id,
            surname,
            name,
            middlename,
            status,
            type,
            system_name_ans,
            name_org,
            system_name_answers,
          }) => (
            <tr key={id}>
              <td aria-label={T.orderNumber}>
                <TableCustomerButton
                  className="table-id"
                  onClick={() => goToOrder(id)}
                >
                  <span>{T.application}</span>
                  {getApplicationId(contract_number)}
                </TableCustomerButton>
              </td>
              {systemNames.map(({ title }: { title: any }) => (
                <td>
                  <span style={{ border: "none" }}>
                    {!system_name_answers ? "" : system_name_answers[title]}
                  </span>
                </td>
              ))}
              {isExpert ? null : (
                <td aria-label={T.fullName}>
                  <TableCustomerButton
                    className="table-name"
                    onClick={() => {
                      goToCustomer(payer_id);
                    }}
                  >
                    {type == 2 ? name_org : `${surname} ${name} ${middlename}`}
                  </TableCustomerButton>
                </td>
              )}
              <td className="gray" aria-label={T.status}>
                {getApplicationStatus(Number(status))[0]?.label}
              </td>
              {isExecutor ? (
                <td className="gray" aria-label={T.status}>
                  <OrderTableSelect
                    onChange={(e: any) => changeExecutor(e, id)}
                    options={experts}
                    selectedValue={executor_id}
                    placeholder={T.choosePaymentMen}
                  ></OrderTableSelect>
                </td>
              ) : null}
            </tr>
          )
        )}
      </tbody>
    </CustomerMainTable>
  );
};

const OrdersHistory: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["История заказов", "Полезные материалы"];

  const [applications, setApplications] = useState<[]>([]);
  const [experts, setExperts] = useState<any>([]);

  const [status, setStatus] = useState<any>(null);

  const isExecutor: any = localStorage.getItem("is_executor") == "1";

  const [systemNames, setSystemNames] = useState([]);

  const [isLoadingApplications, setIsLoading] = useState(false);
  const [isApplicationsWasLoaded, setIsApplicationsWasLoaded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Application.getAll({}, successGetAllApllications);
    dispatch(setIsHiddenMobileButton(true));
    setStatus(getStatus());
    loadExperts();
    loadSystemNames();
  }, []);

  const loadSystemNames = () => {
    Element.getSystemNames({}, loadSystemNamesSuccess);
  };

  const loadSystemNamesSuccess = ({ data }: { data: any }) => {
    setSystemNames(data);
  };

  const loadExperts = () => User.getExperts({}, loadExpertsSuccess);

  const loadExpertsSuccess = ({ data }: { data: Data[] }) => {
    const options: any = data.map(({ name, surname, middlename, id }) => {
      return { value: id, label: `${surname} ${name} ${middlename}` };
    });
    setExperts(options);
  };

  const successGetAllApllications: (res: any) => void = ({ data }) => {
    const finishedApplications = data.filter(
      (application: any) => application?.status != 0
    );
    setIsLoading(false);
    setIsApplicationsWasLoaded(true);
    setApplications(finishedApplications);
  };

  const goToRequestCustomers: () => void = () => {
    history.push(PATHS.customer.requestCustomers);
  };

  const goToOrderInfoCustomer: (id: any) => void = (id) => {
    history.push(PATHS.customer.orderInfo.replace(":id", id));
  };

  const goToInfoCustomer: (id: any) => void = (id) => {
    history.push("/customer-info/" + id);
  };

  const isSpecialist = status == "SPECIALIST";

  const changeExecutor = (e: any, aplicationId: any) => {
    const { value } = e.target;
    Application.update({ id: aplicationId, executor_id: value });
    // Application.setExpert({ app_id: aplicationId, expert_id: value });
  };

  const isEmptyApplications = applications.length == 0;
  return (
    <>
      <Title>{isSpecialist ? "История заказов" : "Личный кабинет"}</Title>

      <Container>
        <Row>
          {/* <ButtonGroup style={{ marginRight: 10 }}>
            {tabs.map((tab, index) => (
              <Button
                onClick={() => setActiveTab(index)}
                key={index}
                className={index === activeTab ? "active" : ""}
              >
                {tab}
              </Button>
            ))}
          </ButtonGroup> */}
          {isSpecialist ? null : (
            <MainCustomerButton
              margin="10px 0 35px 0"
              onClick={goToRequestCustomers}
            >
              {T.orderCalculation}
            </MainCustomerButton>
          )}
        </Row>
      </Container>
      {
        isLoadingApplications && !isApplicationsWasLoaded ? (
          <Preloader />
        ) : isApplicationsWasLoaded && isEmptyApplications ? (
          <EmptyApplicationsBlock />
        ) : !isEmptyApplications ? (
          <OrdersTable
            data={applications}
            goToOrder={goToOrderInfoCustomer}
            goToCustomer={goToInfoCustomer}
            changeExecutor={changeExecutor}
            isExecutor={isExecutor}
            experts={experts}
            systemNames={systemNames}
          ></OrdersTable>
        ) : null
        // <UsefulMaterials></UsefulMaterials>
      }
    </>
  );
};

export default OrdersHistory;
