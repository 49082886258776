
export const INPUT_CONFIGS = {
    register: [{ required: true, key: "surname", placeholder: "Фамилия" },
    { required: true, key: "name", placeholder: "Имя" },
    { required: true, key: "middlename", placeholder: "Отчество" },
    { required: true, key: "phone", placeholder: "Телефон" },
    { required: false, key: "additionalNumber", placeholder: "Добавочный номер" },
    { required: true, key: "email", placeholder: "Email" },
    { required: true, key: "password", placeholder: "Пароль" },
    { required: true, key: "passwordRepeate", placeholder: "Повторите пароль" },
    // { required: true, key: "objectType", placeholder: "Тип объекта" },
    ],
    login: [
        { required: true, key: "email", placeholder: "Email" },
        { required: true, key: "password", placeholder: "Пароль" },
    ]
}
