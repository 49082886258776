import React, { useState } from "react";
import { Container, Row } from "styled-bootstrap-grid";
import Button from "../../../components/UI-kit/Buttons/Button";
import ButtonGroup from "../../../components/UI-kit/Buttons/ButtonGroup";
import Articles from "./Articles";
import NormativeBase from "./NormativeBase";
import Videos from "./Videos";

const Materials = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Видео", "Нормативная база", "Статьи"];
  return (
    <>
      <Container>
        <Row>
          <ButtonGroup>
            {tabs.map((tab, index) => (
              <Button
                onClick={() => setActiveTab(index)}
                key={index}
                className={index === activeTab ? "active" : ""}
              >
                {tab}
              </Button>
            ))}
          </ButtonGroup>
        </Row>
      </Container>
      {activeTab === 0 ? (
        <Videos />
      ) : activeTab === 1 ? (
        <NormativeBase />
      ) : (
        <Articles />
      )}
    </>
  );
};

export default Materials;
