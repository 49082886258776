import T from "./types";

export const setUserAnswer = (key, value) => ({
  type: T.SET_USER_ANSWER,
  key,
  value,
});

export const setAllAnswers = (answers) => ({
  type: T.SET_ALL_ANSWERS,
  payload: answers,
});
