import { combineReducers } from "redux";
import { loaderReducer } from "./loader/loader";
import activeTab from "./activeTab/activeTab";
import activeStep from "./activeStep/activeStep";
import elements from "./elements/elements";
import isPreviewMode from "./previewMode/previewMode";
import userAnswers from "./userAnswers/userAnswers";
import favoriteElements from "./favoriteElements/favoriteElements";
import isHiddenMobileButton from "./isHiddenMobileButton/isHiddenMobileButton";
import popups from "./popups/popups";

export default combineReducers({
  activeTab,
  favoriteElements,
  loaderReducer,
  elements,
  isPreviewMode,
  userAnswers,
  activeStep,
  isHiddenMobileButton,
  popups,
});
