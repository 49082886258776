import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Title from "../../components/UI-kit/Title";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import PersonTable from "./CustomerInfo/PersonTable";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";
import { Payers } from "../../api/methods";
import Text from "../../components/UI-kit/Text";
import { dateTranslate } from "../../functions/common";
import MainCustomerButton from "../../components/UI-kit/Buttons/MainCustomerButton";
import { useDispatch } from "react-redux";
import { setIsHiddenMobileButton } from "../../store/isHiddenMobileButton/action";
import { LinkButton } from "../../components/UI-kit/Buttons/LinkButton";
import { Preloader } from "../../components/UI-kit/Preloader/Preloader";

type Props = {
  match: {
    params: {
      id: string;
      application_id?: string;
    };
  };
};

const CustomerInfo: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, application_id } = props.match.params;
  const [status, setStatus] =
    useState<"ADMIN" | "CUSTOMER" | "SPECIALIST" | null>(null);
  const [infoCustomer, setInfoCustomer] = useState<any>(null);

  useEffect(() => {
    getPayerInfo();
    setStatus("CUSTOMER");
    dispatch(setIsHiddenMobileButton(false));
  }, []);

  const getPayerInfo = () => {
    Payers.getById({ id: String(id) }, getPayerInfoSuccess, getPayerInfoFail);
  };

  const getPayerInfoFail = () => {
    history.push("/");
  };

  const getPayerInfoSuccess: (res: any) => void = ({ data }) => {
    const info = data[0];

    delete info.user_id;
    const { created_at, updated_at } = info;
    info.created_at = dateTranslate(created_at);
    info.updated_at = dateTranslate(updated_at);
    setInfoCustomer(info);
  };

  const goToEditCustomer = () => {
    history.push(PATHS.customer.addNewCustomer + `/${id}`);
  };

  const goToAddNewCustomer = () => {
    history.push(PATHS.customer.addNewCustomer);
  };

  const openModalWindow = () => {};

  const pathBack = application_id
    ? PATHS.admin.orderInfo.replace(":id", application_id)
    : PATHS.admin.ordersHistory;
  const Buttons = (
    <>
      {/* <Button onClick={goToEditCustomer}>{T.edit}</Button> */}
      <LinkButton to={pathBack}>{T.back}</LinkButton>
      {/* {status === "CUSTOMER" ? (
        <SecondOrderButton onClick={goToAddNewCustomer} marginLeft>
          {T.addNewCustomerButton}
        </SecondOrderButton>
      ) : (
        <SecondOrderButton
          className="delete-button"
          onClick={openModalWindow}
          marginLeft
        >
          {T.delete}
        </SecondOrderButton>
      )} */}
    </>
  );

  const names = {
    Email: "Email",
    adress: "Адрес",
    bank: "Банк",
    bik_bank: "БИК Банка",
    created_at: "Дата регистрации",
    date_get: "Дата получения",
    inn: "ИНН",
    kor: "Кор. счёт",
    kpp: "КПП",
    legal_adress: "Юридический адрес",
    base: "Дейсвтующий на основании",
    middlename: "Отчество",
    name: "Имя",
    name_ip: "Наименование ИП",
    name_org: "Наименование организации",
    number: "Cчёт",
    ogrn: "ОГРН",
    phone: "Телефон",
    position: "Должность",
    ras: "Расчетный счёт",
    serial: "Серия",
    surname: "Фамилия",
    // type: "Является",
    updated_at: "Дата обновления",
    id: "Номер заказчика",
    who_give: "Кто выдал",
  };

  if (infoCustomer === null) return <Preloader />;

  return (
    <>
      <Title>{T.infoAboutCustomer}</Title>
      <PersonTable data={names} infoCustomer={infoCustomer} />
      {Buttons}
    </>
  );
};

export default CustomerInfo;
