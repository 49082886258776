import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Source } from "../../../api/methods";

import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../../components/UI-kit/FloatingInput";

import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const AddPopup = ({ getAllSource }) => {
  const dispatch = useDispatch();
  const { isShowAddSource } = useSelector(getIsShowPopups);
  const [source, setSource] = useState({
    name: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    source.name === "" ? setIsDisabled(true) : setIsDisabled(false);
  }, [source.name]);

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    setSource({ ...source, [name]: value });
  };

  const onClickAdd = () => {
    Source.add({ name: source.name }, addSourceSuccess);
  };

  const addSourceSuccess = () => {
    getAllSource();
    closePopup();
  };

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowAddSource", false));
  };

  const buttons = (
    <ButtonsBlock>
      <Button onClick={onClickAdd} disabled={isDisabled}>
        {T.addNew}
      </Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert title={T.adding} isShow={isShowAddSource} buttons={buttons}>
      <FloatingInput
        name="name"
        placeholder={T.title}
        onChange={onChangeInput}
        value={source.name}
      />
    </Alert>
  );
};

export default AddPopup;
