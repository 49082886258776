import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delivery } from "../../../api/methods";
import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../../components/UI-kit/FloatingInput";
import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const EditPopup = ({ getAllDelivery, selectDelivery }) => {
  const dispatch = useDispatch();
  const { isShowEditDelivery } = useSelector(getIsShowPopups);
  const [delivery, setDelivery] = useState({
    name: "",
    price: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setDelivery({ name: selectDelivery?.name, price: selectDelivery?.price });
  }, [selectDelivery]);

  useEffect(() => {
    delivery.name === "" || delivery.price === ""
      ? setIsDisabled(true)
      : setIsDisabled(false);
  }, [delivery.name, delivery.price]);

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    setDelivery({ ...delivery, [name]: value });
  };

  const onClickSave = () => {
    Delivery.update(
      { name: delivery.name, price: delivery.price, id: selectDelivery.id },
      editDeliverySuccess
    );
  };

  const editDeliverySuccess = () => {
    getAllDelivery();
    closePopup();
  };

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowEditDelivery", false));
  };

  const buttons = (
    <ButtonsBlock>
      <Button onClick={onClickSave} disabled={isDisabled}>
        {T.save}
      </Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert title={T.editing} isShow={isShowEditDelivery} buttons={buttons}>
      <FloatingInput
        name="name"
        placeholder={T.name}
        onChange={onChangeInput}
        value={delivery.name}
      />
      <FloatingInput
        name="price"
        placeholder={T.price}
        onChange={onChangeInput}
        value={delivery.price}
      />
    </Alert>
  );
};

export default EditPopup;
