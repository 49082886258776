import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "styled-bootstrap-grid";
import { Element } from "../../../../../api/methods";
import { key_divider } from "../../../../../constants/elements";
import questionnaireFunc from "../../../../../functions/questionnaireFunc";
import { getPreviewMode } from "../../../../../store/previewMode/selectors";
import { setUserAnswer } from "../../../../../store/userAnswers/actions";
import Button from "../../../../UI-kit/Buttons/Button";
import FloatingInput from "../../../../UI-kit/FloatingInput";
import InputButtonGroup from "../../../../UI-kit/InputButtonGroup";
import { Radio, RadioGroup } from "../../../../UI-kit/RadioGroup";
import { SmallInput } from "../../../../UI-kit/SmallInput";
import { CRUDStructElement } from "../../CRUDStructElement";

const RadioPart = ({ struct, element, addStruct, deleteStruct }) => {
  const { id, name } = element;
  const key = id;

  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);

  const [selectedValue, setSelectedValue] = useState();
  const [state, setState] = useState({ value: "" });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  useEffect(() => {
    setIsAddButtonDisabled(state.value == "");
  }, [state.value]);

  const onClickRadioButton = (selectedValue) => setSelectedValue(selectedValue);

  const setUserAnswerRadio = (selectedValue) => {
    const userAnswer = questionnaireFunc.getAnswerInfo(
      element,
      selectedValue,
      null
    );

    dispatch(setUserAnswer(key, userAnswer));
    onClickRadioButton(selectedValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleAddStruct = () => {
    addStruct(element.id, state.value);
    setState({ ...state, value: "" });
  };

  const handleClickRadio = isPreviewMode
    ? setUserAnswerRadio
    : onClickRadioButton;

  return (
    <>
      {isPreviewMode ? null : (
        <Col>
          <Row>
            <InputButtonGroup>
              <FloatingInput
                name="value"
                onChange={handleChange}
                value={state.value}
                label={"Название элемента"}
              ></FloatingInput>
              <Button
                disabled={isAddButtonDisabled}
                onClick={handleAddStruct}
                margin={"10px 0"}
              >
                Добавить ответ
              </Button>
            </InputButtonGroup>
          </Row>
        </Col>
      )}

      {isPreviewMode ? (
        <RadioGroup
          onClickRadioButton={handleClickRadio}
          selectedValue={selectedValue}
        >
          {element.struct.map(({ name, id }) => (
            <Radio key={id} value={id} labelText={name} />
          ))}
        </RadioGroup>
      ) : (
        <CRUDStructElement
          elementId={element.id}
          struct={element.struct}
          deleteStruct={deleteStruct}
        />
      )}
    </>
  );
};

export default RadioPart;
