import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../components/UI-kit/Buttons/Button";
import ButtonGroup from "../../components/UI-kit/Buttons/ButtonGroup";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import Title from "../../components/UI-kit/Title";
import OrderInfoCustomer from "../customer/Order/OrderInfoCustomer";
import OrderInfoSpecialistAdmin from "../admin/Order/OrderInfoSpecialistAdmin";
import OrderInfoSpecialist from "../specialist/Order/OrderInfoSpecialist";
import OrderChat from "./OrderChat";
import OrderObject from "./OrderObject";
import { Row } from "styled-bootstrap-grid";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";
import { getStatus } from "../../functions/common";
import { Application, Company } from "../../api/methods";
import ThirdOrderButton from "../../components/UI-kit/Buttons/ThirdOrderButton";
import { LinkButton } from "../../components/UI-kit/Buttons/LinkButton";
import MainCustomerButton from "../../components/UI-kit/Buttons/MainCustomerButton";
import { useDispatch } from "react-redux";
import { setIsHiddenMobileButton } from "../../store/isHiddenMobileButton/action";
import ButtonFixedGroup from "../../components/UI-kit/Buttons/ButtonsFixedGroup";
import BackButton from "./Order/BackButton";
import { setIsShowPopup } from "../../store/popups/actions";
import DeletePopup from "../customer/Order/DeletePopup";

import hooks from "../../functions/hooks";

const Order = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = props.match.params.id;
  const tabs = [T.infoAboutOrder, T.chat, T.infoAboutObject];
  const [activeTab, setActiveTab] = useState(0);
  const [application, setApplication] = useState({});

  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const [isLoadingCheck, setIsLoadingCheck] = useState(false);

  const status = hooks.useStatus();

  useEffect(() => {
    loadApplicationInfo();
    dispatch(setIsHiddenMobileButton(false));
  }, []);

  const loadApplicationInfo = () => {
    Application.getById(
      { id },
      loadApplicationInfoSuccess,
      loadApplicationInfoFail
    );
  };
  const loadApplicationInfoSuccess = ({ data }) => {
    setApplication(data);
  };
  const loadApplicationInfoFail = () => {
    history.push("/");
  };
  const goToRequestCustomers = () => {
    history.push(PATHS.customer.requestCustomers);
  };
  const onClickLoadResults = () => {
    document.getElementById("results").click();
  };

  const handleLoadFile = (e) => {
    const { files } = e.target;
    const [file] = files;
    setIsLoadingResult(true);

    Application.loadResults({ file, id }, () => {
      setIsLoadingResult(false);
      document.location.reload();
    });
  };

  const changeActiveStatus = (is_active) => {
    Application.update({ id, is_active }, () =>
      onClickBackToArchiveSuccess(is_active)
    );
  };

  const onClickBackToArchiveSuccess = (is_active) => {
    setApplication((prev) => {
      return { ...prev, is_active };
    });
  };

  const onClickDelete = () => {
    dispatch(setIsShowPopup("isShowDeleteApplication", true));
  };

  const handleChangeCheckStatus = (isChecked) => {
    setIsLoadingCheck(true);
    Application.update(
      { id, is_result_checked: isChecked },
      successChangeCheckStatus
    );
  };

  const successChangeCheckStatus = () => {
    setIsLoadingCheck(false);
    loadApplicationInfo();
  };

  const linkButton = application.result_file ? null : (
    <LinkButton
      download
      style={{ marginBottom: 0, marginLeft: 10 }}
      href={
        process.env.REACT_APP_API_URL.replace("/api/", "") +
        application.result_file
      }
    >
      Скачать результаты
    </LinkButton>
  );

  const bottomButtons =
    activeTab === 1 ? (
      <></>
    ) : (
      <>
        <BackButton />
      </>
    );

  const additionalButton =
    status === "CUSTOMER" ? (
      <>
        <MainCustomerButton
          margin="10px 15px 10px 16px"
          onClick={goToRequestCustomers}
        >
          {T.orderCalculation}
        </MainCustomerButton>
      </>
    ) : (
      <>
        <Button
          disabled={isLoadingResult}
          onClick={onClickLoadResults}
          margin="10px 15px 10px 16px"
        >
          {isLoadingResult ? "Загрузка.." : T.loadResultsCustomer}
        </Button>
        <input
          onChange={handleLoadFile}
          name="results"
          id="results"
          type="file"
          style={{ display: "none" }}
        ></input>
      </>
    );

  const { is_active } = application;
  const newActive = is_active == 0 ? 1 : 0;
  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Title margin="30px 30px 15px 15px">{`${T.order} №${id}`}</Title>
        {status === "ADMIN" ? (
          <>
            <ThirdOrderButton
              onClick={() => changeActiveStatus(newActive)}
              marginRight
              marginTop="15px"
            >
              {is_active == 1 ? T.backToArchive : T.backToActive}
            </ThirdOrderButton>
          </>
        ) : null}
        {status === "ADMIN" ? (
          <>
            <SecondOrderButton
              onClick={onClickDelete}
              marginTop="15px"
              className="delete-button"
            >
              {T.delete}
            </SecondOrderButton>
            <DeletePopup id={id}></DeletePopup>
          </>
        ) : null}
      </Row>

      <Row>
        <ButtonFixedGroup margin="0 0 0 16px">
          {tabs.map((tab, index) => (
            <Button
              style={{ width: 240 }}
              onClick={() => setActiveTab(index)}
              key={index}
              className={index === activeTab ? "active" : ""}
            >
              {tab}
            </Button>
          ))}
        </ButtonFixedGroup>
        {additionalButton}
      </Row>
      {status === "ADMIN" ? (
        <>
          <Button
            disabled={isLoadingCheck}
            onClick={() =>
              handleChangeCheckStatus(
                application.is_result_checked == 1 ? 0 : 1
              )
            }
            marginRight
            marginTop="15px"
          >
            {isLoadingCheck
              ? "Загрузка.."
              : application.is_result_checked === 1
              ? "Проверено"
              : "Не проверено"}
          </Button>
        </>
      ) : null}
      {activeTab === 0 ? (
        status === "CUSTOMER" ? (
          <OrderInfoCustomer isShowDocuments={true} id={id} />
        ) : status === "ADMIN" ? (
          <OrderInfoCustomer isShowDocuments={true} id={id} />
        ) : (
          <OrderInfoCustomer isShowDocuments={false} id={id} />
          // <OrderInfoSpecialist id={id} />
        )
      ) : activeTab === 1 ? (
        <OrderChat id={id} />
      ) : (
        <OrderObject id={id} />
      )}
      {bottomButtons}
    </>
  );
};

export default Order;
