import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Application } from "../../api/methods";
import ApplicationInfo from "../../components/Application/ApplicationInfo";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import { Preloader } from "../../components/UI-kit/Preloader/Preloader";
import Table from "../../components/UI-kit/Table/Table";
import Text from "../../components/UI-kit/Text";

import { T } from "../../lang/ru";
import { setIsHiddenMobileButton } from "../../store/isHiddenMobileButton/action";

type Props = {
  id: any;
};

const OrderObject: React.FC<Props> = ({ id }) => {
  const [application, setApplication] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    Application.getById({ id }, ({ data }: any) => setApplication(data));
  }, []);

  if (application === null) return <Preloader />;
  return <ApplicationInfo application={application}></ApplicationInfo>;
};

export default OrderObject;
