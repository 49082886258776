import React, { useEffect, useState } from "react";
import { AnyJson, Category, Form } from "../../api/methods";
import FormsTable from "../../components/admin/forms/FormsTable";
import { FormsTableProps } from "../../components/admin/forms/Types";
import Button from "../../components/UI-kit/Buttons/Button";
import ButtonGroup from "../../components/UI-kit/Buttons/ButtonGroup";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../components/UI-kit/FloatingInput";
import InputButtonGroup from "../../components/UI-kit/InputButtonGroup";
import Title from "../../components/UI-kit/Title";

const EditorFormsList = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [forms, setForms] = useState([]);
  const [newFormName, setNameFormName] = useState("");
  const [choosenCategory, setChoosenCategory] = useState(-1);

  useEffect(() => loadForms(), [choosenCategory]);

  useEffect(() => loadCategories(), []);

  const loadCategories = () => {
    Category.getAll(
      {},
      ({ data }) => {
        setCategories(data);
        setChoosenCategory(data[0].id);
        setNewCategoryName("");
      },
      (err) => console.log(err)
    );
  };

  const addCategory = () => {
    Category.add({ name: newCategoryName }, loadCategories);
  };

  const successLoadForms = ({ data }) => setForms(data);

  const loadForms = () =>
    Form.getAll({ category_id: choosenCategory }, successLoadForms);

  const successAddForm = () => {
    loadForms();
    setNameFormName("");
  };

  const addForm = () => {
    Form.add(
      { name: newFormName, category_id: choosenCategory },
      successAddForm
    );
  };

  return (
    <>
      <Title>Редактор опросных форм</Title>
      <ButtonGroup>
        {categories.map(({ id, name }) => (
          <Button
            key={id}
            disabled={id == choosenCategory}
            onClick={() => setChoosenCategory(id)}
          >
            {name}
          </Button>
        ))}
      </ButtonGroup>
      <InputButtonGroup>
        <FloatingInput
          onChange={(e) => setNewCategoryName(e.target.value)}
          value={newCategoryName}
          label={"Название нового раздела"}
        ></FloatingInput>
        <Button
          // disabled={isAddButtonDisabled}
          onClick={addCategory}
          margin={"10px 0"}
        >
          Добавить категорию
        </Button>
      </InputButtonGroup>

      <FormsTable forms={forms} />

      <InputButtonGroup>
        <FloatingInput
          onChange={(e) => setNameFormName(e.target.value)}
          value={newFormName}
          label={"Название нового раздела"}
        ></FloatingInput>
        <Button
          // disabled={isAddButtonDisabled}
          onClick={addForm}
          margin={"10px 0"}
        >
          Добавить форму
        </Button>
      </InputButtonGroup>
    </>
  );
};

export default EditorFormsList;
