import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import {
  gray,
  lightgray,
  ultraLightgray,
} from "../../../../../constants/Colours";
import { key_divider } from "../../../../../constants/elements";
import { getPreviewMode } from "../../../../../store/previewMode/selectors";
import { setUserAnswer } from "../../../../../store/userAnswers/actions";
import { getUserAnswers } from "../../../../../store/userAnswers/selectors";
import Button from "../../../../UI-kit/Buttons/Button";
import SecondOrderButton from "../../../../UI-kit/Buttons/SecondOrderButton";
import { DeleteButton } from "../../../../UI-kit/Buttons/DeleteButton";
import Card from "../../../../UI-kit/Cards/Card";
import { CardsWrapper } from "../../../../UI-kit/CardWrapper";
import FloatingInput from "../../../../UI-kit/FloatingInput";
import InputButtonGroup from "../../../../UI-kit/InputButtonGroup";
import TopBarItem from "../TopBar/TopBarItem";
import { API_URL, Application } from "../../../../../api/methods";
import { useParams } from "react-router-dom";
import { Divider } from "../../../../UI-kit/Divider";
import Text from "../../../../UI-kit/Text";
import { LinkButton } from "../../../../UI-kit/Buttons/LinkButton";
import Wrapper from "../Wrapper";
import questionnaireFunc from "../../../../../functions/questionnaireFunc";

const RepeatListPart = ({ struct, element, addStruct, deleteStruct }) => {
  const dispatch = useDispatch();

  const { id, name } = element;
  const key = id;

  const { application_id } = useParams();

  const [selectedValue, setSelectedValue] = useState();
  const [state, setState] = useState({ value: "" });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const [newFields, setNewFields] = useState({});

  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);
  const listValues = userAnswers[key]?.answer;

  useEffect(() => {
    setIsAddButtonDisabled(state.value == "");
  }, [state.value]);

  const onClickRadioButton = (selectedValue) => setSelectedValue(selectedValue);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleAddStruct = () => {
    addStruct(element.id, state.value);
    setState({ ...state, value: "" });
  };

  const changeNewFieldsValue = (e) => {
    const { name, value } = e.target;
    setNewFields({ ...newFields, [name]: value });
  };

  const addUserAnswerBlock = () => {
    let userAnswer = questionnaireFunc.getAnswerInfo(element, [{}], null);

    if (listValues) {
      userAnswer = questionnaireFunc.getAnswerInfo(
        element,
        [...listValues, {}],
        null
      );
      dispatch(setUserAnswer(key, userAnswer));
    } else {
      dispatch(setUserAnswer(key, userAnswer));
    }
  };

  const removeUserAnswer = (index) => {
    const buf = listValues;
    buf.splice(index, 1);

    const userAnswer = questionnaireFunc.getAnswerInfo(element, buf, null);

    dispatch(setUserAnswer(key, userAnswer));
  };

  const isEmptyNewFields = () => newFields == {};

  const changeUserAnswer = (e, index) => {
    const { name, value } = e.target;
    const buf = [...listValues];
    buf[index][name] = value;
    const userAnswer = questionnaireFunc.getAnswerInfo(element, buf, null);
    dispatch(setUserAnswer(key, userAnswer));
  };

  const handleLoadFile = (e, index) => {
    const { files, name } = e.target;
    const buf = [...listValues];
    const [file] = files;

    Application.uploadFile({ file, application_id }, ({ data }) => {
      buf[index][name] = data.link;
      const userAnswer = questionnaireFunc.getAnswerInfo(element, buf, null);
      dispatch(setUserAnswer(key, userAnswer));
    });
  };

  function onClickAddFile(index) {
    const file_key = getFileKey(index);
    document.getElementById(file_key).click();
  }

  const handleCancelUploadFile = (index) => {
    const buf = [...listValues];
    buf[index].file = "";

    const file_key = getFileKey(index);

    const userAnswer = questionnaireFunc.getAnswerInfo(element, buf, null);

    dispatch(setUserAnswer(file_key, userAnswer));
  };

  const getFileKey = (index) => key + "_file_" + index;

  const checkIsAvailableAddNewInfo = () => {
    if (!listValues) return true;
    const buf = [...listValues];
    const lastInfo = buf?.pop();
    if (lastInfo) {
      console.log(Object.values(lastInfo));
    }
  };

  checkIsAvailableAddNewInfo();

  return (
    <>
      {isPreviewMode ? (
        <CardsWrapper>
          {listValues
            ? listValues.map((cardInfo, index) => {
                const file_href = cardInfo.file;

                const isFileExist = typeof file_href !== "undefined";

                let isFileNotEmpty = false;
                if (isFileExist) isFileNotEmpty = file_href.length > 0;

                return (
                  <Card key={index} style={{ marginBottom: 20 }}>
                    {element.struct.map(({ id, name }, structIndex) => {
                      const isFieldTitle = structIndex === 0;

                      const isShowFileBlock = isFileNotEmpty && !isFieldTitle;
                      if (isShowFileBlock && structIndex == 1) {
                        return (
                          <Wrapper>
                            <LinkButton
                              style={{ marginRight: 10 }}
                              href={API_URL.replace("/api/", "") + file_href}
                              download
                            >
                              Файл прикреплен
                            </LinkButton>
                            <SecondOrderButton
                              className={"delete-button"}
                              onClick={() => handleCancelUploadFile(index)}
                            >
                              Отменить загрузку
                            </SecondOrderButton>
                          </Wrapper>
                        );
                      }

                      if (isShowFileBlock) {
                        return null;
                      }

                      return (
                        <>
                          <FloatingInput
                            key={id}
                            name={name}
                            onChange={(e) => changeUserAnswer(e, index)}
                            value={cardInfo[name]}
                            style={isFieldTitle ? { fontWeight: "bold" } : {}}
                            placeholder={name}
                            label={name}
                          />
                          {isFieldTitle ? <Divider /> : null}
                        </>
                      );
                    })}
                    {isFileNotEmpty ? null : (
                      <>
                        <Text
                          style={{ textAlign: "center" }}
                          withoutMargin
                          color={gray}
                        >
                          или
                        </Text>
                        <Button onClick={() => onClickAddFile(index)}>
                          Прикрепить файл
                          <input
                            id={key + "_file_" + index}
                            name="file"
                            onChange={(e) => handleLoadFile(e, index)}
                            type="file"
                            style={{ display: "none" }}
                          ></input>
                        </Button>
                      </>
                    )}
                    <DeleteButton onClick={() => removeUserAnswer(index)}>
                      &times;
                    </DeleteButton>
                  </Card>
                );
              })
            : null}
          <Button onClick={addUserAnswerBlock} margin={"10px 0"}>
            Добавить элемент
          </Button>
        </CardsWrapper>
      ) : (
        <>
          {element.struct.map(({ id, name }, index) => {
            const isFieldTitle = index === 0;
            return (
              <InputButtonGroup key={id}>
                <FloatingInput
                  name="value"
                  onChange={handleChange}
                  value={state.value}
                  disabled
                  style={isFieldTitle ? { fontWeight: "bold" } : {}}
                  label={isFieldTitle ? "Название сущности" : "Поле сущности"}
                  value={name}
                ></FloatingInput>
                <Button
                  onClick={() => deleteStruct(element.id, id)}
                  margin={"10px 0"}
                >
                  Удалить поле
                </Button>
              </InputButtonGroup>
            );
          })}
          <Col>
            <Row>
              <InputButtonGroup>
                <FloatingInput
                  name="value"
                  onChange={handleChange}
                  value={state.value}
                  label={"Название элемента"}
                ></FloatingInput>
                <Button
                  disabled={isAddButtonDisabled}
                  onClick={handleAddStruct}
                  margin={"10px 0"}
                >
                  Добавить поле
                </Button>
              </InputButtonGroup>
            </Row>
          </Col>
        </>
      )}
    </>
  );
};

export default RepeatListPart;
