import React, { useEffect, useState } from "react";

import Table from "../../../components/UI-kit/Table/Table";
import OrderTableRow from "./OrderTableRow";

import styled from "styled-components";

import { User, Source, Promocode, Element } from "../../../api/methods";
import { T } from "../../../lang/ru";
import hooks from "../../../functions/hooks";

const paymentStatuses = [
  { value: 0, label: "Не оплачено" },
  { value: 1, label: "Оплачен аванс" },
  { value: 2, label: "Оплачено полностью" },
];

const OrdersRegistryTable = ({ data = [], getApplicationWithDate }) => {
  const [experts, setExperts] = useState([]);
  const [sources, setSources] = useState([]);
  const [promocodes, setPromocodes] = useState([]);

  const systemNames = hooks.useSystemNames();

  useEffect(() => {
    loadExperts();
    loadSources();
    loadPromocodes();
  }, []);

  const loadExperts = () => User.getExperts({}, loadExpertsSuccess);

  const loadExpertsSuccess = ({ data }) => {
    const options = data.map(({ name, surname, middlename, id }) => {
      return { value: id, label: `${surname} ${name} ${middlename}` };
    });
    setExperts(options);
  };

  const loadSources = () => Source.getAll(undefined, loadSourcesSuccess);

  const loadSourcesSuccess = ({ data }) => {
    const options = data.map(({ name, id }) => {
      return { value: id, label: name };
    });
    setSources(options);
  };

  const loadPromocodes = () => Promocode.getAll({}, loadPromocodesSuccess);

  const loadPromocodesSuccess = ({ data }) => {
    const options = data.map(({ name, id }) => {
      return { value: id, label: name };
    });
    setPromocodes(options);
  };
  const systemNamesTitles = systemNames.map(({ title }) => <th>{title}</th>);

  return (
    <div style={{ overflow: "visible" }}>
      <Table style={{ position: "absolute", left: 50 }} className="miniPadding">
        <thead>
          <tr>
            <th className="fixed-col">{T.num}</th>
            <th>{T.contractDate}</th>
            <th>{T.customer}</th>
            {systemNamesTitles}
            <th>{T.remarks}</th>
            <th>{T.filterNames.source}</th>
            <th>{T.performer}</th>
            <th>{T.paymentMen}</th>
            <th>{T.status}</th>
            <th>{T.sum}</th>
            <th>{T.prepayment}</th>
            <th>{T.deadlineOfExecution}</th>
            <th>Кол-во помещений</th>
            <th>{T.promo}</th>
            <th>{T.factOfPayment}</th>
            <th>{T.payment}</th>
            <th>{T.dateOfPayment}</th>
            <th>{T.arrears}</th>
            <th>{T.filterNames.delivery}</th>
            <th>{T.filterNames.objectType}</th>
            <th>{T.note}</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((contract) => {
            return (
              <OrderTableRow
                experts={experts}
                statuses={paymentStatuses}
                sources={sources}
                promocodes={promocodes}
                systemNames={systemNames}
                contract={contract}
                key={contract.id}
                getApplicationWithDate={getApplicationWithDate}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default OrdersRegistryTable;
