import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Subtitle from "../../components/UI-kit/Subtitle";
import Title from "../../components/UI-kit/Title";
import { PATHS } from "../../constants/RoutesPaths";
import { T } from "../../lang/ru";
import { setIsHiddenMobileButton } from "../../store/isHiddenMobileButton/action";

const RegisterSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      history.push(PATHS.login);
    }
  });

  useEffect(() => {
    dispatch(setIsHiddenMobileButton(false));
  }, []);

  return (
    <>
      <Title>{T.registerSuccess}</Title>
      <Subtitle>{`Через ${timeLeft} ${
        timeLeft === 5 ? "секунд" : timeLeft === 1 ? "секунду" : "секунды"
      } вы будете перенаправлены на страницу авторизации`}</Subtitle>
    </>
  );
};

export default RegisterSuccess;
