import React from "react";
import ThirdOrderButton from "../../../components/UI-kit/Buttons/ThirdOrderButton";
import Card from "../../../components/UI-kit/Cards/Card";
import CardBody from "../../../components/UI-kit/Cards/CardBody";
import CardImage from "../../../components/UI-kit/Cards/CardImage";
import Cards from "../../../components/UI-kit/Cards/Cards";

const NormativeBase = () => {
  const data = [
    {
      src:
        "https://i.dailymail.co.uk/i/pix/2017/04/06/11/3F006CA900000578-0-image-a-21_1491475782242.jpg",
      title: "Нормативная база!",
      link: "https://ab-dpo.ru/",
    },
    {
      src:
        "https://i.dailymail.co.uk/i/pix/2017/04/06/11/3F006CA900000578-0-image-a-21_1491475782242.jpg",
      title: "Нормативная база!",
      link: "https://ab-dpo.ru/",
    },
  ];
  return (
    <>
      <Cards>
        {data.map(({ src, title, link }, index) => (
          <Card key={index} onClick={() => window.open(link)}>
            <CardImage src={src}></CardImage>
            <CardBody>
              <ThirdOrderButton style={{ textAlign: "left" }}>
                {title}
              </ThirdOrderButton>
            </CardBody>
          </Card>
        ))}
      </Cards>
    </>
  );
};

export default NormativeBase;
