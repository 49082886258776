import styled from "styled-components";
import { black, gray } from "../../../constants/Colours";

export const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 12px;
  font-weight: bold;
  color: ${gray};
  &:hover {
    color: ${black};
  }
`;
