import React from "react";
import styled from "styled-components";
import { accent } from "../../constants/Colours";
import { device } from "../../constants/Breakpoints";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  height: 23px;
  margin-bottom: 15px;
  @media ${device.laptop} {
    width: 100%;
  }
`;

const Input = styled.input`
  appearance: none;
  margin: 0;
  position: absolute;
`;

const Label = styled.label`
  cursor: pointer;
  padding-left: 35px;
  margin-right: 75px;

  &::before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 5px;
    border-radius: 50%;
    border: 1px solid ${accent};
    bottom: -1px;
  }

  ${Input}:checked ~ &::after {
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    position: absolute;
    left: 12px;
    bottom: 6px;
    border-radius: 50%;
    background-color: ${accent};
  }

  @media ${device.laptop} {
    margin-right: 0;
  }
`;

type InputRadio = {
  id: string;
  name?: string;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  placeholder: string;
  checked: boolean;
  flexDirect: boolean;
};

const InputRadio: React.FC<InputRadio> = ({
  id = "",
  name = "",
  onClick = () => {},
  placeholder = "",
  checked = false,
  flexDirect = false,
}) => (
  <Wrapper>
    <Input
      type="radio"
      id={id}
      name={name}
      onClick={onClick}
      checked={checked}
    ></Input>
    <Label htmlFor={id}>{placeholder}</Label>
  </Wrapper>
);

export default InputRadio;
