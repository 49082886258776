import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Application } from "../../../api/methods";

import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import Text from "../../../components/UI-kit/Text";
import { PATHS } from "../../../constants/RoutesPaths";

import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const BoldText = styled.span`
  font-weight: bold;
`;

const DeletePopup = ({ name, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isShowDeleteApplication } = useSelector(getIsShowPopups);

  const onClickYes = () => {
    deleteApplication();
  };

  const deleteApplication = () => {
    Application.delete({ id }, deleteApplicationSuccess);
  };

  const deleteApplicationSuccess = () => {
    closePopup();
    goToDefaultScreen();
  };

  const goToDefaultScreen = () => {
    history.push(PATHS.admin.ordersHistory);
  };

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowDeleteApplication", false));
  };

  const nameText = <BoldText>{`№${id}`}</BoldText>;

  const buttons = (
    <ButtonsBlock>
      <Button onClick={onClickYes}>{T.yes}</Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert
      title={T.deleting}
      isShow={isShowDeleteApplication}
      buttons={buttons}
    >
      <Text>
        {T.deleteApplication}
        {nameText}?
      </Text>
    </Alert>
  );
};

export default DeletePopup;
