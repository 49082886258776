import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { Chain, Element, Form } from "../../api/methods";
import CreateButtonsBlock from "../../components/admin/Editor/CreateButtonsBlock/CreateButtonsBlock";
import EditorFormNameBlock from "../../components/admin/Editor/EditorFormNameBlock";
import { FavoritesPanel } from "../../components/admin/Editor/FavoritesPanel";
import PullElements from "../../components/admin/Editor/PullElements";
import SectionList from "../../components/admin/Editor/Section/SectionList";
import Button from "../../components/UI-kit/Buttons/Button";
import Text from "../../components/UI-kit/Text";
import Title from "../../components/UI-kit/Title";
import { PATHS } from "../../constants/RoutesPaths";
import { setElements } from "../../store/elements/actions";
import { setFavoriteElements } from "../../store/favoriteElements/actions";
import { getFavoriteElements } from "../../store/favoriteElements/selectors";
import { previewModeOFF } from "../../store/previewMode/actions";

const Editor = (props) => {
  const id = props.match.params.id;
  const history = useHistory();
  const dispatch = useDispatch();

  const elements = useSelector((state) => state.elements);

  const [name, setName] = useState("");
  const [selectedSection, setSelectedSuction] = useState(0);
  const [sections, setSections] = useState(["Раздел 1", "Раздел 2"]);
  let [systemNamesOptions, setSystemNamesOptions] = useState([]);

  useEffect(() => {
    loadForm(id);
    disablePreviewMode();
    loadSystemNames();
  }, [id]);

  const loadSystemNames = () => {
    Element.getSystemNames({}, loadSystemNamesSuccess);
  };

  const loadSystemNamesSuccess = ({ data }) => {
    const options = data.map(({ id, title }) => {
      return { id, name: title };
    });
    setSystemNamesOptions(options);
  };

  const loadFavoritesSuccess = ({ data }) => {
    dispatch(setFavoriteElements(data));
  };

  const loadFavorites = () => {
    Element.getAllFavorites({}, loadFavoritesSuccess);
  };

  const disablePreviewMode = () => dispatch(previewModeOFF());

  const loadFormSuccess = ({ data }) => {
    // fixOrderIndex(data);
    dispatch(setElements(data));
  };

  // TODO: чисто для разработки//////////////////////////////////////////////////////////////////////////////
  const fixOrderIndex = (elements = []) => {
    const orderIndexes = elements.map((element) => element.order_index);

    const isHasDuplicates = checkForDuplicates(orderIndexes);

    if (isHasDuplicates) {
      console.log(orderIndexes, elements);
      fixPoolIndexes(elements);
    }
    elements.forEach((element) => {
      const { childrens } = element;
      childrens.map(({ elems }) => {
        fixOrderIndex(elems);
      });
    });
  };

  const fixPoolIndexes = (elements = []) => {
    elements.forEach((element, index) => {
      Form.change({ id: element.id, form: id, index });
    });
  };

  function checkForDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  // //////////////////////////////////////////////////////////////////////////////

  const loadForm = (id) => {
    Form.getById({ id }, loadFormSuccess);
    loadFavorites();
  };

  const inputName = (e) => {
    setName(e.target.value);
  };

  const createFormOnAnotherForm = () => {
    console.log("Создать на основе другой формы");
  };

  const onSelectSection = (index) => {
    setSelectedSuction(index);
  };

  const addElement = (type_id) => {
    const order_index = getElementOrderIndex();
    Chain.add({ type: type_id, form_id: id, order_index }, addElementSuccess);
  };

  const addElementSuccess = () => loadForm(id);

  const getElementOrderIndex = () => {
    const isEmptyElements = elements.length == 0;

    if (isEmptyElements) return 0;

    const { order_index } = elements.pop();
    return order_index + 1;
  };

  const addElementFromFavorite = (element) => {
    const order_index = getElementOrderIndex();
    Chain.addFromFavorite(
      {
        id: element.id,
        type: element.type_id,
        form_id: id,
        order_index: order_index,
      },
      addElementSuccess
    );
  };

  const deleteFromFavorite = (element) =>
    Element.deleteFavorite({ id: element.id }, () => loadForm(id));

  const goToPreview = () => {
    history.push(PATHS.admin.editorPreview.replace(":id", id));
  };

  const isEmptyElements = elements.length == 0;

  return (
    <>
      <Title>Создание формы расчета рисков</Title>
      {isEmptyElements ? null : <Button onClick={goToPreview}>Превью</Button>}

      <FavoritesPanel
        deleteFromFavorite={deleteFromFavorite}
        addElementFromFavorite={addElementFromFavorite}
      />

      {isEmptyElements ? (
        <Text>Для добавления вопроса, нажмите на одну из кнопок ниже</Text>
      ) : (
        <PullElements
          systemNames={systemNamesOptions}
          elements={elements}
        ></PullElements>
      )}

      <CreateButtonsBlock addElement={addElement} />
    </>
  );
};

export default Editor;
