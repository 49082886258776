import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Promocode } from "../../../api/methods";

import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../../components/UI-kit/FloatingInput";

import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const AddPopup = ({ getAllPromocodes }) => {
  const dispatch = useDispatch();
  const { isShowAddPromocode } = useSelector(getIsShowPopups);
  const [promocode, setPromocode] = useState({
    name: "",
    value: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    promocode.name === "" ? setIsDisabled(true) : setIsDisabled(false);
  }, [promocode.name, promocode.value]);

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    setPromocode({ ...promocode, [name]: value });
  };

  const onClickAdd = () => {
    Promocode.add(
      { name: promocode.name, value: promocode.value },
      addPromocodeSuccess
    );
  };

  const addPromocodeSuccess = () => {
    getAllPromocodes();
    closePopup();
  };

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowAddPromocode", false));
  };

  const buttons = (
    <ButtonsBlock>
      <Button onClick={onClickAdd} disabled={isDisabled}>
        {T.addNew}
      </Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert title={T.adding} isShow={isShowAddPromocode} buttons={buttons}>
      <FloatingInput
        name="name"
        placeholder={T.title}
        onChange={onChangeInput}
        value={promocode.name}
      />
      <FloatingInput
        name="value"
        type="number"
        placeholder={T.value}
        onChange={onChangeInput}
        value={promocode.value}
      />
    </Alert>
  );
};

export default AddPopup;
