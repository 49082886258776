import T from "./types";
import { debounce } from "../../functions/common";
import { useSelector } from "react-redux";
import { getUserAnswers } from "./selectors";
import { Application } from "../../api/methods";

const initialState = {};

let stateForLoad = {};

const saveAnswersDebounced = debounce(function () {
  const application_id = document.location.pathname.split("/").pop();
  if (application_id) {
    // Application.
    console.log(application_id, stateForLoad);
  }
}, 1000);

export default function userAnswers(state = initialState, action) {
  switch (action.type) {
    case T.SET_USER_ANSWER:
      const { key, value } = action;
      stateForLoad = { ...state, [key]: value };
      saveAnswersDebounced();
      return stateForLoad;
    case T.SET_ALL_ANSWERS:
      return { ...action.payload };
    default:
      return state;
  }
}
