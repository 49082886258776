import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "../../../api/methods";
import { green } from "../../../constants/Colours";
import { PATHS } from "../../../constants/RoutesPaths";
import SecondOrderButton from "../../UI-kit/Buttons/SecondOrderButton";
import ThirdOrderButton from "../../UI-kit/Buttons/ThirdOrderButton";
import { FormsTableRowProps } from "./Types";

const FormsTableRow: React.FC<FormsTableRowProps> = ({ id, name }) => {
  const [isSuccessUpdate, setSuccessUpdate] = useState(false);

  let changeFormNameSuccess: () => void;
  let changeFormName: (e: any) => any;

  const history = useHistory();
  const editForm = () => {
    history.push(PATHS.admin.editorCreating.replace(":id", id));
  };

  const deleteForm = () => {
    const isWantDelete = window.confirm(
      `Вы действительно хотите удалить форму ${name}?`
    );
    if (isWantDelete) {
      handleDeleteConfirm();
    }
  };

  const handleDeleteConfirm = () => {
    Form.delete({ id });
    document.location.reload();
  };

  changeFormName = (e: any) =>
    Form.update({ name: e.target.value, id }, changeFormNameSuccess);

  changeFormNameSuccess = () => {
    setSuccessUpdate(true);
  };

  return (
    <tr>
      <td>
        <input
          style={{
            border: isSuccessUpdate
              ? "1px solid " + green
              : "1px solid transparent",
          }}
          onBlur={changeFormName}
          defaultValue={name}
        />
      </td>
      <td>
        <ThirdOrderButton onClick={editForm}>Редактировать</ThirdOrderButton>
      </td>
      <td>
        <ThirdOrderButton onClick={deleteForm}>Удалить</ThirdOrderButton>
      </td>
    </tr>
  );
};

export default FormsTableRow;
