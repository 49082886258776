import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Title from "../../components/UI-kit/Title";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import ExecutorInfoTable from "./ExecutorInfo/ExecutorInfoTable";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";

type Props = {
  match: {
    params: {
      id: number;
    };
  };
};

const ExecutorInfo: React.FC<Props> = (props) => {
  const history = useHistory();
  const id = props.match.params.id;
  const [infoExecutor, setInfoExecutor] = useState<object>({
    fullNameExecuter: "Константинопольский Константин Анатольевич",
    email: "1235456@mail.ru",
    numberCompletedOrders: 10,
    numberWorkOrders: 3,
    averageExecutionTime: "3 дня",
    complexity: "Сложность",
    rating: 8,
  });

  // API
  // useEffect(() => {
  //   setInfoCustomer();
  // }, []);

  const goToMainScreen = () => {
    history.push(PATHS.admin.ordersHistory);
  };

  const goToEditCustomer = () => {
    history.push(PATHS.admin.addNewExecutor + `/${id}`);
  };

  const goToAddNewCustomer = () => {
    history.push(PATHS.admin.addNewExecutor);
  };

  const openModalWindow = () => {};

  return (
    <>
      <Title>{T.moreDetailsAboutExecutor}</Title>
      <ExecutorInfoTable data={T} infoCustomer={infoExecutor} />
      <Button onClick={goToEditCustomer}>{T.edit}</Button>
      <SecondOrderButton onClick={goToMainScreen} marginLeft>
        {T.back}
      </SecondOrderButton>
      <SecondOrderButton onClick={goToAddNewCustomer} marginLeft>
        {T.addNewCustomerButton}
      </SecondOrderButton>
      <SecondOrderButton
        className="delete-button"
        onClick={openModalWindow}
        marginLeft
      >
        {T.delete}
      </SecondOrderButton>
    </>
  );
};

export default ExecutorInfo;
