import React from "react";
import Button from "../../components/UI-kit/Buttons/Button";
import Text from "../../components/UI-kit/Text";
import Title from "../../components/UI-kit/Title";

const Agreement = () => {
  return (
    <>
      <Text style={{ maxWidth: "100%", lineHeight: "180%", fontSize: 18 }}>
        <Title style={{ maxWidth: "100%" }}>
          Согласие на обработку персональных данных
        </Title>
        <p>
          Настоящим в соответствии с Федеральным законом № 152-ФЗ «О
          персональных данных» от 27.07.2006 года свободно, своей волей и в
          своем интересе выражаю свое безусловное согласие на обработку моих
          персональных данных ООО "ЦЕНТР ПБ" (ОГРН 1073702032161, ИНН
          3702531368), зарегистрированным в соответствии с законодательством РФ
          по адресу: УЛ. СТАНКОСТРОИТЕЛЕЙ, ДОМ 5А, СТРОЕНИЕ 1, ОФИС 26, 153032
          ИВАНОВО ИВАНОВСКАЯ ОБЛАСТЬ, Россия (далее по тексту - Оператор).
          Персональные данные - любая информация, относящаяся к определенному
          или определяемому на основании такой информации физическому лицу.
          Настоящее Согласие выдано мною на обработку следующих персональных
          данных:
        </p>
        Имя
        <br /> Фамилия
        <br /> Отчество
        <br /> Адрес
        <br /> Телефон
        <br /> E-mail
        <br />
        Серия, номер основного документа, удостоверяющего личность, сведения о
        дате выдачи указанного документа и выдавшем его органе
        <br />
        <br />
        <p>
          Согласие дано Оператору для совершения следующих действий с моими
          персональными данными с использованием средств автоматизации и/или без
          использования таких средств: сбор, систематизация, накопление,
          хранение, уточнение (обновление, изменение), использование,
          обезличивание, а также осуществление любых иных действий,
          предусмотренных действующим законодательством РФ как
          неавтоматизированными, так и автоматизированными способами. Данное
          согласие дается Оператору для обработки моих персональных данных в
          следующих целях:
        </p>
        – Предоставление мне услуг/работ
        <br />
        – Направление в мой адрес уведомлений, касающихся предоставляемых
        услуг/работ
        <br />
        – Подготовка и направление ответов на мои запросы
        <br />
        – Направление в мой адрес информации, в том числе рекламной, о
        мероприятиях/товарах/услугах/работах Оператора
        <br />
        <br />
        <p>
          Настоящее согласие действует до момента его отзыва путем направления
          соответствующего уведомления на электронный адрес info@fabras.ru. В
          случае отзыва мною согласия на обработку персональных данных Оператор
          вправе продолжить обработку персональных данных без моего согласия при
          наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части
          2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О
          персональных данных» от 27.07.2006 г.
        </p>
        <Button style={{ marginRight: 25 }} onClick={() => window.close()}>
          Закрыть
        </Button>
      </Text>
    </>
  );
};

export default Agreement;
