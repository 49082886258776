// const useSystemNames = ()=>{
//   const [systemNames, setSystemNames] = useState([]);
//   loadSystemNames();

import { useState, useEffect } from "react";
import { Element } from "../api/methods";
import { getStatus } from "./common";

// }
// const loadSystemNames = () => {
//     Element.getSystemNames({}, loadSystemNamesSuccess);
//   };

//   const loadSystemNamesSuccess = ({ data }) => {
//     setSystemNames(data);
//   };

const useStatus = () => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setStatus(getStatus());
  }, []);
  return status;
};

const useSystemNames = () => {
  const [systemNames, setSystemNames] = useState([]);

  useEffect(() => {
    loadSystemNames();
  }, []);

  const loadSystemNames = () => {
    Element.getSystemNames({}, loadSystemNamesSuccess);
  };

  const loadSystemNamesSuccess = ({ data }) => {
    setSystemNames(data);
  };

  return systemNames;
};

const useCheckbox = () => {
  const [value, setValue] = useState(false);

  const switchValue = () => setValue(!value);
  return [value, switchValue];
};

export default {
  useStatus,
  useSystemNames,
  useCheckbox,
};
