import React from "react";
import { useDispatch } from "react-redux";
import { Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import Alert from "../../../components/Alert/Alert";
import Text from "../../../components/UI-kit/Text";
import { gray, lightgray, red } from "../../../constants/Colours";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

import { dateTranslate } from "../../../functions/common";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";

import { API_URL, Message as M } from "../../../api/methods";
import Axios from "axios";
const MessageBlock = styled.div`
  padding: 20px 40px 20px 40px;
  &:nth-child(2) {
    backgroud-color: #fafafa;
  }
`;

const DeleteButton = styled.div`
  position: relative;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  visibility: hidden;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 18px;
    background: ${lightgray};
    position: absolute;
    transition: all 0.4s linear;
  }

  &:hover {
    &::before,
    &::after {
      background: ${red};
    }
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  ${MessageBlock}:hover & {
    visibility: visible;
  }
`;

const Message = ({ message, setDeleteMessage, index }) => {
  const { file_id, file_name } = message;

  const dispatch = useDispatch();

  const onClickDelete = () => {
    setDeleteMessage(message.id);
    dispatch(setIsShowPopup("isShowDeleteMessage", true));
  };

  const formatDate = (dateString) => {
    try {
      let [date, time] = dateString.split("T");

      const [hours, minutes] = time.split(".")[0].split(":");

      time = `${hours}:${minutes}`;

      date = date.split("-").reverse();
      date = date.join(".");

      return { time, date };
    } catch (error) {
      return { time: "", date: "" };
    }
  };

  const loadFile = () => {
    let token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: "Bearer " + token,
    };

    const url = API_URL + "message/file/" + file_id;

    Axios({
      url,
      method: "POST",
      headers: headers,
      responseType: "blob",
    }).then((response) => {
      successLoadFile(response);
    });
  };

  const successLoadFile = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
  };

  const downloadAttached = () => {
    loadFile("Прикрепленное сообщение");
  };

  const { time, date } = formatDate(message.created_at);
  return (
    <MessageBlock
      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
    >
      <Row style={{ marginLeft: 0, marginBottom: 5, alignItems: "center" }}>
        <Text color={gray} rightMargin withoutMargin>
          {message.sender}
        </Text>
        <Text color={gray} withoutMargin>
          {time} &nbsp; {date}
        </Text>
        {file_id == null ? null : (
          <SecondOrderButton
            style={{ marginLeft: 20 }}
            onClick={downloadAttached}
          >
            Прикрепленный файл
          </SecondOrderButton>
        )}
        {/* <DeleteButton onClick={onClickDelete}></DeleteButton> */}
      </Row>
      <Text>{message.message}</Text>
    </MessageBlock>
  );
};

export default Message;
