import React from "react";
import Table from "../../UI-kit/Table/Table";
import FormsTableRow from "./FormsTableRow";
import { FormsTableProps } from "./Types";

const FormsTable: React.FC<FormsTableProps> = ({ forms = [] }) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Название формы</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {forms.map(({ id, name }) => (
            <FormsTableRow key={id} id={id} name={name} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default FormsTable;
