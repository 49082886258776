import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import InputsGrid from "../../components/UI-kit/InputsGrid";
import Title from "../../components/UI-kit/Title";
import ChoiceCard from "./RequestCategories/ChoiceCard";

import { T } from "../../lang/ru";
import { Category } from "../../api/methods";
import { PATHS } from "../../constants/RoutesPaths";
import { setActiveStep } from "../../store/activeStep/actions";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import { gray } from "../../constants/Colours";

interface ParamTypes {
  payer_id: string;
}

const RequestCategories: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [content, setContent] = useState<any>([]);

  const { payer_id } = useParams<ParamTypes>();

  useEffect(() => {
    getAllCategories();
    dispatch(setActiveStep(2));
  }, []);

  const getAllCategories: () => void = () => {
    Category.getAll({}, setCategoriesToContent);
  };

  const setCategoriesToContent: (data: any) => void = ({ data }) => {
    transformContent(data, onClickCategory);
  };

  const onClickCategory: (id: string) => void = (id) => {
    history.push(
      PATHS.customer.requestForms
        .replace(":id", id)
        .replace(":payer_id", payer_id)
    );
  };

  const transformContent: (items: any, onClick: any) => void = (
    items,
    onClick
  ) => {
    setContent(
      items.map((item: any) => {
        const { name, id } = item;
        return (
          <ChoiceCard key={id} title={name} onClick={() => onClick(id)}>
            {item.name}
          </ChoiceCard>
        );
      })
    );
  };

  const goToChoosingCustomer = () => {
    history.push(PATHS.customer.requestCustomers);
  };

  return (
    <>
      <SecondOrderButton color={gray} onClick={goToChoosingCustomer}>
        {T.backToChoosingCustomer}
      </SecondOrderButton>
      <Title margin="0">{T.chooseCategory}</Title>
      <InputsGrid
        maxWidth="1000px"
        columns="1fr 1fr 1fr"
        gap="0 20px"
        margin="20px 0 0"
      >
        {content}
      </InputsGrid>
    </>
  );
};

export default RequestCategories;
