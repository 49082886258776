import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import ThirdOrderButton from "../../../components/UI-kit/Buttons/ThirdOrderButton";
import OrderTableSelect from "./OrderTableSelect";
import OrderTableInput from "./OrderTableInput";

import { Application } from "../../../api/methods";
import { PATHS } from "../../../constants/RoutesPaths";
import { dateTranslate } from "../../../functions/common";
import { APP_STATUSES, T } from "../../../lang/ru";
import styled from "styled-components";
import { Col, Row } from "styled-bootstrap-grid";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import { accent } from "../../../constants/Colours";
import { getApplicationId } from "../../../functions/getters";

const ButtonArchiveContainer = styled.div`
  position: absolute;
  display: none;
  border: none !important;
  right: -195px;
  align-items: center;
  justify-content: center;
  padding: 10px 15px !important;
  &:hover {
    display: flex;
  }
`;

const Tr = styled.tr`
  &:hover {
    & ${ButtonArchiveContainer} {
      display: flex;
    }
  }
`;

const OrderTableRow = ({
  contract,
  experts,
  sources,
  promocodes,
  statuses,
  systemNames,
  getApplicationWithDate,
}) => {
  const history = useHistory();
  const [application, setApplication] = useState({});

  const {
    address = "",
    contract_number,
    application_date = "",
    arrears = "",
    date_of_payment = "",
    expert_id = "",
    expert_middlename = "",
    deadline = "",
    rooms_count = "",
    expert_name = "",
    expert_surname = "",
    id = "",
    is_active = "",
    is_needed = "",
    is_payed = "",
    middlename = "",
    name = "",
    name_ip = "",
    name_org = "",
    note = "",
    object_name = "",
    payed_part = "",
    payer_id = "",
    price = "",
    payment_status = "",
    remark = "",
    source_id = "",
    status = "",
    prepayment = "",
    surname = "",
    type = "",
    prepayment_date = "",
    wishes = "",
    promocode = "",
    date_of_create = "",
    system_name = "",
    promocode_id = "",
    system_name_ans = "",
    system_name_answers = "",
  } = contract;

  useEffect(() => {
    setApplication(contract);
  }, []);

  const handleSelectExpertChange = (e, aplicationId) => {
    const { value } = e.target;
    Application.setExpert({ app_id: aplicationId, expert_id: value });
  };

  const handleChangeStatus = (e, aplicationId) => {
    const { value } = e.target;
    Application.setPaymentStatus({ app_id: aplicationId, status: value });
  };

  const handleSelectPaymentMenChange = (e, aplicationId) => {
    const { value } = e.target;
    Application.update({ id: aplicationId, executor_id: value });
  };

  const handleSelectStatusChange = (e, aplicationId) => {
    const { value } = e.target;
    Application.setStatus({ app_id: aplicationId, status: value });
  };

  const onClickSwap = (name) => {
    Application.update({ id, [name]: application[name] ? 0 : 1 }, () =>
      swapSuccess(name)
    );
  };

  const swapSuccess = (name) => {
    const isPayed = application[name] ? 0 : 1;
    const date = new Date().toJSON().slice(0, 10);
    setApplication((prev) => {
      return {
        ...prev,
        [name]: isPayed,
        date_of_payment: isPayed ? date : "",
      };
    });
  };

  const handleChangeInput = (e, constraints = []) => {
    const { value, name } = e.target;

    const isEmptyConstraints = constraints.length == 0;

    if (!isEmptyConstraints) {
      const [min, max] = constraints;
      let valueToUpdate = value > max ? max : value < min ? min : value;
      setApplication({ ...application, [name]: valueToUpdate });
    } else {
      setApplication({ ...application, [name]: value });
    }
  };

  const updateInput = (e, min) => {
    const { value, name } = e.target;

    if (typeof min !== "undefined") {
      const valueToUpdate = value == "" || value < min ? min : value;
      Application.update({ id, [name]: valueToUpdate });
    } else {
      Application.update({ id, [name]: value });
    }
  };

  const onClickBackToArchive = () => {
    Application.update({ id, is_active: 0 }, backToArchiveSuccess);
  };

  const backToArchiveSuccess = () => {
    setApplication((prev) => {
      return { ...prev, is_active: 0 };
    });
  };

  const goToApplication = (id) =>
    history.push(PATHS.customer.orderInfo.replace(":id", id));

  const goToDelivery = (id) => {
    history.push(PATHS.admin.orderDelivery.replace(":id", id));
  };

  const isHasPrepayment = application.prepayment > 0;
  const isFullPrepayment = application.prepayment == 100;

  statuses = !isHasPrepayment
    ? statuses.filter(({ value }) => value != 1)
    : statuses;

  if (isFullPrepayment) {
    statuses = statuses.filter(({ value }) => value != 1);
  }

  const handleSendDocs = () =>
    Application.sendDocs({ id: application.id }, successSendDocs);
  const successSendDocs = () => {
    alert("Документы были успешно отправлены");
  };

  const fixedColumn = {
    maxWidth: 400,
    minWidth: 400,
    whiteSpace: "normal",
  };

  const getCustomerName = () => {
    if (!isEmpty(name_org)) return name_org;
    if (!isEmpty(name_ip)) return name_ip;
    if (isEmpty(surname) || isEmpty(name) || isEmpty(middlename)) {
      return `Не указано`;
    }

    return `${surname} ${name} ${middlename}`;
  };

  const isEmpty = (val) => val == "" || val == null;

  return (
    <Tr>
      <td className="fixed-col">
        <ThirdOrderButton onClick={() => goToApplication(id)}>
          {getApplicationId(contract_number)}
        </ThirdOrderButton>
      </td>
      <td>{dateTranslate(application_date)}</td>
      <td
        style={{ cursor: "pointer", color: accent }}
        onClick={() =>
          history.push(
            PATHS.customer.customerInfo.replace(
              ":id/:application_id?",
              application.payer_id
            )
          )
        }
      >
        {getCustomerName()}
      </td>
      {systemNames?.map(({ title }) => {
        if (!system_name_answers) return null;

        const answer = system_name_answers[title];
        return <td style={fixedColumn}>{answer}</td>;
      })}

      <td>
        <OrderTableInput
          placeholder={T.note}
          value={application.note}
          onChange={handleChangeInput}
          name="note"
          onBlur={updateInput}
        ></OrderTableInput>
      </td>
      <td>
        <OrderTableSelect
          onChange={updateInput}
          options={sources}
          selectedValue={source_id}
          name="source_id"
          placeholder={T.filterNames.source}
        ></OrderTableSelect>
      </td>
      <td>
        <OrderTableSelect
          onChange={(e) => handleSelectExpertChange(e, id)}
          options={experts}
          selectedValue={expert_id}
          placeholder={T.chooseExpert}
        ></OrderTableSelect>
      </td>
      <td>
        <OrderTableSelect
          onChange={(e) => handleSelectPaymentMenChange(e, id)}
          options={experts}
          selectedValue={application.executor_id}
          placeholder={T.choosePaymentMen}
        ></OrderTableSelect>
      </td>
      <td>
        <OrderTableSelect
          onChange={(e) => handleSelectStatusChange(e, id)}
          options={APP_STATUSES}
          selectedValue={status}
          placeholder={T.setStatus}
        ></OrderTableSelect>
        <SecondOrderButton onClick={handleSendDocs}>
          Отправить документы
        </SecondOrderButton>
      </td>

      <td className="input">
        <OrderTableInput
          placeholder={T.sum}
          value={application.price}
          onChange={handleChangeInput}
          name="price"
          onBlur={updateInput}
        ></OrderTableInput>
      </td>
      <td>
        <OrderTableInput
          placeholder={T.prepayment}
          value={application.prepayment}
          onChange={(e) => handleChangeInput(e, [0, 100])}
          name="prepayment"
          onBlur={(e) => updateInput(e, 0)}
        ></OrderTableInput>
      </td>
      <td className="input">
        <OrderTableInput
          placeholder={T.deadlineOfExecution}
          value={application.deadline}
          onChange={handleChangeInput}
          name="deadline"
          onBlur={updateInput}
        ></OrderTableInput>
      </td>
      <td className="input">
        <OrderTableInput
          placeholder={0}
          value={application.rooms_count}
          onChange={handleChangeInput}
          name="rooms_count"
          onBlur={updateInput}
        ></OrderTableInput>
      </td>
      <td>
        <OrderTableSelect
          disabledPlaceholder={false}
          onChange={updateInput}
          name="promocode_id"
          defaultValue={0}
          options={promocodes}
          selectedValue={parseInt(promocode_id)}
          placeholder={"Нет промокода"}
        ></OrderTableSelect>
      </td>
      <td onClick={() => onClickSwap("is_payed")} style={{ cursor: "pointer" }}>
        <OrderTableSelect
          onChange={(e) => handleChangeStatus(e, id)}
          options={statuses}
          selectedValue={application.payment_status}
          placeholder={T.choosePaymentMen}
        ></OrderTableSelect>
      </td>
      <td>
        <OrderTableInput
          placeholder={T.payment}
          value={application.payed_part}
          onChange={handleChangeInput}
          name="payed_part"
          onBlur={updateInput}
        ></OrderTableInput>
      </td>

      <td>
        {/* {dateTranslate(application.date_of_payment)} */}
        {isHasPrepayment && !isFullPrepayment ? (
          <>
            <span style={{ padding: 13 }}>
              {T.dateOfPrepayment}
              <OrderTableInput
                placeholder={T.dateOfPrepayment}
                value={application.prepayment_date}
                onChange={handleChangeInput}
                name="prepayment_date"
                onBlur={updateInput}
                type="date"
              ></OrderTableInput>
            </span>
            <br />
            <span style={{ padding: 13, display: "block", marginTop: 14 }}>
              {T.dateOfFullPayment}
              <OrderTableInput
                placeholder={T.dateOfPayment}
                value={application.date_of_payment}
                onChange={handleChangeInput}
                name="date_of_payment"
                onBlur={updateInput}
                type="date"
              ></OrderTableInput>
            </span>
          </>
        ) : (
          <OrderTableInput
            placeholder={T.dateOfPayment}
            value={application.date_of_payment}
            onChange={handleChangeInput}
            name="date_of_payment"
            onBlur={updateInput}
            type="date"
          ></OrderTableInput>
        )}
      </td>
      <td>
        {/* {application.arrears} */}
        <OrderTableInput
          placeholder={T.arrears}
          value={application.arrears}
          onChange={handleChangeInput}
          name="arrears"
          onBlur={updateInput}
        ></OrderTableInput>
      </td>
      <td>
        <ThirdOrderButton onClick={() => goToDelivery(id)}>
          {is_needed == 1 ? T.filterNames.delivery : T.noDelivery}
        </ThirdOrderButton>
      </td>
      <td style={fixedColumn}>{application.object_name}</td>
      <td>
        <OrderTableInput
          placeholder={T.remark}
          value={application.remark}
          onChange={handleChangeInput}
          name="remark"
          onBlur={updateInput}
        ></OrderTableInput>
      </td>
      {application.is_active != -1 ? null : (
        <ButtonArchiveContainer>
          <ThirdOrderButton onClick={onClickBackToArchive}>
            {T.backToArchive}
          </ThirdOrderButton>
        </ButtonArchiveContainer>
      )}

      {/* <td>
      <ThirdOrderButton>{assessment}</ThirdOrderButton>
    </td>
    <td>
      <ThirdOrderButton>{term}</ThirdOrderButton>
    </td> */}
    </Tr>
  );
};

export default OrderTableRow;
