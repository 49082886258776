import React, { Component, PropTypes, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RichTextEditor from "react-rte";
import { Col, Row } from "styled-bootstrap-grid";
import { getPreviewMode } from "../../../store/previewMode/selectors";
import SecondOrderButton from "../Buttons/SecondOrderButton";

import "./TextEditor.css";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Полужирный", style: "BOLD", className: "custom-css-class" },
    { label: "Курсив", style: "ITALIC" },
    { label: "Подчеркивание", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Обычный", style: "unstyled" },
    { label: "Заголовок 1", style: "header-one" },
    { label: "Заголовок 2", style: "header-two" },
    { label: "Заголовок 3", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const TextEditor = ({ onChange, element, placeholder }) => {
  const isPreviewMode = useSelector(getPreviewMode);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const isEmpty = Object.keys(value._cache).length == 0;

  useEffect(() => {
    const { tip } = element;
    if (tip == "null" || tip == null)
      setValue(RichTextEditor.createEmptyValue());
    else setValue(RichTextEditor.createValueFromString(tip, "html"));
  }, [element.tip]);

  useEffect(() => {
    rebindHrefsToOpenInNewWindow();
  }, [isEmpty, isReadOnly, isPreviewMode]);

  const rebindHrefsToOpenInNewWindow = () => {
    const links = document.querySelectorAll(".DraftEditor-root a");
    for (const link of links) {
      link.target = "_blank";
    }
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const handleBlur = () => {
    offEditingMode();
    onChange(value.toString("html"));
  };

  const offEditingMode = () => setIsReadOnly(true);
  const toggleEditingMode = () => setIsReadOnly(!isReadOnly);

  const isImportantInfo = element.type == 6;

  return (
    <>
      {isPreviewMode ? null : (
        <SecondOrderButton
          margin="0 0 10px 0"
          onClick={isReadOnly ? toggleEditingMode : handleBlur}
        >
          {isReadOnly
            ? isImportantInfo
              ? "Редактировать содержание"
              : "Редактировать подсказку"
            : "Сохранить изменения"}
        </SecondOrderButton>
      )}
      {isReadOnly && isEmpty ? null : (
        <RichTextEditor
          // onBlur={handleBlur}
          placeholder={placeholder}
          readOnly={isPreviewMode ? true : isReadOnly}
          toolbarConfig={toolbarConfig}
          value={value}
          onChange={handleChange}
        />
      )}
    </>
  );
};
