import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Message as M } from "../../api/methods";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FileUploader from "../../components/UI-kit/FileUploader";
import FloatingInput from "../../components/UI-kit/FloatingInput";
import Text from "../../components/UI-kit/Text";
import { device } from "../../constants/Breakpoints";
import { gray, green, yellow } from "../../constants/Colours";
import { T } from "../../lang/ru";
import { setIsShowPopup } from "../../store/popups/actions";
import BackButton from "./Order/BackButton";
import DeleteMessage from "./OrderChat/DeleteMessage";
import Message from "./OrderChat/Message";

const OrderChatBlock = styled.div`
  margin-top: 30px;
  @media ${device.tablet} {
    & ${Button} {
      font-size: 13px;
    }
  }
`;

const OrderChat = ({ id }) => {
  const dispatch = useDispatch();
  const [newMessage, setNewMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState();

  const [attachedFile, setAttachedFile] = useState(null);
  useEffect(() => {
    getAllMessage();
  }, []);

  useEffect(() => {
    scrollMessagesContainerToBottom();
  }, [allMessages]);

  const scrollMessagesContainerToBottom = () => {
    let msgsContainer = document.getElementById("messagesBlock");
    if (msgsContainer) {
      msgsContainer.scrollTop = msgsContainer.scrollHeight;
    }
  };

  const getAllMessage = () => {
    M.getAllMessage({ id }, successGetAllMessage);
  };

  const successGetAllMessage = ({ data }) => {
    setAllMessages(data);
  };

  const sendMessage = () => {
    M.send({ id, message: newMessage, file: attachedFile }, successSendMessage);
  };

  const successSendMessage = ({ data }) => {
    setAttachedFile(null);
    setNewMessage("");
    getAllMessage();
  };

  const onChangeTextarea = (e) => {
    setNewMessage(e.target.value);
  };

  const onClickDelete = (id) => {
    M.delete({ id }, deleteMessageSuccess);
  };

  const deleteMessageSuccess = ({ data }) => {
    console.log(data);
    getAllMessage();
    dispatch(setIsShowPopup("isShowDeleteMessage", false));
  };

  function onClickAddFile(key) {
    document.getElementById(key).click();
  }

  const handleLoadFile = (e, index) => {
    const { files, name } = e.target;
    const [file] = files;
    setAttachedFile(file);
  };

  const isEmptyMessage = newMessage.length == 0;

  const isFileAttached = attachedFile != null;
  return (
    <OrderChatBlock>
      <div
        id="messagesBlock"
        style={{
          maxHeight: 400,
          overflow: "auto",
          border: "1px solid #eee",
          borderRadius: 10,
        }}
      >
        {allMessages.length ? (
          allMessages.map((message, index) => (
            <Message
              message={message}
              index={index}
              setDeleteMessage={setDeleteMessage}
            />
          ))
        ) : (
          <div
            style={{
              padding: "20px 40px 10px 40px",
            }}
          >
            <Text color={gray}>{T.noMessages}</Text>
          </div>
        )}
      </div>
      <FloatingInput
        value={newMessage}
        style={{
          borderColor: isFileAttached && isEmptyMessage ? yellow : null,
        }}
        placeholder={
          isFileAttached && isEmptyMessage ? "Введите текст сообщения" : null
        }
        onChange={onChangeTextarea}
        isTextarea={true}
        width="100%"
      ></FloatingInput>

      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <Button
          disabled={isEmptyMessage}
          margin={"0 10px 20px 0"}
          onClick={sendMessage}
        >
          {T.send}
        </Button>
        {!isFileAttached ? (
          <Button margin={"0 0 20px 0"} onClick={() => onClickAddFile("file")}>
            Прикрепить файл
            <input
              id={"file"}
              name={"file"}
              onChange={handleLoadFile}
              type="file"
              style={{ display: "none" }}
            ></input>
          </Button>
        ) : (
          <>
            <Text color={green} style={{ marginRight: 10 }}>
              Файл прикреплен
            </Text>
            <SecondOrderButton
              style={{ marginBottom: 16 }}
              onClick={() => setAttachedFile(null)}
            >
              Открепить файл
            </SecondOrderButton>
          </>
        )}
      </div>

      <br />
      <BackButton />
      <DeleteMessage
        id={deleteMessage}
        onClickOk={onClickDelete}
      ></DeleteMessage>
    </OrderChatBlock>
  );
};

export default OrderChat;
