import React from "react";
import styled from "styled-components";
import { accent, gray, lightgray } from "../../constants/Colours";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
`;

const Input = styled.input`
  font-size: 16px;
  padding: 4px;
  display: block;
  width: 164px;
  border: 1px solid ${lightgray};
  border-radius: 4px;
  &:focus {
    border-color: ${accent};
    outline: none;
  }
  padding-left: 20px;
`;

type LabelProps = {
  valueForLabel: string;
};

const Label = styled.label`
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 11px;
  top: 5px;
  padding-left: 10px;
  font-size: 18px;
  color: ${gray};
  transition: 0.2s ease all;
  display: ${(props: LabelProps) =>
    props.valueForLabel === "" ? "block" : "none"};
`;

type MiniInput = {
  placeholder?: string;
  value: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const MiniInput: React.FC<MiniInput> = ({
  placeholder = "",
  value = "",
  onChange,
  name = "",
}) => (
  <Wrapper>
    <Input type="number" onChange={onChange} name={name} value={value}></Input>
    <Label valueForLabel={value} style={{ fontSize: 16 }}>
      {placeholder}
    </Label>
  </Wrapper>
);

export default MiniInput;
