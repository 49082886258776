import styled from "styled-components";

const CardImage = styled.img`
  max-height: 175px;
  width: 350px;
  height: 175px;
  border-radius: 4px;
`;

export default CardImage;
