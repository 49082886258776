import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import { ultraLightgray, gray, white } from "../../../constants/Colours";
import Table from "./Table";

const CustomerApplicationTable = styled(Table)`
  @media ${device.tablet} {
    font-size: 16px !important;
    width: 100%;

    & thead {
      display: none;
    }

    & tr * {
      border: none;
      padding: 0 !important;
    }

    & tr {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid ${ultraLightgray};
      padding: 10px 0;
    }

    & td::before {
      content: attr(aria-label);
      color: ${gray};
      border: none;
      display: block;
    }

    & td.gray {
      background-color: ${white};
    }

    & .right {
      text-align: left;
    }
  }
`;

export default CustomerApplicationTable;
