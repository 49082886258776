import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Source } from "../../api/methods";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingSelect from "../../components/UI-kit/FloatingSelect";
import Title from "../../components/UI-kit/Title";
import { T } from "../../lang/ru";
import { setIsShowPopup } from "../../store/popups/actions";
import AddPopup from "./EditorSources/AddPopup";
import DeletePopup from "./EditorSources/DeletePopup";

const EditorSources = () => {
  const dispatch = useDispatch();
  const [source, setSource] = useState(-1);
  const [allSources, setAllSources] = useState([]);
  const [selectSource, setSelectSource] = useState(0);

  useEffect(() => {
    getAllSource();
  }, []);

  const getAllSource = () => {
    Source.getAll({}, getAllSourcesSuccess);
  };

  const getAllSourcesSuccess = ({ data }) => {
    setAllSources(data);
  };

  const onChangeSelect = (e) => {
    setSource(e.target.value);
    setSelectSource(
      allSources.findIndex((item) => Number(item.id) === Number(e.target.value))
    );
  };

  const onClickDelete = () => {
    dispatch(setIsShowPopup("isShowDeleteSource", true));
  };

  const onClickAdd = () => {
    dispatch(setIsShowPopup("isShowAddSource", true));
  };

  return (
    <>
      <Title>{T.editSources}</Title>
      <FloatingSelect
        value={source}
        title={T.filterNames.source}
        name="source"
        onChange={onChangeSelect}
        options={allSources}
      ></FloatingSelect>
      <Button margin={"10px 15px 10px 0"} onClick={onClickAdd}>
        {T.addNewSource}
      </Button>
      {source !== -1 ? (
        <>
          <SecondOrderButton
            marginLeft
            className="delete-button"
            onClick={onClickDelete}
          >
            {T.deleteSelectSource}
          </SecondOrderButton>
        </>
      ) : (
        <></>
      )}
      <AddPopup getAllSource={getAllSource}></AddPopup>
      <DeletePopup
        name={allSources[selectSource]?.name}
        id={source}
        getAllSource={getAllSource}
        setSource={setSource}
      ></DeletePopup>
    </>
  );
};

export default EditorSources;
