import styled from "styled-components";

const Cards = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export default Cards;
