import T from "./types";

export const setElements = (elements) => ({
  type: T.SET_EDITOR_ELEMENTS,
  payload: elements,
});

export const setStructToElement = (id, struct) => ({
  type: T.SET_STRUCT_ELEMENT,
  id,
  struct,
});

export const addStructElement = (id, structElement) => ({
  type: T.ADD_ELEMENT_TO_STRUCT,
  id,
  structElement,
});

export const deleteStructElement = (id, structElementId) => ({
  type: T.DELETE_ELEMENT_FROM_STRUCT,
  id,
  structElementId,
});

export const typeName = (id, text) => ({
  type: T.TYPE_NAME,
  id,
  text,
});

export const typeTip = (id, text) => ({
  type: T.TYPE_TIP,
  id,
  text,
});

export const pasteHref = (id, text) => ({
  type: T.CHANGE_HREF,
  id,
  text,
});

export const deleteHref = (id) => ({
  type: T.CHANGE_HREF,
  id,
  text: "",
});

export const setRepeatChildrenCountToElement = (id, repeat) => ({
  type: T.SET_REPEAT_CHILDREN_COUNT_TO_ELEMENT,
  id,
  repeat,
});

export const modificateIdsOfSuperComplexLists = () => ({
  type: T.MODIFICATE_IDS_OF_SUPER_COMPLEXT_LISTS,
});
