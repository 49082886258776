import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import InputsGrid from "../../components/UI-kit/InputsGrid";
import Title from "../../components/UI-kit/Title";
import ChoiceCard from "./RequestCategories/ChoiceCard";

import { T } from "../../lang/ru";
import { Application, Form } from "../../api/methods";
import { PATHS } from "../../constants/RoutesPaths";
import { setActiveStep } from "../../store/activeStep/actions";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import { gray } from "../../constants/Colours";

type Props = {
  match: {
    params: {
      id: number;
      payer_id: number;
    };
  };
};

const RequestCategories: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, payer_id } = props.match.params;
  const [content, setContent] = useState<any>([]);

  useEffect(() => {
    getAllForms(id);
    dispatch(setActiveStep(2));
  }, []);

  const getAllForms: (id: any) => void = (id) => {
    Form.getAll({ category_id: id }, setFormsToContent);
  };

  const setFormsToContent: (data: any) => void = ({ data }) => {
    transformContent(data, goToRequestForms);
  };

  const goToRequestForms: (id: any) => void = (id) => {
    addApplication(id);
  };

  const addApplication = (form_id: any) => {
    Application.add(
      {
        payer_id: String(payer_id),
        form_id: String(form_id),
      },
      ({ data }) => successAddApllication(data.id, form_id)
    );
  };

  const successAddApllication = (application_id: any, form_id: any) => {
    history.push(
      PATHS.customer.requestPassing
        .replace(":id", form_id)
        .replace(":payer_id", String(payer_id))
        .replace(":application_id", application_id)
    );
  };

  const goToRequestCategories: () => void = () => {
    history.push(
      PATHS.customer.requestCategories.replace(":payer_id", String(payer_id))
    );
  };

  const transformContent: (items: any, onClick: any) => void = (
    items,
    onClick
  ) => {
    setContent(
      items.map((item: any) => {
        const { name, id } = item;
        console.log(name, id, item.category_id);

        return (
          <ChoiceCard key={id} title={name} onClick={() => onClick(id)}>
            {item.name}
          </ChoiceCard>
        );
      })
    );
  };

  return (
    <>
      <SecondOrderButton color={gray} onClick={goToRequestCategories}>
        {T.backToChooseCategory}
      </SecondOrderButton>
      <Title margin="0">{T.chooseForm}</Title>
      <InputsGrid
        maxWidth="1000px"
        columns="1fr 1fr 1fr"
        gap="0 20px"
        margin="20px 0 0"
      >
        {content}
      </InputsGrid>
    </>
  );
};

export default RequestCategories;
