import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import FloatingInput from "../../components/UI-kit/FloatingInput";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import Title from "../../components/UI-kit/Title";

import { T } from "../../lang/ru";
import { isValidEmailCheck } from "../../functions/validators";
import { PATHS } from "../../constants/RoutesPaths";
import { Auth, User } from "../../api/methods";

const AddingNewExecutor = () => {
  const history = useHistory();
  const [newExecutorData, setNewExecutorData] = useState({
    surname: "",
    name: "",
    middlename: "",
    email: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setNewExecutorData({ ...newExecutorData, [name]: value });
  };

  const onClickSave = () => registerNewExecutor();

  const registerNewExecutor = () =>
    Auth.register(newExecutorData, registerNewExecutorSuccess);

  const registerNewExecutorSuccess = ({ data }) => {
    changeStatusToExpert(data.id);
  };

  const changeStatusToExpert = (id) =>
    User.changeUserStatus({ user_id: id, status: 2 }, goToDefaultPath);

  const goToDefaultPath = () => {
    history.push(PATHS.admin.ordersHistory);
  };

  const checkEmailValid = (e) => {
    setIsValidEmail(isValidEmailCheck(e.target.value));
  };

  return (
    <>
      <Title>{T.addingNewCommissionMember}</Title>
      <FloatingInput
        name="surname"
        placeholder={T.surname}
        onChange={onChangeHandler}
        value={newExecutorData.surname}
      ></FloatingInput>
      <FloatingInput
        name="name"
        placeholder={T.name}
        onChange={onChangeHandler}
        value={newExecutorData.name}
      ></FloatingInput>
      <FloatingInput
        name="middlename"
        placeholder={T.middleName}
        onChange={onChangeHandler}
        value={newExecutorData.middlename}
      ></FloatingInput>
      <FloatingInput
        name="email"
        placeholder={T.email}
        onChange={onChangeHandler}
        value={newExecutorData.email}
        wrong={!isValidEmail}
        onBlur={checkEmailValid}
        wrongMessage={T.inCorrectEmail}
      ></FloatingInput>
      <Button onClick={onClickSave}>{T.save}</Button>
      <SecondOrderButton marginLeft onClick={goToDefaultPath}>
        {T.back}
      </SecondOrderButton>
    </>
  );
};

export default AddingNewExecutor;
