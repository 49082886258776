import React from "react";
import Table from "../../../components/UI-kit/Table/Table";

type Props = {
  data: any;
  infoCustomer: object;
};

const ExecutorInfoTable: React.FC<Props> = ({
  data = {},
  infoCustomer = {},
}) => (
  <Table className="miniPadding">
    <tbody>
      {Object.entries(infoCustomer).map(([key, value]) => (
        <tr key={key}>
          <td className="gray" style={{ width: 400, textAlign: "right" }}>
            {data[key]}
          </td>
          <td style={{ width: 400 }}>{value}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default ExecutorInfoTable;
