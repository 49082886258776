import React from "react";
import { Tooltip as TooltipLightweight } from "react-lightweight-tooltip";
import { black, gray, ultraLightgray } from "../../constants/Colours";

type MarginsType = {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
};

type TooltipProps = {
  children: React.ReactNode;
  label: string;
  margin?: MarginsType;
};

const Tooltip: React.FC<TooltipProps> = ({
  label = "",
  children,
  margin = { left: 0, right: 0, top: 0, bottom: 0 },
}) => {
  return (
    <TooltipLightweight
      styles={{
        wrapper: {
          position: "relative",
          display: "inline-block",
          zIndex: 98,
          color: "#555",
          cursor: "defualt",
          // bottom: '100%',
          marginLeft: margin.left,
          marginRight: margin.right,
          marginBottom: margin.bottom,
          marginTop: margin.top,
        },
        tooltip: {
          position: "absolute",
          zIndex: 99,
          bottom: "100%",
          left: "50%",
          marginBottom: "10px",
          padding: "5px",
          background: `${ultraLightgray}`,
          WebkitTransform: "translateX(-50%)",
          msTransform: "translateX(-50%)",
          OTransform: "translateX(-50%)",
          transform: "translateX(-50%)",
          borderRadius: 4,
        },
        content: {
          background: "#eee",
          color: black,
          position: "relative",
          bottom: "1px",
          lineHeight: "28px",
          fontSize: "14px",
          padding: ".3em 1em",
          whiteSpace: "nowrap",
        },
        arrow: {
          position: "absolute",
          width: "0",
          height: "0",
          bottom: "-5px",
          left: "50%",
          marginLeft: "-5px",
          borderLeft: "solid transparent 5px",
          borderRight: "solid transparent 5px",
          borderTop: `solid ${ultraLightgray} 5px`,
        },
        gap: {
          position: "absolute",
          width: "100%",
          height: "20px",
          bottom: "-20px",
        },
      }}
      content={label}
    >
      {children}
    </TooltipLightweight>
  );
};

export default Tooltip;
