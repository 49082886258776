import React from "react";
import styled from "styled-components";
import { lightgray, white } from "../../constants/Colours";
import Subtitle from "../UI-kit/Subtitle";

const AlertBlock = styled.div`
  content: "";
  position: fixed;
  display: flex;
  justify-content: center;
  visibility: hidden;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: all 0.4s linear;
  &.show {
    visibility: visible;
  }
`;

const AlertBody = styled.div`
  background: ${white};
  position: absolute;
  top: -100%;
  transition: all 0.4s linear;
  border: 1px solid ${lightgray};
  padding: 25px;
  &.show {
    top: 50%;
    transform: translateY(-50%);
  }
  & ${Subtitle} {
    margin-top: 0;
  }
`;

const Alert = ({ title, isShow, buttons, children }) => {
  return (
    <AlertBlock className={isShow ? "show" : ""}>
      <AlertBody className={isShow ? "show" : ""}>
        <Subtitle>{title}</Subtitle>
        {children}
        {buttons}
      </AlertBody>
    </AlertBlock>
  );
};

export default Alert;
