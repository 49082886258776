import React from "react";
import { Col, Row } from "styled-bootstrap-grid";

import emptyIcon from "../../assets/svg/empty-folder.svg";
import { accent } from "../../constants/Colours";
import Subtitle2 from "./Subtitle2";
import Text from "./Text";

export const EmptyApplicationsBlock = () => (
  <Col>
    <Row
      style={{
        alignItems: "center",
        width: "fit-content",
        border: "1px solid",
        padding: 40,
        borderRadius: 10,
        borderColor: "#eee",
      }}
    >
      <img src={emptyIcon} style={{ width: 60, height: 60, marginRight: 20 }} />
      <Text style={{ margin: 0 }}>
        Заявки <br /> отсутствуют
      </Text>
    </Row>
  </Col>
);
