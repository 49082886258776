import React from "react";
import styled from "styled-components";
import { accent, lightgray, white } from "../../../constants/Colours";

export const Card = styled.div`
  font-size: 16px;
  padding: 15px 15px 15px 20px;
  margin: 10px 0;
  display: block;
  background-color: white;
  border: 1px solid ${lightgray};
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${accent};
    color: ${white};
  }
`;

type ChoiсeCardProps = {
  title: string;
  onClick: any;
};

const ChoiсeCard: React.FC<ChoiсeCardProps> = ({ title = "", onClick }) => (
  <Card onClick={onClick}>{title}</Card>
);

export default ChoiсeCard;
