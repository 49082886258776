import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ThirdOrderButton from "../../components/UI-kit/Buttons/ThirdOrderButton";
import Table from "../../components/UI-kit/Table/Table";
import Button from "../../components/UI-kit/Buttons/Button";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";
import { User } from "../../api/methods";
import Text from "../../components/UI-kit/Text";
import { Preloader } from "../../components/UI-kit/Preloader/Preloader";

const ExecutorsList = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    loadExecutors();
  }, []);

  const loadExecutors = () => User.getExperts({}, loadExecutorsSuccess);
  const loadExecutorsSuccess = ({ data }) => setData(data);

  const history = useHistory();

  const goToCreateExecutor = () => {
    history.push(PATHS.admin.addNewExecutor);
  };

  const goToEditExecutor = (id) => {
    history.push(PATHS.admin.executorInfo.replace(":id", String(id)));
  };

  return (
    <>
      {data == null ? (
        <Preloader />
      ) : data.length == 0 ? (
        <Text>Исполнители отсутствуют</Text>
      ) : (
        <Table className="smoothPadding">
          <thead>
            <tr>
              <th>ФИО</th>
              <th>Email</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {data.map(({ id, surname, name, middlename, email }) => (
              <tr key={id}>
                <td>
                  {surname} {name} {middlename}
                </td>
                <td>{email}</td>
                {/* <td>
                  <ThirdOrderButton onClick={() => goToEditExecutor(id)}>
                    {T.moreDetails}
                  </ThirdOrderButton>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Button onClick={goToCreateExecutor}>{T.addNewExecutor}</Button>
    </>
  );
};

export default ExecutorsList;
