import styled from "styled-components";
import { lightgray } from "../../constants/Colours";

export const SmallInput = styled.input`
  width: 180px;
  font-size: 16px;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid ${lightgray};
  outline: none;
`;
