import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { key_divider } from "../../../../../constants/elements";
import questionnaireFunc from "../../../../../functions/questionnaireFunc";
import { getPreviewMode } from "../../../../../store/previewMode/selectors";
import { setUserAnswer } from "../../../../../store/userAnswers/actions";
import { getUserAnswers } from "../../../../../store/userAnswers/selectors";
import Button from "../../../../UI-kit/Buttons/Button";
import FloatingInput from "../../../../UI-kit/FloatingInput";
import InputButtonGroup from "../../../../UI-kit/InputButtonGroup";

const ComplexListPart = ({ element }) => {
  const { id, name } = element;
  const key = id;

  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);

  const [newListElement, setNewListElement] = useState("");
  const [isInputWarning, setIsInputWarning] = useState(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    const userAnswer = questionnaireFunc.getAnswerInfo(element, list, null);
    dispatch(setUserAnswer(key, userAnswer));
  }, [list]);

  const type = (e) => {
    const userAnswer = questionnaireFunc.getAnswerInfo(
      element,
      e.target.value,
      null
    );
    dispatch(setUserAnswer(key, userAnswer));
  };

  const deleteListElement = (index) => {
    const buf = [...list];
    buf.splice(index, 1);
    setList(buf);
  };

  const addAnswer = () => {
    if (newListElement == "") {
      setInputWarning();
      return;
    }

    setList([...list, newListElement]);
    clearNewListElement();
  };

  const setInputWarning = () => setIsInputWarning(true);

  const clearNewListElement = () => setNewListElement("");

  const typeNewElement = (e) => setNewListElement(e.target.value);

  if (!isPreviewMode) return null;

  return (
    <>
      {list.map((answer, index) => (
        <InputButtonGroup key={index}>
          <FloatingInput
            disabled
            onChange={type}
            value={answer}
          ></FloatingInput>
          <Button
            isDanger
            onClick={() => deleteListElement(index)}
            margin={"10px 0"}
          >
            Удалить
          </Button>
        </InputButtonGroup>
      ))}
      <InputButtonGroup>
        <FloatingInput
          onChange={typeNewElement}
          wrong={isInputWarning}
          value={newListElement}
        ></FloatingInput>

        <Button onClick={addAnswer} margin={"10px 0"}>
          Добавить ответ
        </Button>
      </InputButtonGroup>
    </>
  );
};

export default ComplexListPart;
