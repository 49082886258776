
import {
  LoaderState,
  LoaderActionTypes,
  SET_LOADER_STATE
} from './types'

const initialState: LoaderState = "DEFAULT"


export function loaderReducer(
  state = initialState,
  action: LoaderActionTypes
): LoaderState {
  switch (action.type) {
    case SET_LOADER_STATE:

      if (action.payload === "DEFAULT") {
        setTimeout(() => {}, 2000);
      }

      return action.payload
    default:
      return state
  }
}