function getAnswerInfo(element, answer, systemNameId = null) {
  const { name, parent_id } = element;
  return {
    Title: name,
    parentId: parent_id,
    answer,
    system_name_id: systemNameId,
  };
}

function isRequiredFieldEmpty(elements, userAnswers) {
  let isEmpty = false;

  let requiredElementsIds = [];
  findRequiredElements(elements, requiredElementsIds);

  requiredElementsIds.forEach((requiredId) => {
    const value = userAnswers[requiredId]?.answer;

    const valueType = typeof value;
    const isUndefinedValue = valueType == "undefined";

    // проверка что вообще ничего нет
    if (isUndefinedValue) {
      isEmpty = true;
      return;
    }

    const isValueString = valueType == "string";

    // проверка пустой строки
    if (isValueString) {
      if (value.length == 0) {
        isEmpty = true;
        return;
      }
      return;
    }

    const JSONValue = JSON.stringify(value);
    const isValueArray = JSONValue.includes("[") && JSONValue.includes("]");

    // проверка массива объектов
    if (isValueArray) {
      value.forEach((obj) => {
        if (typeof obj == "string") {
          return;
        }

        const isCorrect = isCorrectObjectCheck(obj);
        if (!isCorrect) {
          isEmpty = true;
          return;
        }
      });
      return;
    }

    // Проверка объекта
    if (!isCorrectObjectCheck(value)) {
      isEmpty = true;
      return;
    }
  });
  return isEmpty;
}

function isCorrectObjectCheck(obj) {
  const values = Object.values(obj);
  const isNone = values.length == 0;

  if (isNone) {
    return false;
  }

  let isBooleanAllFalse = true;
  let isCorrectData = true;

  values.map((value) => {
    const valueType = typeof value;
    if (valueType == "boolean") {
      if (value) {
        isBooleanAllFalse = false;
        return;
      }
    }

    isBooleanAllFalse = false;

    if (value.length == 0) {
      isCorrectData = false;
    }
  });

  if (isBooleanAllFalse) {
    isCorrectData = false;
  }

  return isCorrectData;
}

function findRequiredElements(elements, requiredElementsIds) {
  elements.forEach((element) => {
    const { id, required, childrens } = element;
    const isRequired = required == 1;

    const isHidden = isDOMElementHidden(id);

    if (isRequired && !isHidden) {
      requiredElementsIds.push(id);
    }

    const findFromChildren = ({ elems }) => {
      findRequiredElements(elems, requiredElementsIds);
    };

    childrens.forEach(findFromChildren);
  });
}

function markElementBySystemName(userAnswers, systemNames) {
  const keys = Object.keys(userAnswers);
  return keys.map((elementId) => {
    const value = userAnswers[elementId];
    const { Title } = value;

    const systemNameIndex = systemNames.findIndex(
      ({ title }) => title == Title
    );

    if (systemNameIndex != -1) {
      userAnswers[elementId].system_name_id = systemNames[systemNameIndex].id;
    }
  });
}

function findElementById(elements, idForSearch) {
  let returnedElement = {};

  let wasFounded = false;
  elements.forEach((element) => {
    const { id, childrens } = element;

    if (wasFounded) {
      return;
    }

    if (id == idForSearch) {
      returnedElement = element;
      wasFounded = true;
      return;
    }

    const findFromChildren = ({ elems }) => {
      returnedElement = findElementById(elems, idForSearch);
    };

    childrens.forEach(findFromChildren);
  });
  return returnedElement;
}

function isDOMElementHidden(elementId) {
  const element = document.querySelector(`div[data-id="${elementId}"]`);
  if (element == null) return true;
  return false;
}

function elementsDOMIdsInRightOrder() {
  const elements = document.querySelectorAll(`.element`);

  const elementsOrder = {};

  elements.forEach((el, index) => {
    const { id } = el.dataset;

    elementsOrder[id] = index;
  });
  console.log(elementsOrder);
  return elementsOrder;
}

function saveElementsOrder(userAnswers) {
  const orderOfElements = elementsDOMIdsInRightOrder();
  console.log(userAnswers);

  Object.keys(orderOfElements).map((id) => {
    const index = orderOfElements[id];
    const answer = userAnswers[id];
    if (answer) {
      answer.orderIndex = index;
    }
  });
}

export default {
  getAnswerInfo,
  isRequiredFieldEmpty,
  markElementBySystemName,
  findElementById,
  saveElementsOrder,
};
