import React from "react";
import styled from "styled-components";
import { gray, green, black, accent } from "../../constants/Colours";
import check from "../../assets/svg/check-step.svg";
import { device } from "../../constants/Breakpoints";

const Status = styled.div`
  border: ${(props) => `solid 1px ${props.color}`};
  border-radius: 40px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: ${(props) => props.color};
`;

const Img = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Info = styled.div`
  color: ${gray};
`;

const StepForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  position: relative;
`;

function Step({ info = "", stepName = "", color = "" }) {
  const CheckStatus = () => {
    if (color == `${green}`) {
      return (
        <div>
          <Img>
            <img src={check} />
          </Img>
        </div>
      );
    } else {
      return <div>{stepName}</div>;
    }
  };

  return (
    <StepForm>
      <Status color={color}>
        <CheckStatus />
      </Status>
      <Info color={color}>{info}</Info>
    </StepForm>
  );
}

export default Step;
