const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const isValidEmailCheck: (email: string) => boolean = (email) =>
  email.match(emailReg) ? true : false;

export const isValidPhoneCheck: (phone: string) => boolean = (phone) =>
  phone.match(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/) || !phone
    ? true
    : false;

export const isValidDateCheck: (date: string) => boolean = (date) =>
  date.match("^([0-9]{2})\\.([0-9]{2})\\.([1-2][0-9]{3})$") || !date
    ? true
    : false;
