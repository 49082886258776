import React from "react";
import CustomerInfoTable from "../../../components/UI-kit/Table/CustomerInfoTable";
import Table from "../../../components/UI-kit/Table/Table";

type Props = {
  data: any;
  infoCustomer: object;
};

const OrderInfoSpecialistAdminTable: React.FC<Props> = ({
  data = {},
  infoCustomer = {},
}) => (
  <CustomerInfoTable>
    <tbody>
      {Object.entries(infoCustomer).map(([key, value]) =>
        value !== null && value !== "" && key !== "type" ? (
          <tr key={key}>
            <td className="gray right" style={{ paddingRight: 25 }}>
              {data[key]}
            </td>
            <td>{value}</td>
          </tr>
        ) : null
      )}
    </tbody>
  </CustomerInfoTable>
);

export default OrderInfoSpecialistAdminTable;
