import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Delivery } from "../../../api/methods";
import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../../components/UI-kit/FloatingInput";
import Text from "../../../components/UI-kit/Text";
import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const BoldText = styled.span`
  font-weight: bold;
`;

const DeletePopup = ({ name, id, getAllDelivery, setDelivery }) => {
  const dispatch = useDispatch();
  const { isShowDeleteDelivery } = useSelector(getIsShowPopups);

  const onClickYes = () => {
    deleteDelivery();
  };

  const deleteDelivery = () => {
    Delivery.delete({ id }, addDeliverySuccess);
  };

  const addDeliverySuccess = () => {
    setDelivery(-1);
    getAllDelivery();
    closePopup();
  };

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowDeleteDelivery", false));
  };

  const nameText = <BoldText>{name}</BoldText>;

  const buttons = (
    <ButtonsBlock>
      <Button onClick={onClickYes}>{T.yes}</Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert title={T.deleting} isShow={isShowDeleteDelivery} buttons={buttons}>
      <Text>
        {T.deleteDeliveryMethod}
        {nameText}?
      </Text>
    </Alert>
  );
};

export default DeletePopup;
