import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import { ultraLightgray } from "../../../constants/Colours";
import Table from "../Table/Table";

const CustomerMainTable = styled(Table)`
  @media ${device.tablet} {
    font-size: 14px !important;
    width: 100%;

    & thead {
      display: none;
    }

    & tr * {
      border: none;
      padding: 0;
    }

    & tr {
      display: flex;
      flex-direction: column-reverse;
      border-bottom: 1px solid ${ultraLightgray};
    }

    & .right {
      text-align: left;
    }
  }
`;

export default CustomerMainTable;
