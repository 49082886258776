import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Element } from "../../../../api/methods";
import { gray, lightgray, ultraLightgray } from "../../../../constants/Colours";
import { goToHref } from "../../../../functions/common";
import { typeName, typeTip } from "../../../../store/elements/actions";
import { setFavoriteElements } from "../../../../store/favoriteElements/actions";
import { getFavoriteElements } from "../../../../store/favoriteElements/selectors";
import { getPreviewMode } from "../../../../store/previewMode/selectors";
import SecondOrderButton from "../../../UI-kit/Buttons/SecondOrderButton";
import FloatingInput from "../../../UI-kit/FloatingInput";
import Subtitle from "../../../UI-kit/Subtitle";
import { TextEditor } from "../../../UI-kit/TextEditor/TextEditor";
import Title from "../../../UI-kit/Title";
import TopBar from "./TopBar/TopBar";

const Wrapper = styled.div`
  ${(props) =>
    props.isImportantInfo
      ? `
        padding: 20px 40px 40px 50px;
        border: 1px solid ${lightgray};
        ${Title}{
          max-width:fit-content;
        }
      `
      : ``}
`;

const CommonElementsPart = ({
  element,
  deleteElement,
  isLast,
  isFirst,
  swapNext,
  swapPrevious,
  systemNames,
  deleteFromFavorite,
  toggleFavorite,
  isElementFavorite,
  loadForm,
}) => {
  let { type, tip = "", name, href, required } = element;
  tip = tip == "null" ? "" : tip;

  const isHrefEmpty = href == "" || !href || href == null || href == "null";

  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);

  const loadFavoritesSuccess = ({ data }) => {
    dispatch(setFavoriteElements(data));
  };

  const loadFavorites = () => {
    Element.getAllFavorites({}, loadFavoritesSuccess);
  };

  const updateElement = () => {
    const {
      id,
      tip = "",
      name = "",
      href = "",
      href_name = "",
      required,
    } = element;
    Element.update(
      { id, name, tip, href, href_name, required },
      elementUpdateSuccess
    );
  };

  const elementUpdateSuccess = (res) => {
    loadFavorites();
  };

  const toggleRequired = () => {
    const { id } = element;
    const isRequired = !element.required ? 1 : 0;
    Element.update({ id, required: isRequired }, loadForm);
  };

  const makeSystemElement = (newSystemNameId) => {
    const { id } = element;
    console.log(newSystemNameId);
    Element.update({ id, system_name_id: newSystemNameId }, loadForm);
  };

  const handleChangeName = (e) =>
    dispatch(typeName(element.id, e.target.value));

  const handleChangeTip = (value) => {
    dispatch(typeTip(element.id, value));
    updateElement();
  };

  const handleDeleteElement = () => deleteElement(element.id);

  const isImportantInfo = type == 6;

  const isTipEmpty =
    element.tip == "null" || element.tip == "" || element.tip == null;

  const isHrefNameEmpty =
    element.href_name == "null" ||
    element.href_name == "" ||
    element.href_name == null;

  let elementTitle = name == "" ? "Отсутствует имя элемента" : name;

  if (required) {
    elementTitle += " *";
  }

  return (
    <Wrapper isImportantInfo={isImportantInfo}>
      <TopBar
        isFirst={isFirst}
        toggleRequired={toggleRequired}
        isLast={isLast}
        swapNext={swapNext}
        swapPrevious={swapPrevious}
        makeSystemElement={makeSystemElement}
        systemNames={systemNames}
        element={element}
        required={required}
        deleteElement={handleDeleteElement}
        type={type}
        isElementFavorite={isElementFavorite}
        toggleFavorite={toggleFavorite}
        deleteFromFavorite={deleteFromFavorite}
      />
      {/* {isPreviewMode ? null : (
        )} */}
      {isPreviewMode ? (
        isImportantInfo ? (
          <Title>{elementTitle}</Title>
        ) : (
          <Subtitle style={{ marginBottom: 7 }}>{elementTitle}</Subtitle>
        )
      ) : (
        <FloatingInput
          value={name}
          onChange={handleChangeName}
          onBlur={updateElement}
          placeholder={isImportantInfo ? "Заголовок" : "Вопрос"}
          style={{ fontWeight: "bold" }}
        />
      )}
      {isPreviewMode ? null : (
        <TextEditor
          onChange={handleChangeTip}
          placeholder={isImportantInfo ? "Содержание информации" : ""}
          element={element}
        />
      )}

      {isPreviewMode ? (
        <>
          {isHrefEmpty ? null : (
            <SecondOrderButton
              style={{ padding: 0, marginBottom: 10 }}
              onClick={() => goToHref(element.href)}
            >
              {isHrefNameEmpty ? "Дополнительные материалы" : element.href_name}
            </SecondOrderButton>
          )}

          {isTipEmpty ? null : (
            <TextEditor
              onChange={handleChangeTip}
              placeholder={isImportantInfo ? "Содержание информации" : ""}
              element={element}
            />
          )}
        </>
      ) : null}
    </Wrapper>
  );
};

export default CommonElementsPart;
