import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Promocode } from "../../api/methods";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import FloatingSelect from "../../components/UI-kit/FloatingSelect";
import Title from "../../components/UI-kit/Title";
import { T } from "../../lang/ru";
import { setIsShowPopup } from "../../store/popups/actions";
import AddPopup from "./EditorPromocodes/AddPopup";
import DeletePopup from "./EditorPromocodes/DeletePopup";

const EditorPromocodes = () => {
  const dispatch = useDispatch();
  const [promocode, setPromocode] = useState(-1);
  const [allPromocodes, setAllPromocodes] = useState([]);
  const [selectPromocode, setSelectPromocode] = useState(0);

  useEffect(() => {
    getAllPromocodes();
  }, []);

  const getAllPromocodes = () => {
    Promocode.getAll({}, getAllPromocodesSuccess);
  };

  const getAllPromocodesSuccess = ({ data }) => {
    setAllPromocodes(data);
  };

  const onChangeSelect = (e) => {
    setPromocode(e.target.value);
    setSelectPromocode(
      allPromocodes.findIndex(
        (item) => Number(item.id) === Number(e.target.value)
      )
    );
  };

  const onClickDelete = () => {
    dispatch(setIsShowPopup("isShowDeletePromocode", true));
  };

  const onClickAdd = () => {
    dispatch(setIsShowPopup("isShowAddPromocode", true));
  };

  return (
    <>
      <Title>{T.editPromocodes}</Title>
      <FloatingSelect
        value={promocode}
        title={T.promo}
        name="promocode"
        onChange={onChangeSelect}
        options={allPromocodes}
      ></FloatingSelect>
      <Button margin={"10px 15px 10px 0"} onClick={onClickAdd}>
        {T.addNewPromo}
      </Button>
      {promocode !== -1 ? (
        <>
          <SecondOrderButton
            marginLeft
            className="delete-button"
            onClick={onClickDelete}
          >
            {T.deleteSelectPromo}
          </SecondOrderButton>
        </>
      ) : (
        <></>
      )}
      <AddPopup getAllPromocodes={getAllPromocodes}></AddPopup>
      <DeletePopup
        name={allPromocodes[selectPromocode]?.name}
        id={promocode}
        getAllPromocodes={getAllPromocodes}
        setPromocode={setPromocode}
      ></DeletePopup>
    </>
  );
};

export default EditorPromocodes;
