import React from "react";
import styled from "styled-components";
import Subtitle from "../../../components/UI-kit/Subtitle";
import Text from "../../../components/UI-kit/Text";
import { gray } from "../../../constants/Colours";

const ImageBlock = styled.img`
  max-height: 50px;
  max-width: 50px;
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  transition: border-radius 200ms linear;
  &:hover {
    border-radius: 12px;
  }
`;

const ImagesContainer = styled.div`
  display: grid;
  max-width: 240px;
  margin: 7px 0 0 0;
  gap: 1rem;
  grid-template-columns: 50px 50px 50px 50px;
  grid-template-rows: 50px 50px;
  gap: 10px;
  margin-right: 10px;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;
  & > ${Subtitle} {
    margin-top: 8px;
    line-height: 110%;
  }
  & > ${Text} {
    max-width: 140px;
    margin-bottom: 0;
    line-height: 110%;
  }
`;

const ApplicationContainer = styled.div`
  margin: 0 0 23px 0;
  display: flex;
`;
type App = {
  src: string;
  img: string;
};
type Apps = App[];

const Applications = () => {
  const apps: Apps = [
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
    {
      src: "https://play.google.com/store/apps/details?id=ru.otb37.ab&hl=ru",
      img:
        "https://lh3.googleusercontent.com/9ABsr2vq9RRaXw6ydGJvqf2RolomgPzog4-Vi_YhRaN0XGx1-ixNSHl-BCtR0SjIlw=s360",
    },
  ];

  const goToApp = (src: string) => {
    window.open(src);
  };

  return (
    <>
      <ApplicationContainer>
        <ImagesContainer>
          {apps.map(({ src, img }, index) => (
            <ImageBlock onClick={() => goToApp(src)} key={index} src={img} />
          ))}
        </ImagesContainer>
        <TextContainer>
          <Subtitle>Бесплатные приложения</Subtitle>
          <Text color={gray}>Только для наших клиентов</Text>
        </TextContainer>
      </ApplicationContainer>
    </>
  );
};

export default Applications;
