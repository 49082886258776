import { APP_STATUSES } from "../lang/ru";

type ObjectWithKey = {
  key: string;
};
type Result = {
  [key: string]: string;
};
export const formDataFromInputInfoForState: (
  fields: ObjectWithKey[]
) => Result = (fields) => {
  let data: Result = {};
  fields.map(({ key }) => (data[key] = ""));
  return data;
};
export const getStatus = () => {
  let statusIndex: string | number | null = localStorage.getItem("status");

  if (statusIndex === null) {
    return "";
  }

  statusIndex = parseInt(statusIndex) - 1;
  const STATUSES: ["CUSTOMER", "SPECIALIST", "ADMIN"] = [
    "CUSTOMER",
    "SPECIALIST",
    "ADMIN",
  ];
  return STATUSES[statusIndex];
};
export const goToHref = (href: string) => window.open(href);

export const checkFullnessFields = (array: []) => {
  for (const item of array) {
    if (!item) return true;
  }
  return false;
};

export const getStatusString = (statusId: number) => {
  return APP_STATUSES.find((status) => status.value == statusId)?.label;
};
export const dateTranslate = (date: any) => {
  if (!date) return "";
  date = date !== undefined ? date.split(" ")[0] : "10.10.2000";
  date = new Date(Date.parse(date));
  date = date.toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return date;
};
export const dateTranslateToApi = (date: any) => {
  date = date.split(".").reverse().join("-");
  return date;
};
export const getApplicationStatus = (status_id: number) => {
  return APP_STATUSES.filter(({ value }) => value == status_id);
};

export function debounce(f: any, ms: number) {
  let isCooldown = false;

  return function (this: any) {
    if (isCooldown) return;

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);
  };
}
