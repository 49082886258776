import React, { useState, useEffect } from "react";

import FloatingInput from "../../../components/UI-kit/FloatingInput";
import FloatingSelect from "../../../components/UI-kit/FloatingSelect";
import Subtitle2 from "../../../components/UI-kit/Subtitle2";
import { Col, Row } from "styled-bootstrap-grid";
import InputCheckbox from "../../../components/UI-kit/InputCheckbox";

import { T } from "../../../lang/ru";
import {
  isValidEmailCheck,
  isValidPhoneCheck,
} from "../../../functions/validators";
import { checkFullnessFields } from "../../../functions/common";

const IndividualForm = ({
  setInputsValue,
  inputsValue = {},
  setIsDisabled,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [isValid, setIsValid] = useState({
    inn: false,
    kpp: false,
    bik_bank: false,
    ras: false,
    kor: false,
  });

  const checkValid = (e) => {
    const { value, name } = e.target;
    setIsValid({ ...isValid, [name]: validators[name](value) });
  };
  const validators = {
    inn: (value) => !value.match("^([0-9]{10,12})$") || !value,
    kpp: (value) => !value.match("^([0-9]{9})$") || !value,
    bik_bank: (value) => !value.match("^([0-9]{9})$") || !value,
    ras: (value) => !value.match("^([0-9]{20})$") || !value,
    kor: (value) => !value.match("^([0-9]{20})$") || !value,
  };

  const {
    name_org,
    inn,
    kpp,
    bank,
    bik_bank,
    ras,
    phone,
    email,
    name,
    surname,
    middlename,
    legal_adress,
    kor,
    base,
    position,
    deliveryAddressMatches,
  } = inputsValue;

  const requiredFields = [
    name_org,
    inn,
    kpp,
    bank,
    bik_bank,
    ras,
    isValidPhone,
    phone,
    isValidEmail,
    email,
    name,
    surname,
    middlename,
  ];

  useEffect(
    () => setIsDisabled(checkFullnessFields(requiredFields)),
    requiredFields
  );

  const onChangeInput = (e) => {
    let { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      value = checked;
    }
    setInputsValue({ ...inputsValue, [name]: value });
  };

  const checkEmailValid = (e) => {
    setIsValidEmail(isValidEmailCheck(e.target.value));
  };

  const checkPhoneValid = (e) => {
    setIsValidPhone(isValidPhoneCheck(e.target.value));
  };

  return (
    <>
      <Subtitle2 style={{ marginTop: 30 }}>
        {T.enterOrganizatioInformation}
      </Subtitle2>
      <Row>
        <Col>
          <FloatingInput
            name="name_org"
            placeholder={T.nameOrganization}
            required={true}
            onChange={onChangeInput}
            value={name_org}
          />
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="legal_adress"
            placeholder={T.legalAdress}
            onChange={onChangeInput}
            value={legal_adress}
          ></FloatingInput>
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="inn"
            onBlur={checkValid}
            wrong={isValid.inn}
            wrongMessage={"Формат (Например, 998877777766)"}
            placeholder={T.INN}
            required
            onChange={onChangeInput}
            value={inn}
          />
        </Col>
        <Col col="auto">
          <FloatingInput
            name="kor"
            onBlur={checkValid}
            wrong={isValid.kor}
            wrongMessage={"Формат (Например, 30101643600000000957)"}
            placeholder={T.corNumber}
            onChange={onChangeInput}
            value={kor}
          />
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="kpp"
            onBlur={checkValid}
            wrong={isValid.kpp}
            wrongMessage={"Формат (Например, 773101001)"}
            placeholder={T.KPP}
            required
            onChange={onChangeInput}
            value={kpp}
          />
        </Col>
        <Col col="auto">
          <FloatingInput
            name="ras"
            onBlur={checkValid}
            wrong={isValid.ras}
            wrongMessage={"Формат (Например, 40817810099910004312)"}
            placeholder={T.raschNumber}
            required
            onChange={onChangeInput}
            value={ras}
          />
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="bank"
            placeholder={T.bank}
            required
            onChange={onChangeInput}
            value={bank}
          />
        </Col>
        <Col col="auto">
          <FloatingInput
            name="phone"
            placeholder={T.phone}
            required
            onChange={onChangeInput}
            value={phone}
            wrong={!isValidPhone}
            onBlur={checkPhoneValid}
            wrongMessage={T.inCorrectPhone}
          />
        </Col>
      </Row>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="bik_bank"
            onBlur={checkValid}
            wrong={isValid.bik_bank}
            wrongMessage={"Формат (Например, 044525957)"}
            placeholder={T.bikBank}
            required
            onChange={onChangeInput}
            value={bik_bank}
          />
        </Col>
        <Col col="auto">
          <FloatingInput
            name="email"
            placeholder={T.email}
            required
            onChange={onChangeInput}
            value={email}
            wrong={!isValidEmail}
            wrongMessage={T.inCorrectEmail}
            onBlur={checkEmailValid}
          />
        </Col>
      </Row>
      <Subtitle2 style={{ marginTop: 40 }}>
        {T.enterLeaderInformation}
      </Subtitle2>
      <Row>
        <Col col="auto">
          <FloatingInput
            name="position"
            placeholder={T.position}
            onChange={onChangeInput}
            value={position}
          />
        </Col>
        <Col col="auto">
          <FloatingInput
            name="base"
            placeholder={T.actingOnTheBasis}
            onChange={onChangeInput}
            value={base}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingInput
            name="surname"
            placeholder={T.surname}
            required
            onChange={onChangeInput}
            value={surname}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingInput
            name="name"
            placeholder={T.name}
            required
            onChange={onChangeInput}
            value={name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingInput
            name="middlename"
            placeholder={T.middleName}
            required
            onChange={onChangeInput}
            value={middlename}
          />
        </Col>
      </Row>
    </>
  );
};

export default IndividualForm;
