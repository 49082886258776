import { type } from "os";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Chain, Form } from "../../../api/methods";
import { gray, lightgray, ultraLightgray } from "../../../constants/Colours";
import { key_divider } from "../../../constants/elements";
import questionnaireFunc from "../../../functions/questionnaireFunc";
import {
  setElements,
  setRepeatChildrenCountToElement,
} from "../../../store/elements/actions";
import { getPreviewMode } from "../../../store/previewMode/selectors";
import { getUserAnswers } from "../../../store/userAnswers/selectors";
import Button from "../../UI-kit/Buttons/Button";
import SecondOrderButton from "../../UI-kit/Buttons/SecondOrderButton";
import Subtitle from "../../UI-kit/Subtitle";
import Subtitle2 from "../../UI-kit/Subtitle2";
import CreateButtonsBlock from "./CreateButtonsBlock/CreateButtonsBlock";
import MainElement from "./Elements/MainElement";

const PullWrapper = styled.div``;

const ChildPullWrapper = styled.div`
  padding: ${(props) => (props.isPreviewMode ? "0px" : "0 40px")};
  border: ${(props) => (props.isPreviewMode ? "" : `1px solid ${lightgray}`)};
  background-color: ${(props) => (props.isPreviewMode ? "" : "#eeeeee88")};
  margin-left: ${(props) => (props.isPreviewMode ? "70px" : "0")}; ;
`;

const ElementWrapper = styled.div``;

const PullElements = ({ elements = [], systemNames = [] }) => {
  const form_id = document.location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);

  const addElement = (type_id, parent_id, condition, order_index) => {
    Chain.add(
      { type: type_id, form_id, parent_id, condition, tip: "", order_index },
      addElementSuccess
    );
  };

  const addElementSuccess = () => loadForm(form_id);

  const loadForm = (id) => Form.getById({ id }, successLoadForm);

  const successLoadForm = ({ data }) => dispatch(setElements(data));

  const isShowChild = (element, child) => {
    const { id, type } = element;
    const key = id;
    const nameForShowing = child.name;

    if (!isPreviewMode) return true;

    if (type == 5 || type == 9) return true;

    return isUserAnswerAcceptString(userAnswers[key], nameForShowing);
  };

  const isUserAnswerAcceptString = (userAnswer = {}, string) => {
    let isContain = false;

    const isAnswerUndefined = typeof userAnswer?.answer == "undefined";

    if (isAnswerUndefined) {
      return false;
    }

    const isUserAnswerString = typeof userAnswer?.answer == "string";

    if (isUserAnswerString) {
      return userAnswer.answer?.includes(string);
    }

    Object.keys(userAnswer?.answer).map((key) => {
      if (userAnswer?.answer[key] == true && key == string) isContain = true;
    });

    return isContain;
  };

  const filterArrayByDuplicatesIds = (arr) =>
    arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const filteredStruct = (element) =>
    filterArrayByDuplicatesIds(element.struct);

  const renderChildrenOfElement = (element, structId, structName) => {
    const { childrens } = element;
    const filteredChildren = childrens.filter((child) => child.id == structId);
    return filteredChildren.map((child) => {
      const isContainChildren = child.elems?.length != 0;
      const isShow = isShowChild(element, child) || !isPreviewMode;

      if (!isContainChildren) {
        return null;
      }

      if (isShow) {
        return <PullElements elements={child.elems} />;
      }
    });
  };

  const swapElements = (currentId, neighborId) =>
    Form.swap({ id1: currentId, id2: neighborId, form: form_id }, () =>
      loadForm(form_id)
    );

  const getElementIdByIndex = (index) => elements[index].id;

  const swapPrevious = (currentId, currentIndex, isFirst) => {
    if (isFirst) return;
    const neighborId = getElementIdByIndex(currentIndex - 1);
    swapElements(currentId, neighborId);
  };
  const swapNext = (currentId, currentIndex, isLast) => {
    if (isLast) return;
    const neighborId = getElementIdByIndex(currentIndex + 1);
    swapElements(currentId, neighborId);
  };

  const getCountOfChildrenByStructId = (element, structId) => {
    const { childrens = [] } = element;
    const child = childrens.filter((child) => child.id == structId)[0];

    const { elems } = child;

    if (elems.length == 0) return 0;

    const { order_index } = elems.pop();

    return order_index + 1;
  };

  const duplicateElementChildrenPool = (elementId, currentRepeat) => {
    if (!currentRepeat) {
      currentRepeat = 1;
    }
    dispatch(setRepeatChildrenCountToElement(elementId, currentRepeat + 1));
  };

  return (
    <PullWrapper>
      {elements.map((element, index) => {
        const isLast = index == elements.length - 1;
        const isFirst = index == 0;

        const { type, id, childrens, repeat } = element;

        const groupTitle = `Добавить вопросы к группе «${element.name}»`;
        const superComplexListTitle = `Добавить вопросы к супер-сложному списку «${element.name}»`;

        return (
          <ElementWrapper key={id}>
            <MainElement
              isFirst={isFirst}
              systemNames={systemNames}
              isLast={isLast}
              swapNext={() => swapNext(id, index, isLast)}
              swapPrevious={() => swapPrevious(id, index, isFirst)}
              element={element}
            ></MainElement>
            {isPreviewMode || type == 8
              ? null
              : filteredStruct(element).map(({ name, id }) => (
                  <ChildPullWrapper key={id}>
                    <Subtitle2 style={{ margin: 0 }}>
                      {type == 5
                        ? groupTitle
                        : type == 9
                        ? superComplexListTitle
                        : `Добавить вопросы к варианту ответа «${element.name}» на вопрос «${name}»`}
                    </Subtitle2>
                    <CreateButtonsBlock
                      addElement={(type_id) =>
                        addElement(
                          type_id,
                          element.id,
                          id,
                          getCountOfChildrenByStructId(element, id)
                        )
                      }
                    />
                    {renderChildrenOfElement(element, id, name)}
                  </ChildPullWrapper>
                ))}

            {isPreviewMode
              ? childrens.map((child) => {
                  const isEmptyElems = child.elems?.length == 0;

                  if (isEmptyElems) {
                    return null;
                  }

                  const isSuperComplextList = type == 9;
                  const isGroup = type == 5;

                  const isChangeStyleChildWrapper =
                    !isGroup && !isSuperComplextList;

                  const isShowChildren = isShowChild(element, child);

                  const Children = isShowChildren ? (
                    <PullElements elements={child.elems} />
                  ) : null;

                  const duplicate = () =>
                    duplicateElementChildrenPool(id, element.repeat);

                  return (
                    <ChildPullWrapper isPreviewMode={isChangeStyleChildWrapper}>
                      {Children}
                      {isSuperComplextList ? (
                        <Button
                          style={{ marginBottom: 40 }}
                          onClick={duplicate}
                        >
                          {element.button_name
                            ? element.button_name
                            : "Добавить группу ответов"}
                        </Button>
                      ) : null}
                    </ChildPullWrapper>
                  );
                })
              : null}
          </ElementWrapper>
        );
      })}
    </PullWrapper>
  );
};

export default PullElements;
