import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Title from "../../components/UI-kit/Title";
import Subtitle2 from "../../components/UI-kit/Subtitle2";
import Radio from "../../components/UI-kit/Radio";
import IndividualForm from "./AddNewCustomerComponents/IndividualForm";
import EntityForm from "./AddNewCustomerComponents/EntityForm";
import IeForm from "./AddNewCustomerComponents/IeForm";
import Button from "../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import { Row, Col } from "styled-bootstrap-grid";

import { T } from "../../lang/ru";
import { PATHS } from "../../constants/RoutesPaths";
import { Payers } from "../../api/methods";
import { CheckBoxLabel } from "../../components/UI-kit/CheckBoxLabel";
import Checkbox from "../../components/UI-kit/Checkbox";
import { LinkButton } from "../../components/UI-kit/Buttons/LinkButton";
import hooks from "../../functions/hooks";

const AddNewCustomer: React.FC = () => {
  const history = useHistory();
  const [formName, setFormName] = useState<string>("1");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [isAgree, switchIsAgree] = hooks.useCheckbox();

  const [individualInputs, setIndividualInputs] = useState<object>({
    type: "1",
    surname: "",
    adress: "",
    name: "",
    phone: "",
    middlename: "",
    email: "",
    inn: "",
    serial: "",
    number: "",
    who_give: "",
    date_get: "",
  });
  const [entityInputs, setEntityInputs] = useState<object>({
    type: "2",
    name_org: "",
    legal_adress: "",
    deliveryAddressMatches: false,
    inn: "",
    kor: "",
    kpp: "",
    ras: "",
    bank: "",
    phone: "",
    bik_bank: "",
    email: "",
    position: "",
    base: "",
    surname: "",
    name: "",
    middlename: "",
  });
  const [ieInputs, setIeInputs] = useState<object>({
    type: "3",
    name_ip: "",
    legal_adress: "",
    deliveryAddressMatches: false,
    inn: "",
    kor: "",
    ogrn: "",
    ras: "",
    bank: "",
    phone: "",
    bik_bank: "",
    email: "",
    surname: "",
    name: "",
    middlename: "",
  });

  const onClickSave: () => void = () => {
    addNewPayer();
  };

  const addNewPayer: () => void = () => {
    const newPayer =
      formName === "1"
        ? individualInputs
        : formName === "2"
        ? entityInputs
        : ieInputs;
    Payers.add({ ...newPayer }, goToRequestCustomers);
  };

  const goToRequestCustomers: () => void = () => {
    history.push(PATHS.customer.requestCustomers);
  };

  const isPhysicalPerson = formName == "1";
  const isDisabledNext = isDisabled || (!isAgree && isPhysicalPerson);

  return (
    <>
      <Title>{T.addNewCustomer}</Title>
      <Subtitle2>{T.addNewCustomerText}</Subtitle2>
      <Radio
        radios={T.addNewCustomerRadio}
        nameRadio="сustomer"
        setValue={setFormName}
        radioValue={formName}
      />
      {isPhysicalPerson ? (
        <IndividualForm
          setInputsValue={setIndividualInputs}
          inputsValue={individualInputs}
          setIsDisabled={setIsDisabled}
        />
      ) : formName === "2" ? (
        <EntityForm
          setInputsValue={setEntityInputs}
          inputsValue={entityInputs}
          setIsDisabled={setIsDisabled}
        />
      ) : (
        <IeForm
          setInputsValue={setIeInputs}
          inputsValue={ieInputs}
          setIsDisabled={setIsDisabled}
        />
      )}
      {isPhysicalPerson ? (
        <div style={{ margin: "13px 0" }}>
          <CheckBoxLabel>
            <Checkbox
              className="isAgree"
              name="isAgree"
              checked={isAgree}
              onChange={switchIsAgree}
            />
            <span style={{ marginLeft: 13 }}>
              Соглашаюсь на обработку
              <LinkButton
                target={"_blank"}
                style={{ display: "inline-flex" }}
                to={PATHS.agreement}
              >
                персональных данных
              </LinkButton>
            </span>
          </CheckBoxLabel>
        </div>
      ) : null}

      <Row>
        <Col>
          <Button onClick={onClickSave} disabled={isDisabledNext}>
            {T.saveAndContinue}
          </Button>
          <SecondOrderButton onClick={goToRequestCustomers} marginLeft>
            {T.back}
          </SecondOrderButton>
        </Col>
      </Row>
    </>
  );
};

export default AddNewCustomer;
