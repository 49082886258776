import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row } from "styled-bootstrap-grid";
import Button from "../../components/UI-kit/Buttons/Button";
import ButtonGroup from "../../components/UI-kit/Buttons/ButtonGroup";
import Title from "../../components/UI-kit/Title";
import OrdersRegistry from "./OrdersRegistry";
import ExecutorsList from "./ExecutorsList";
import CustomersList from "./CustomersList";

import { T } from "../../lang/ru";
import { getActiveTab } from "../../store/activeTab/selectors";
import { setActiveTabAdmin } from "../../store/activeTab/actions";

const OrdersHistory: React.FC = () => {
  const tabs = [T.registerOfOrders, T.listOfPerformers, T.listOfCustomers];
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);

  const onChangeTab: (numTab: number) => void = (numTab) => {
    dispatch(setActiveTabAdmin(numTab));
  };

  return (
    <>
      <Title>{T.administratorPersonalAccount}</Title>
      <Container>
        <Row>
          <ButtonGroup>
            {tabs.map((tab, index) => (
              <Button
                onClick={() => onChangeTab(index)}
                key={index}
                className={index === activeTab ? "active" : ""}
              >
                {tab}
              </Button>
            ))}
          </ButtonGroup>
        </Row>
      </Container>
      {activeTab === 0 ? (
        <OrdersRegistry></OrdersRegistry>
      ) : activeTab === 1 ? (
        <ExecutorsList></ExecutorsList>
      ) : (
        <CustomersList></CustomersList>
      )}
    </>
  );
};

export default OrdersHistory;
