import React from "react";
import styled from "styled-components";

import { accent, lightgray } from "../../constants/Colours";
import check from "../../assets/svg/checkbox.svg";
import { device } from "../../constants/Breakpoints";

const Input = styled.input`
  appearance: none;
  margin: 0;
  position: absolute;
`;

type LabelProps = {
  checked: boolean;
};

const Label = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  height: 21px;
  font-size: 16px;
  vertical-align: middle;
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 23px;
    height: 23px;
    border: 1px solid ${lightgray};
    border-radius: 4px;
    transition: all 0.2s linear;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: ${(props: LabelProps) =>
      props.checked ? `url(${check})` : "none"};
  }
  @media ${device.laptop} {
    margin: 15px 0;
    padding-left: 35px;
  }
  @media ${device.tablet} {
    max-width: 300px;
    margin: 20px 0;
  }
`;

type InputCheckbox = {
  placeholder?: string;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  checked: boolean;
  name?: string;
};

const InputCheckbox: React.FC<InputCheckbox> = ({
  placeholder = "",
  onClick,
  checked = false,
  name = "",
}) => (
  <Label checked={checked}>
    <Input type="checkbox" onClick={onClick} checked={checked} name={name} />
    <span>{placeholder}</span>
  </Label>
);

export default InputCheckbox;
