const DEFUALT_PATH = "/";

export const PATHS = {
  defualt: DEFUALT_PATH,
  login: "/auth",
  register: "/register",
  agreement: "/agreement",
  registerSuccess: "/register-success",
  customer: {
    ordersHistory: DEFUALT_PATH,
    usefulMaterials: "/materials",
    orderInfo: "/order/info/:id",
    orderChat: "/order/chat/:id",
    orderObject: "/order/object/:id",
    customerInfo: "/customer-info/:id/:application_id?",
    addNewCustomer: "/request/customer/add",
    requestCustomers: "/request/customer",
    requestCategories: "/request/categories/:payer_id",
    requestPassing: "/request/passing/:id/:payer_id/:application_id",
    requestDelivery: "/request/delivery/:id/:payer_id/:application_id",
    requestSteps: "/request",
    requestForms: "/request/forms/:id/:payer_id",
  },
  specialist: {
    orders: DEFUALT_PATH,
    orderInfo: "/order/info/:id",
    orderChat: "/order/chat/:id",
    orderObject: "/order/object/:id",
  },
  admin: {
    ordersHistory: DEFUALT_PATH,
    customersList: "/customers",
    executorsList: "/executors",
    registry: "/registry",
    companyInfo: "/company-info",
    customerInfo: "/customer-info/:id/:application_id?",
    addNewExecutor: "/add-executor",
    executorInfo: "/executor/:id",
    orderInfo: "/order/info/:id",
    orderChat: "/order/chat/:id",
    orderObject: "/order/object/:id",
    changeAccount: "/account-change",
    orderDelivery: "/order/delivery/:id",
    editorCategories: "/editor/categories",
    editorSources: "/editor/sources",
    editorDeliveries: "/editor/deliveries",
    editorPromocodes: "/editor/promocodes",
    editorCreating: "/editor/creating/:id",
    editorPreview: "/editor/preview/:id",
  },
};
