import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import { PATHS } from "../../../constants/RoutesPaths";
import { T } from "../../../lang/ru";

const BackButton = () => {
  const history = useHistory();
  const goToMainScreen = () => {
    history.push(PATHS.customer.ordersHistory);
  };
  return (
    <SecondOrderButton onClick={goToMainScreen} marginRight>
      {T.back}
    </SecondOrderButton>
  );
};

export default BackButton;
