import React, { useEffect, useState } from "react";
import Step from "./Step";
import styled from "styled-components";
import { lightgray, accent, green } from "../../constants/Colours";
import { Container } from "styled-bootstrap-grid";
import { useSelector } from "react-redux";
import { getActiveStep } from "../../store/activeStep/selectors";
import { device } from "../../constants/Breakpoints";

const StepRow = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;

  @media ${device.tablet} {
    display: none;
  }
`;

const StepBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    display: none;
  }
`;

const StepLine = styled.div`
  position: absolute;
  width: 70%;
  height: 0.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${lightgray};
  color: white;
  top: 32%;
`;

function Steps() {
  const stepNum = useSelector(getActiveStep);
  const [firstStep, newFirstStep] = useState(`${accent}`);
  const [secondStep, newSecondStep] = useState(`${lightgray}`);
  const [thirdStep, newThirdStep] = useState(`${lightgray}`);

  useEffect(() => {
    StepChange(stepNum);
  }, [stepNum]);

  const StepChange = (stepNum) => {
    switch (stepNum) {
      case 1: {
        newFirstStep(`${accent}`);
        newSecondStep(`${lightgray}`);
        newThirdStep(`${lightgray}`);
        break;
      }
      case 2: {
        newFirstStep(`${green}`);
        newSecondStep(`${accent}`);
        newThirdStep(`${lightgray}`);
        break;
      }
      case 3: {
        newFirstStep(`${green}`);
        newSecondStep(`${green}`);
        newThirdStep(`${accent}`);
        break;
      }
      case 4: {
        newFirstStep(`${green}`);
        newSecondStep(`${green}`);
        newThirdStep(`${green}`);
        break;
      }
    }
  };

  return (
    <StepBlock>
      <StepRow>
        <StepLine />
        <Step info="Выбор заказщика" stepName={1} color={firstStep} />
        <Step info="Данные по объекту" stepName={2} color={secondStep} />
        <Step info="Доставка" stepName={3} color={thirdStep} />
      </StepRow>
    </StepBlock>
  );
}

export default Steps;
