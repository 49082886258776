import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Source } from "../../../api/methods";

import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import Text from "../../../components/UI-kit/Text";

import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const BoldText = styled.span`
  font-weight: bold;
`;

const DeleteMessage = ({ id, onClickOk }) => {
  const dispatch = useDispatch();
  const { isShowDeleteMessage } = useSelector(getIsShowPopups);

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowDeleteMessage", false));
  };

  const buttons = (
    <ButtonsBlock>
      <Button onClick={() => onClickOk(id)}>{T.yes}</Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert title={T.deleting} isShow={isShowDeleteMessage} buttons={buttons}>
      <Text>{T.deleteMessage}</Text>
    </Alert>
  );
};

export default DeleteMessage;
