import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import { accent, white } from "../../../constants/Colours";

const MobileButton = styled.button`
  position: fixed;
  display: none;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 45px;
  border: none;
  background-color: ${accent};
  font-size: 14px;
  color: ${white};
  z-index: 500;
  @media ${device.tablet} {
    display: block;
  }
`;

export default MobileButton;
