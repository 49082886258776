import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Element } from "../../../api/methods";
import { gray, lightgray, ultraLightgray } from "../../../constants/Colours";
import { setFavoriteElements } from "../../../store/favoriteElements/actions";
import { getFavoriteElements } from "../../../store/favoriteElements/selectors";
import SecondOrderButton from "../../UI-kit/Buttons/SecondOrderButton";
import Text from "../../UI-kit/Text";

const PanelWrapper = styled.div`
  position: fixed;
  left: 0;
  background-color: white;
  //   border: 1px solid ${ultraLightgray};
  border-radius: 0 4px 4px 0;
  padding: 20px 0 30px 0;
  z-index: 1000;
  width: 250px;
  transition: all 300ms linear;
  -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  ${(props) =>
    props.isShowContent
      ? `
      max-height:600px;  
      height:600px;  
      overflow:auto;
      `
      : `
      max-height:15px;  
      overflow:hidden;
  `}
`;

const FavoriteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  &:nth-child(2n) {
    background-color: ${ultraLightgray};
  }
  &:hover {
    background-color: ##f5f5f5;
  }
  ${SecondOrderButton} {
    text-align: left;
  }
`;

const ToggleFavoriteButton = styled(Text)`
  cursor: pointer;
  padding: 0 20px;
  &:hover {
    color: #737373;
  }
`;

export const FavoritesPanel = ({
  addElementFromFavorite,
  deleteFromFavorite,
}) => {
  const dispatch = useDispatch();
  const favoriteElements = useSelector(getFavoriteElements);
  const [isShowContent, setIsShowContent] = useState(true);
  useEffect(() => {
    loadFavorites();
  }, []);

  const loadFavoritesSuccess = ({ data }) => {
    dispatch(setFavoriteElements(data));
  };

  const loadFavorites = () => {
    Element.getAllFavorites({}, loadFavoritesSuccess);
  };

  const toggleIsShowContent = () => setIsShowContent(!isShowContent);

  const handleDeleteFromFavorite = (element) => {
    const isWantToDelete = window.confirm(
      "Вы действительно хотите удалить элемент из избранного?"
    );
    if (isWantToDelete) deleteFromFavorite(element);
  };

  return (
    <PanelWrapper isShowContent={isShowContent}>
      <ToggleFavoriteButton onClick={toggleIsShowContent}>
        {isShowContent ? "Скрыть избранное" : "Показать избранное"}
      </ToggleFavoriteButton>
      {favoriteElements.map((element) => (
        <FavoriteItem>
          <SecondOrderButton onClick={() => addElementFromFavorite(element)}>
            {element.name}
          </SecondOrderButton>
          <SecondOrderButton onClick={() => handleDeleteFromFavorite(element)}>
            &times;
          </SecondOrderButton>
        </FavoriteItem>
      ))}
    </PanelWrapper>
  );
};
