import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "../../api/methods";
import Button from "../../components/UI-kit/Buttons/Button";
import { LinkButton } from "../../components/UI-kit/Buttons/LinkButton";
import Checkbox from "../../components/UI-kit/Checkbox";
import { CheckBoxLabel } from "../../components/UI-kit/CheckBoxLabel";
import FloatingInput from "../../components/UI-kit/FloatingInput";
import Text from "../../components/UI-kit/Text";
import Title from "../../components/UI-kit/Title";
import { gray } from "../../constants/Colours";
import { INPUT_CONFIGS } from "../../constants/InputConfigs";
import { PATHS } from "../../constants/RoutesPaths";
import { formDataFromInputInfoForState } from "../../functions/common";
import hooks from "../../functions/hooks";
import { isValidEmailCheck } from "../../functions/validators";
import { T } from "../../lang/ru";
import { setIsHiddenMobileButton } from "../../store/isHiddenMobileButton/action";

const Register: React.FC = () => {
  const history = useHistory();
  const inititialData = formDataFromInputInfoForState(INPUT_CONFIGS.register);
  const [data, changeDataField] = useState(inititialData);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const [isAgree, switchIsAgree] = hooks.useCheckbox();

  useEffect(() => {
    dispatch(setIsHiddenMobileButton(false));
  }, []);

  const onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e
  ) => {
    const { value, name } = e.target;
    changeDataField({ ...data, [name]: value });
  };

  const [isValidEmail, setIsValidEmail] = useState(true);
  const checkEmailValid: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e
  ) => {
    setError("");
    setIsValidEmail(isValidEmailCheck(e.target.value));
  };

  const registerUser: () => void = () =>
    Auth.register(
      {
        name: data.name,
        surname: data.surname,
        middlename: data.middlename,
        phone: data.phone,
        email: data.email,
        password: data.password,
      },
      goToRegisterSuccess,
      handleRegisterError
    );

  const goToRegisterSuccess = () => history.push(PATHS.registerSuccess);

  const handleRegisterError = (err: string) => {
    const isEmailError = err.toString().includes("400");

    if (isEmailError) {
      showRepeatedEmailMessage();
    }
  };

  const showRepeatedEmailMessage = () => {
    setError("Пользователь с таким Email уже существует!");
  };

  const checkEmptyFields = (data: any) => {
    let isRequiredEmpty = false;

    const requiredKeys = INPUT_CONFIGS.register.map(({ required, key }) => {
      if (required) return key;
    });

    requiredKeys.map((key) => {
      if (!key) {
        return;
      }

      const isEmpty = data[key] == "";

      if (isEmpty) {
        isRequiredEmpty = true;
      }
    });
    return isRequiredEmpty;
  };

  const isWrongPassword = data["password"] !== data["passwordRepeate"];
  const isWrongEmail = !isValidEmail || error.length > 0;

  const isEmptyFieldsData = checkEmptyFields(data);
  const isDisableRegisterButton =
    isEmptyFieldsData || isWrongEmail || isWrongPassword || !isAgree;

  return (
    <>
      <Title>{T.registerOfAnApplication}</Title>
      <Text>{T.registerInfo}</Text>
      <Text color={gray}>{T.registerTooltip}</Text>
      <FloatingInput
        key={"surname"}
        name={"surname"}
        value={data["surname"]}
        onChange={onChangeHandler}
        placeholder={"Фамилия"}
        required={true}
      />
      <FloatingInput
        key={"name"}
        name={"name"}
        value={data["name"]}
        onChange={onChangeHandler}
        placeholder={"Имя"}
        required={true}
      />
      <FloatingInput
        key={"middlename"}
        name={"middlename"}
        value={data["middlename"]}
        onChange={onChangeHandler}
        placeholder={"Отчество"}
        required={true}
      />
      <FloatingInput
        key={"phone"}
        name={"phone"}
        value={data["phone"]}
        onChange={onChangeHandler}
        placeholder={"Телефон"}
        required={true}
      />
      <FloatingInput
        key={"additionalNumber"}
        name={"additionalNumber"}
        value={data["additionalNumber"]}
        onChange={onChangeHandler}
        placeholder={"Добавочный номер"}
        required={false}
      />
      <FloatingInput
        wrongMessage={T.inCorrectEmail}
        wrong={isWrongEmail}
        key={"email"}
        onBlur={checkEmailValid}
        name={"email"}
        value={data["email"]}
        onChange={onChangeHandler}
        placeholder={"Email"}
        required={true}
      />
      <FloatingInput
        wrong={isWrongPassword}
        key={"password"}
        name={"password"}
        value={data["password"]}
        onChange={onChangeHandler}
        placeholder={"Пароль"}
        required={true}
        type={"password"}
      />
      <FloatingInput
        wrongMessage={T.inCorrectPassword}
        wrong={isWrongPassword}
        key={"passwordRepeate"}
        name={"passwordRepeate"}
        value={data["passwordRepeate"]}
        onChange={onChangeHandler}
        placeholder={"Повторите пароль"}
        type={"password"}
        required={true}
      />
      <div style={{ margin: "13px 0" }}>
        <CheckBoxLabel>
          <Checkbox
            className="isAgree"
            name="isAgree"
            checked={isAgree}
            onChange={switchIsAgree}
          />
          <span style={{ marginLeft: 13 }}>
            Соглашаюсь на обработку
            <LinkButton
              target={"_blank"}
              style={{ display: "inline-flex" }}
              to={PATHS.agreement}
            >
              персональных данных
            </LinkButton>
          </span>
        </CheckBoxLabel>
      </div>
      <Button
        style={{ marginRight: 25 }}
        disabled={isDisableRegisterButton}
        onClick={isDisableRegisterButton ? () => {} : registerUser}
      >
        {T.send}
      </Button>
      <div>{error}</div>
    </>
  );
};

export default Register;
