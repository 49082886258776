import T from "./types";

const initialState = [];

const addStruct = (state = [], id, structElement) => {
  state.forEach((el) => {
    el.childrens.map(({ elems }) => {
      addStruct(elems, id, deleteStruct);
    });

    if (el.id == id) el["struct"] = [...el.struct, structElement];
  });
};

const deleteStruct = (state = [], id, structId) => {
  state.forEach((el) => {
    el.childrens.map(({ elems }) => {
      deleteStruct(elems, id, structId);
    });

    if (el.id == id)
      el["struct"] = [
        ...el.struct.filter((structElement) => structElement.id !== structId),
      ];
  });
};

const changeField = (elements, id, text, fieldName) => {
  elements.forEach((el) => {
    if (el.id == id) el[fieldName] = text;
    el.childrens.map(({ elems }) => {
      changeField(elems, id, text, fieldName);
    });
    return;
  });
};

const setStruct = (state = [], id, struct) => {
  state.forEach((el) => {
    el.childrens.map(({ elems }) => {
      setStruct(elems, id, struct);
    });

    if (el.id == id) el["struct"] = [...struct];
  });
};

const sortElements = (elements) => {
  elements.map((element) =>
    element.childrens.map(({ elems }) => {
      sortElements(elems);
    })
  );

  elements.sort(function (a, b) {
    if (a.id > b.id) return 1;
    if (a.id < b.id) return -1;
    return 0;
  });
};

export default function elements(state = initialState, action) {
  switch (action.type) {
    case T.SET_EDITOR_ELEMENTS:
      return [...action.payload];
    case T.ADD_ELEMENT_TO_STRUCT:
      addStruct(state, action.id, action.structElement);
      return [...state];
    case T.DELETE_ELEMENT_FROM_STRUCT:
      deleteStruct(state, action.id, action.structElementId);
      return [...state];
    case T.SET_STRUCT_ELEMENT:
      setStruct(state, action.id, action.struct);
      return [...state];
    case T.TYPE_NAME:
      changeField(state, action.id, action.text, "name");
      return [...state];
    case T.TYPE_TIP:
      changeField(state, action.id, action.text, "tip");
      return [...state];
    case T.CHANGE_HREF:
      changeField(state, action.id, action.text, "href");
      return [...state];
    case T.SET_REPEAT_CHILDREN_COUNT_TO_ELEMENT:
      changeField(state, action.id, action.repeat, "repeat");
      modificateComplexListIds(state, "");
      return [...state];
    case T.MODIFICATE_IDS_OF_SUPER_COMPLEXT_LISTS:
      const elements = [...state];
      saveDeafultChildren(elements);
      modificateComplexListIds(elements, "");
      return [...elements];
    default:
      return state;
  }
}

const saveDeafultChildren = (elements) => {
  elements.forEach((element) => {
    let { type, id, childrens, defaultChildren } = element;
    const isSuperComplexList = type == 9;

    if (!defaultChildren) {
      childrens.forEach(({ elems }) => {
        saveDeafultChildren(elems);
      });
    }
    if (isSuperComplexList) {
      const childElements = getNewArrayOfObjects(childrens[0].elems);

      element.defaultChildren = childElements;
      // element.defaultChildren = JSON.parse(
      //   JSON.stringify(element.defaultChildren)
      // );
    }
  });
};

const modificateComplexListIds = (elements, staticPartOfId, parent = null) => {
  elements.forEach((element) => {
    let { type, id, childrens, defaultChildren, repeat, parent_id } = element;

    const stringId = id.toString();
    const isIdWithStaticPart = stringId.includes("_");

    if (parent_id != null) {
      // element.parent_id = parent;
    }

    // Если элемент уже модифицирован, надо заменить старую доп часть на новую
    if (isIdWithStaticPart) {
      const partsOfId = stringId.split("_");
      const idWithoutPart = partsOfId.shift();
      element.id = idWithoutPart + staticPartOfId;
    }

    // Если еще нет, то просто добавляем статичную часть
    else {
      element.id = id + staticPartOfId;
    }

    const isSuperComplexList = type == 9;

    const isRepeatUndefined = typeof repeat == "undefined";

    if (isRepeatUndefined) {
      repeat = 1;
    }

    if (isSuperComplexList) {
      let partsOfElements = [];

      const { elems } = childrens[0];

      const countOfChildrens = elems.length;
      const countOfDefaultChildren = defaultChildren.length;

      const startIndex = countOfChildrens / countOfDefaultChildren;

      for (let i = startIndex; i < repeat; i++) {
        let newElements = getNewArrayOfObjects(defaultChildren);
        childrens[0].elems = [...elems, ...newElements];
      }

      for (let i = 0; i < repeat; i++) {
        let elemsToUpdate = [];

        const indexesOfGroups = {
          min: i * countOfDefaultChildren,
          max: (i + 1) * countOfDefaultChildren,
        };
        const newPart = staticPartOfId + "_" + i;

        for (let j = indexesOfGroups.min; j < indexesOfGroups.max; j++) {
          const elementToChangeId = childrens[0].elems[j];
          const { id } = elementToChangeId;
          elemsToUpdate.push(elementToChangeId);
        }

        console.log(newPart, repeat, elemsToUpdate);
        modificateComplexListIds(elemsToUpdate, newPart, element.id);
      }
    } else {
      childrens.map(({ elems }) => {
        modificateComplexListIds(elems, staticPartOfId, element.id);
      });
    }
  });
};

const getNewArrayOfObjects = (arr = []) => {
  return JSON.parse(JSON.stringify(arr));
};
