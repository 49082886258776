import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Source } from "../../../api/methods";

import Alert from "../../../components/Alert/Alert";
import ButtonsBlock from "../../../components/Alert/ButtonsBlock";
import Button from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import Text from "../../../components/UI-kit/Text";

import { T } from "../../../lang/ru";
import { setIsShowPopup } from "../../../store/popups/actions";
import { getIsShowPopups } from "../../../store/popups/selectors";

const BoldText = styled.span`
  font-weight: bold;
`;

const DeletePopup = ({ name, id, getAllSource, setSource }) => {
  const dispatch = useDispatch();
  const { isShowDeleteSource } = useSelector(getIsShowPopups);

  const onClickYes = () => {
    deleteSource();
  };

  const deleteSource = () => {
    Source.delete({ id }, deleteSourceSuccess);
  };

  const deleteSourceSuccess = () => {
    setSource(-1);
    getAllSource();
    closePopup();
  };

  const closePopup = () => {
    dispatch(setIsShowPopup("isShowDeleteSource", false));
  };

  const nameText = <BoldText>{name}</BoldText>;

  const buttons = (
    <ButtonsBlock>
      <Button onClick={onClickYes}>{T.yes}</Button>
      <SecondOrderButton className="delete-button" onClick={closePopup}>
        {T.cancel}
      </SecondOrderButton>
    </ButtonsBlock>
  );

  return (
    <Alert title={T.deleting} isShow={isShowDeleteSource} buttons={buttons}>
      <Text>
        {T.deleteSource}
        {nameText}?
      </Text>
    </Alert>
  );
};

export default DeletePopup;
