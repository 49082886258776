export const accent = "#4F8CE8";
export const darkaccent = "#023a8f";
export const yellow = "#FFB03A";
export const red = "#F44336";
export const green = "#4CBA5E";
export const white = "#ffffff";
export const black = "#262626";
export const gray = "#969696";
export const lightgray = "#E3E3E3";
export const ultraLightgray = "#F8F8F8";
